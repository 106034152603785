import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private oauthService: OAuthService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (window.location.href.indexOf('#state=') > -1) {
      if (!localStorage.getItem('isLoginSuccess')) {

        localStorage.setItem('isLoginSuccess', 'true');

        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
          'event': 'JMLoginSuccessful'
        });
      }
      return true;
    } else {
      if (this.oauthService.getIdentityClaims()) {
        return true;
      } else {
        this.router.navigate(['/register']);
        return false;
      }
    }
  }
}
