import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { RegistrationRoutingService } from '../services/registration-routing.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private oauthService: OAuthService,
    private cookieService: CookieService,
    private registrationRouting: RegistrationRoutingService
  ) { }

  ngOnInit() {
    var queryParams = this.registrationRouting.queryStringParams();
    if(queryParams){
      localStorage.setItem('UtmParams', queryParams);
    }
    this.azureAdlogin();
  }

  azureAdlogin() {
    let submissionId = '';
    if (sessionStorage.getItem('submissionId')) {
      submissionId = sessionStorage.getItem('submissionId');
    }

    const loginCallback = () => {
      if (this.oauthService.loginUrl.endsWith('authorize')) {
        this.oauthService.initLoginFlow('', {
          submissionId, ccAppUrl: window.location.origin,
          instrumentationKey: environment.appInsights.instrumentationKey
        });
      } else {
        setTimeout(() => {
          loginCallback();
        }, 500);
      }
    };
    loginCallback();
  }
}
