import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bureau-data-provided',
  templateUrl: './bureau-data-provided.component.html',
  host: {'class': 'col-md-12'},
  styleUrls: ['./bureau-data-provided.component.css']
})
export class BureauDataProvidedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
