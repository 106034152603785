<div [style.display]="hasData ? 'block': 'none'">
    <div class="improvemtnsbox" *ngIf="display" >    
        <div  class="improvetext">
            <span *ngIf="progressData.realValue!=0" [class]="progressData.cssClass"  class="pointtxt"> <i class="pintIndicatior-icon"></i>  {{progressData.realValue}} points</span>
            <span *ngIf="progressData.realValue!=0">  {{progressData.text}}</span> 
            <span *ngIf="progressData.realValue==0" class="nochngetext"><span class="pointtxt">No change</span> {{progressData.text}}</span>
        </div>
    </div> 

    <div class="col-md-12 col-12 px-md-2 px-0" style="height: 235px;max-width:1000PX">
        <canvas width="100%" height="100%" id="areaChart"></canvas>
    </div>
</div>

<div *ngIf="hasData === false" class="credithistoryNone">
    <div class="pr-3">
        <img src="../../assets/images/credithistory-none.jpg"  class="no-credit" alt=""/>
    </div>
    <div class="pt-2 px-3">
        <p class="nocreditText mb-0 ">
            To see how your credit score has changed over time, at least 3 month's credit history is required. As you registered on {{registrationDate}}, we do not have sufficient credit information to show this feature at this time.
        </p>
    </div>
</div>

