import { Injectable } from '@angular/core';
import { HttpClient,  } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SettingService {

  env = environment;
  constructor(private http: HttpClient) { }

  getUserSetting() {
    return this.http.post(this.env.webApiUrl + 'api/UserSetting/GetUserSetting', null);
  }

  updateUserSetting(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/UserSetting/UpdateUserSetting', data);
  }
}
