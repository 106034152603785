<div autoIdButtons class="d-flex flex-column h-100">
    <router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#e59a2c" type="ball-circus"></ngx-spinner>

<ng-template #logoutModel let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Would you like to stay?</h4>
    </div>
    <div class="otp-form">
        <div class="modal-body">
            {{idleState}}
        </div>
        <div class="modal-footer stypop">
            <button id="btnLogoutComponent" name="btnLogoutComponent" type="button" (click)="logout(null)" class="btn-border">Logout</button>
            <button id="btnStayComponent" name="btnStayComponent" type="button" (click)="stay()" class="btn btn-success">Stay</button>

        </div>
    </div>
</ng-template>
</div>

