import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { InputValidationService } from '../helpers/validators/input-validation.service';
import { ProductService } from '../services/product.service';
import { CommunicationService } from '../services/communication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Enum } from '../helpers/enums/enum';
@Component({
  selector: 'app-motor-vehicle-finance-calculator',
  templateUrl: './motor-vehicle-finance-calculator.component.html',
  styleUrls: ['./motor-vehicle-finance-calculator.component.css']
})
export class MotorVehicleFinanceCalculatorComponent implements OnInit {
  @ViewChild('motorVehicleCalculatorModel', { static: false }) motorVehicleCalculatorModel: ElementRef;
  @ViewChild('callbackSuccessModel', { static: false }) callbackSuccessModel: ElementRef;
  @ViewChild('callbackErrorModel', { static: false }) callbackErrorModel: ElementRef;
  modalOptions: NgbModalOptions;

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private productService: ProductService, private communicationService: CommunicationService) { }
  isDisplayedBallonPayment: boolean = false;
  isDisplayedCarTrade: boolean = false;
  isDisplayedSavingsDeposit: boolean = false;
  primeRate: number = 13.0;
  extraPercentage: number = 0.0;
  extraPercentageValue: string = '';
  termsOfLoan: { display: string, value: number }[] = [];
  ValidMonthlyAmountErrorMsg: string = '';
  motorVehicleCalculatorForm;
  estimatedVehicleAmount: number = 0;
  monthlyPaymentAmount: number = 0;
  estimatedLoanAmount: number = 0;
  depositAmount: number = 0;
  tradeInValueAmount: number = 0;
  callBackErrorDebt = 'Please try after 15 minutes.';
  vehicleCalculatorCampaignSource = 0;
  ValidationErrorMsgForBalloonPayment: string = '';
  vehicleShowDiv = false;
  ngOnInit(): void {
    let vehicleInterestRate = sessionStorage.getItem('vehicleInterestRate');
    if (vehicleInterestRate != "") {
      this.primeRate = this.CheckNumber(vehicleInterestRate);
      this.extraPercentage = parseFloat(this.CheckNumber(vehicleInterestRate)) + 2;
      this.extraPercentageValue = " + 2 %";
    }
    this.motorVehicleCalculatorForm = this.formBuilder.group({
      MonthlyAmount: ['', [Validators.required,Validators.min(1)]],
      InterestRate: [this.extraPercentage, [Validators.required, Validators.min(this.primeRate), Validators.max(99.5),Validators.pattern(/^\d*\.?\d+$/)]],
      TermInMonths: ["72"],
      BalloonPayment: [''],
      TradeInValue: [''],
      Deposit: ['']
    });
    this.CalculateTermOfLoan();
    // this.rangeSlider();

    this.motorVehicleCalculatorForm.get('InterestRate')?.valueChanges.subscribe(value => {
      this.CalculateExtraPrimeAmount();
    });
    
  }
  get motorVehicleCalculatorFormControls() { return this.motorVehicleCalculatorForm.controls; }
  CalculateTermOfLoan() {
    for (let year = 3; year <= 6; year++) {
      let month = year * 12;
      this.termsOfLoan.push({ display: `${year} years (${month} months)`, value: month });
    }
  }

  CalculateExtraPrimeAmount() {
    this.extraPercentageValue = "";
    let interestRate = this.CheckNumber(this.motorVehicleCalculatorForm.controls.InterestRate.value);
    this.extraPercentage = parseFloat(interestRate) - this.primeRate;
    if (this.extraPercentage > 0) {
      this.extraPercentageValue = " + " + this.extraPercentage.toFixed(2) + " %";
    }
    else if (this.extraPercentage < 0) {
      this.extraPercentageValue = this.extraPercentage.toFixed(2) + " %";
    }
  }
  CheckNumber(val: any) {
    val = val || '';
    if (val == "") {
      return 0;
    }
    const valArr = val.toString().split('');

    const valFiltered = valArr.filter((x: string, index: number) => {
      if (!isNaN(Number(x)) && x !== ' ') {
        return true;
      }
      if (x === '.' && valArr.indexOf('.') === index) {
        return true;
      }
      return false;
    });
    const valProcessed = valFiltered.join('').trim();

    return valProcessed;
  }
 
  resetValidation() {
    if (this.motorVehicleCalculatorForm.controls.MonthlyAmount.valid) {
      this.ValidMonthlyAmountErrorMsg = '';
    }
  }

  ShowMonthlyAmountErrorMessage() {
    let monthlyRepay = this.CheckNumber(this.motorVehicleCalculatorForm.controls.MonthlyAmount.value);
    let zeroPattern = /^0+(\.|,0+)*$/;
    if (monthlyRepay === "" || monthlyRepay === "R") {
      this.ValidMonthlyAmountErrorMsg = "Monthly Affordability is required";
    }
    else if(zeroPattern.test(monthlyRepay)){
         this.ValidMonthlyAmountErrorMsg = "Monthly Affordability must be greater than 0"
    }
    else {
      this.ValidMonthlyAmountErrorMsg = "";
    }
  }

  motorVehicleCal() {
    this.modalService.open(this.motorVehicleCalculatorModel, { size: 'lg', keyboard: false, backdrop: 'static' });
  }

  showHideCarTrade(event: any) {
    if (event.target.checked == true) {
      this.isDisplayedCarTrade = true;
    }
    else {
      this.isDisplayedCarTrade = false;
      this.motorVehicleCalculatorForm.get('TradeInValue').reset();
    }
  }

  showHideSavingsDeposit(event: any) {
    if (event.target.checked == true) {
      this.isDisplayedSavingsDeposit = true;
    }
    else {
      this.isDisplayedSavingsDeposit = false;
      this.motorVehicleCalculatorForm.get('Deposit').reset();
    }
  }

  submitMotorVehicleCalculatorData() {

    if (this.motorVehicleCalculatorForm.invalid || this.ValidMonthlyAmountErrorMsg !== ''|| this.ValidationErrorMsgForBalloonPayment !== '') {
      this.motorVehicleCalculatorForm.markAllAsTouched();
      return;
    }
    let x = this.motorVehicleCalculatorForm.value.InterestRate;
    const param = {
      InterestRate: parseFloat(this.CheckNumber(this.motorVehicleCalculatorForm.controls.InterestRate.value)),
      TermInMonths: parseInt(this.CheckNumber(this.motorVehicleCalculatorForm.controls.TermInMonths.value)),
      MonthlyAmount: parseFloat(this.CheckNumber(this.motorVehicleCalculatorForm.controls.MonthlyAmount.value)),
      Deposit: parseFloat(this.CheckNumber(this.motorVehicleCalculatorForm.controls.Deposit.value)),
      TradeInValue: parseFloat(this.CheckNumber(this.motorVehicleCalculatorForm.controls.TradeInValue.value)),
      IdNumber: ""
    };
    this.productService.CalculateVehicleFinanceFinance(param).subscribe((data: any) => {
      this.ProcessVehicleFinanceFinanceData(data);
    }),
      (error: any) => { };

  }

  ProcessVehicleFinanceFinanceData(data) {
    this.ValidationErrorMsgForBalloonPayment="";
    this.ValidMonthlyAmountErrorMsg = "";
    this.estimatedVehicleAmount = data.affordabilityAmount;
    this.monthlyPaymentAmount = data.monthlyAmount;
    this.estimatedLoanAmount = data.financedAmount;
    this.depositAmount = data.deposit;
    this.tradeInValueAmount = data.tradeInValue;
    this.vehicleShowDiv = true;
 
  }
  
  userCallback() {
    this.loadVehicleResponseCriteria();
    var payload = {
      CampaignSourceType: this.vehicleCalculatorCampaignSource
    }

    this.communicationService.userCallbackSource(payload).subscribe((data: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.callbackSuccessModel, this.modalOptions);
    }, (err: HttpErrorResponse) => {
      this.callBackErrorDebt = 'Our coach will contact you shortly.';
      this.modalService.dismissAll();
      this.modalService.open(this.callbackErrorModel, this.modalOptions);
    });
  }
  loadVehicleResponseCriteria(){
    const loadProductsQualify = sessionStorage.getItem('loadProductsQualify'); 
    if (loadProductsQualify) {
      const loadProductsQualifyObj = JSON.parse(loadProductsQualify);
      const vehicleResponseCriteria = loadProductsQualifyObj.vehicleResponse.criteria;
      switch (vehicleResponseCriteria) {
        case "0-19":
          this.vehicleCalculatorCampaignSource = Enum.LmsCampaignSourceType.vehicleFinanceCalculatorLow;
          break;
        case "20-64":
          this.vehicleCalculatorCampaignSource = Enum.LmsCampaignSourceType.vehicleFinanceCalculatorMedium;
          break;
        case "65-89":
          this.vehicleCalculatorCampaignSource = Enum.LmsCampaignSourceType.vehicleFinanceCalculatorHigh;
          break;
        case "90-10000":
          this.vehicleCalculatorCampaignSource = Enum.LmsCampaignSourceType.vehicleFinanceCalculatorVeryHigh;
          break;  
        default:
          console.warn('Criteria range does not match expected values.');
          break;
    } }else {
      console.error('No data found in sessionStorage for key: loadProductsQualify');
    }
  }
}
