<section class="news">

    <div class="bg-white">
        <div class="newsbanner b5containeradjust d-flex align-items-center position-relative"
            style="background-image: url(../../assets/images/newsbanner.jpg);">
            <div class="position-absolute banner-button d-none">
                <a href="javascript:void(0)" class="bannerbutn d-md-block d-none">Lol</a>
                <a href="javascript:void(0)" class="bannerbutn d-md-block d-none">Win</a>
                <a href="javascript:void(0)" class="bannerbutn d-md-block d-none">Play</a>
                <a href="javascript:void(0)" class="bannerbutn d-md-block d-none">Lorem</a>
                <a href="javascript:void(0)" class="bannerbutn d-md-block d-none">Ipsum</a>

                <a href="javascript:void(0)" class="bannerbutn  d-md-none d-block">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="30" height="30" x="0" y="0"
                        viewBox="0 0 24 24" style="enable-background:new 0 0 512 512" xml:space="preserve">
                        <g>
                            <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" fill="#ffd54f" r="12"
                                data-original="#ffd54f" style="" />
                            <path xmlns="http://www.w3.org/2000/svg"
                                d="m19.537 7.723c-.236-.036-.474.043-.643.211l-10.96 10.96c-.168.168-.247.407-.211.643.036.235.181.44.392.551 3.462 1.822 7.644 1.192 10.404-1.569s3.391-6.942 1.569-10.404c-.111-.211-.315-.356-.551-.392z"
                                fill="#e53935" data-original="#e53935" style="" />
                            <path xmlns="http://www.w3.org/2000/svg"
                                d="m6.61 15.639c-.518.749-2.205 3.291-2.204 4.569 0 1.555 1.266 2.821 2.821 2.821.778 0 1.483-.316 1.994-.827.51-.51.827-1.216.827-1.994 0-1.279-1.687-3.82-2.204-4.569-.139-.203-.37-.324-.617-.324s-.479.122-.617.324z"
                                fill="#00bcd4" data-original="#00bcd4" style="" />
                            <path xmlns="http://www.w3.org/2000/svg"
                                d="m20.208 4.406c-1.278 0-3.82 1.687-4.569 2.204-.204.14-.324.371-.324.617s.121.477.324.617c.748.518 3.29 2.205 4.569 2.204.778 0 1.483-.317 1.993-.827.511-.511.828-1.216.828-1.994 0-1.555-1.266-2.821-2.821-2.821z"
                                fill="#00bcd4" data-original="#00bcd4" style="" />
                            <g xmlns="http://www.w3.org/2000/svg" fill="#6d4c41">
                                <path
                                    d="m7.581 15.005c-.167.167-.411.253-.661.209-.408-.072-.681-.461-.608-.869l.344-1.95-1.95.344c-.408.073-.797-.2-.869-.608-.074-.408.2-.796.608-.868l3.005-.53c.241-.043.488.035.661.208s.251.419.208.661l-.53 3.005c-.028.156-.103.293-.208.398z"
                                    fill="#6d4c41" data-original="#6d4c41" style="" />
                                <path
                                    d="m15.005 7.581c-.105.105-.242.18-.399.207l-3.006.531c-.241.043-.488-.035-.661-.208s-.251-.419-.208-.661l.53-3.005c.072-.408.461-.681.868-.608.408.072.681.461.608.869l-.344 1.95 1.95-.344c.408-.073.797.2.869.608.046.25-.04.494-.207.661z"
                                    fill="#6d4c41" data-original="#6d4c41" style="" />
                            </g>
                        </g>
                    </svg>

                </a>
                <a href="javascript:void(0)" class="bannerbutn d-md-none d-block">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="30" height="30" x="0" y="0"
                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                        <g>
                            <g xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="m150.399 239.282c-85.166-7.998-150.399-78.618-150.399-164.282v-30c0-24.814 20.186-45 45-45s45 20.186 45 45c0 8.276 6.724 15 15 15h31c8.291 0 15 6.709 15 15s-6.709 15-15 15h-31c-24.814 0-45-20.186-45-45 0-8.276-6.724-15-15-15s-15 6.724-15 15v30c0 70.093 53.544 127.866 123.212 134.399 8.247.776 14.297 8.101 13.535 16.348-.776 8.221-8.056 14.289-16.348 13.535z"
                                    fill="#ffbe40" data-original="#ffbe40" style="" class="" />
                                <path
                                    d="m346.253 225.747c-.762-8.247 5.288-15.571 13.535-16.348 69.668-6.533 122.212-64.306 122.212-134.399v-30c0-8.276-6.724-15-15-15s-15 6.724-15 15c0 24.814-20.186 45-45 45h-30c-8.291 0-15-6.709-15-15s6.709-15 15-15h30c8.276 0 15-6.724 15-15 0-24.814 20.186-45 45-45s45 20.186 45 45v30c0 85.664-64.233 156.284-149.399 164.282-8.291.754-15.572-5.313-16.348-13.535z"
                                    fill="#ff9f40" data-original="#ff9f40" style="" class="" />
                                <path
                                    d="m377 0h-121-120c-8.291 0-15 6.709-15 15v150c0 57.349 36.46 107.944 90 126.841v115.159h45 45v-115.159c53.54-18.896 91-69.492 91-126.841v-150c0-8.291-6.709-15-15-15z"
                                    fill="#ffdf40" data-original="#ffdf40" style="" class="" />
                                <path
                                    d="m301 291.841c53.54-18.896 91-69.492 91-126.841v-150c0-8.291-6.709-15-15-15h-121v407h45z"
                                    fill="#ffbe40" data-original="#ffbe40" style="" class="" />
                                <path
                                    d="m332.611 115.664c-1.772-5.435-6.46-9.39-12.114-10.21l-35.273-5.127-15.776-31.963c-2.52-5.127-7.983-7.69-13.447-7.69s-10.928 2.563-13.447 7.69l-15.776 31.963-35.273 5.127c-5.654.82-10.342 4.775-12.114 10.21-1.758 5.42-.293 11.382 3.794 15.366l25.532 24.888-6.021 35.127c-.967 5.625 1.348 11.309 5.962 14.663 4.614 3.369 10.796 3.809 15.791 1.143l31.551-16.568 31.553 16.567c4.979 2.64 11.142 2.254 15.791-1.143 4.614-3.354 6.929-9.038 5.962-14.663l-6.021-35.127 25.532-24.888c4.087-3.983 5.552-9.945 3.794-15.365z"
                                    fill="#ffbe40" data-original="#ffbe40" style="" class="" />
                                <path
                                    d="m303.344 205.708c4.614-3.354 6.929-9.038 5.962-14.663l-6.021-35.127 25.532-24.888c4.087-3.984 5.552-9.946 3.794-15.366-1.772-5.435-6.46-9.39-12.114-10.21l-35.273-5.127-15.776-31.963c-2.52-5.127-7.983-7.69-13.447-7.69v129.609l31.553 16.567c4.977 2.641 11.141 2.255 15.79-1.142z"
                                    fill="#ff9f40" data-original="#ff9f40" style="" class="" />
                                <path
                                    d="m376 452h-15v-45c0-8.291-6.709-15-15-15h-90-90c-8.291 0-15 6.709-15 15v45h-15c-8.291 0-15 6.709-15 15v30c0 8.291 6.709 15 15 15h120 120c8.291 0 15-6.709 15-15v-30c0-8.291-6.709-15-15-15z"
                                    fill="#4d6699" data-original="#4d6699" style="" class="" />
                                <path
                                    d="m391 497v-30c0-8.291-6.709-15-15-15h-15v-45c0-8.291-6.709-15-15-15h-90v120h120c8.291 0 15-6.709 15-15z"
                                    fill="#404b80" data-original="#404b80" style="" class="" />
                                <path
                                    d="m286 437h-30-30c-8.291 0-15 6.709-15 15s6.709 15 15 15h30 30c8.291 0 15-6.709 15-15s-6.709-15-15-15z"
                                    fill="#404b80" data-original="#404b80" style="" class="" />
                                <path d="m301 452c0-8.291-6.709-15-15-15h-30v30h30c8.291 0 15-6.709 15-15z"
                                    fill="#333366" data-original="#333366" style="" class="" />
                            </g>
                        </g>
                    </svg>

                </a>
                <a href="javascript:void(0)" class="bannerbutn d-md-none d-block">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        x="0px" y="0px" viewBox="0 0 58 44" style="enable-background:new 0 0 58 44;"
                        xml:space="preserve" width="30" height="30">
                        <g id="_x31_-Video">
                            <path style="fill:#DD352E;"
                                d="M52.305,44H5.695C2.55,44,0,41.45,0,38.305V5.695C0,2.55,2.55,0,5.695,0h46.61   C55.45,0,58,2.55,58,5.695v32.61C58,41.45,55.45,44,52.305,44z" />
                            <path style="fill:#FFFFFF;"
                                d="M21,32.53V11.47c0-1.091,1.187-1.769,2.127-1.214l17.82,10.53c0.923,0.546,0.923,1.882,0,2.427   l-17.82,10.53C22.187,34.299,21,33.621,21,32.53z" />
                        </g>

                    </svg>
                </a>
                <a href="javascript:void(0)" class="bannerbutn d-md-none d-block">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="30" height="30" x="0" y="0"
                        viewBox="0 0 512.016 512.016" style="enable-background:new 0 0 512 512" xml:space="preserve"
                        class="">
                        <g>
                            <circle xmlns="http://www.w3.org/2000/svg" style="" cx="256" cy="256.008" r="256"
                                fill="#ffd93b" data-original="#ffd93b" class="" />
                            <path xmlns="http://www.w3.org/2000/svg" style=""
                                d="M512,256.008c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28  c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72  C474.8,103.688,512,175.528,512,256.008z"
                                fill="#f4c534" data-original="#f4c534" class="" />
                            <path xmlns="http://www.w3.org/2000/svg" id="SVGCleanerId_0" style=""
                                d="M158.352,384.552c23.04-51.488,59.52-52.048,99.68-62.864  c40.32-10.928,76.8,30.08,99.84,51.248c7.136,4.992,0.384,18.544-12.832,24.752c-57.952,22.352-115.904-19.504-173.856,8.576  C157.968,414.008,151.216,402.264,158.352,384.552z"
                                fill="#3e4347" data-original="#3e4347" />
                            <g xmlns="http://www.w3.org/2000/svg">
                                <path id="SVGCleanerId_0_1_" style=""
                                    d="M158.352,384.552c23.04-51.488,59.52-52.048,99.68-62.864   c40.32-10.928,76.8,30.08,99.84,51.248c7.136,4.992,0.384,18.544-12.832,24.752c-57.952,22.352-115.904-19.504-173.856,8.576   C157.968,414.008,151.216,402.264,158.352,384.552z"
                                    fill="#3e4347" data-original="#3e4347" />
                            </g>
                            <path xmlns="http://www.w3.org/2000/svg" style=""
                                d="M314.496,404.184c-1.232-10.448-6.336-25.712-11.904-32.72c-6.992-8.784-20.064-13.008-31.024-7.712  c-4.192,2.096-8.736,3.584-13.552,4.304c-4.928,0.688-9.776,0.48-14.416-0.624c-17.312-4.432-34.4,3.52-38.288,22.768  c-0.464,2.192-0.544,4.464-0.752,6.72C241.2,393.016,277.856,406.664,314.496,404.184z"
                                fill="#e24b4b" data-original="#e24b4b" />
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">

                                <ellipse transform="matrix(-0.9162 -0.4008 0.4008 -0.9162 398.5494 838.7022)" style=""
                                    cx="286.986" cy="377.671" rx="9.312" ry="6.112" fill="#ffffff"
                                    data-original="#ffffff" />
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                                <path style=""
                                    d="M479.536,483.016c1.968,1.152,3.104,2.384,3.104,3.6c0,2.256-3.472,4.304-9.472,6.048   c10.704,2,17.264,4.752,17.264,7.904c0,6.304-27.776,11.44-61.936,11.44c-24.4,0-45.504-2.64-55.552-6.496c0,0,0,0-0.096,0   c-1.968,0.064-4.128,0.064-6.192,0.064c-34.256,0-61.936-5.136-61.936-11.44c0-3.344,7.792-6.368,20.176-8.416   c-10.976-2.048-17.824-4.88-17.824-8.032c0-6.368,27.68-11.44,61.84-11.44c9.76,0,18.96,0.384,27.12,1.152   c10.512-3.536,30.784-5.92,54.048-5.92c34.16,0,61.936,5.152,61.936,11.44C512,477.224,498.864,481.016,479.536,483.016z"
                                    fill="#45cbea" data-original="#45cbea" />
                                <path style=""
                                    d="M445.232,490.84c10.304-1.76,17.632-10.944,16.896-21.376   c-3.424-48.784-11.296-98.768-29.408-143.552c-15.184-41.168-35.632-80.944-63.632-119.04c-6.576,5.04-14.704,8.544-24.304,9.952   c18.944,41.392,29.952,81.952,35.68,122.592c5.04,28.496,6.48,70.944-1.376,126.88c-1.264,9.008,3.776,17.904,12.256,21.184   C405.568,492.952,423.072,494.616,445.232,490.84z"
                                    fill="#45cbea" data-original="#45cbea" />
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">
                                <path style=""
                                    d="M394.016,334.552c-6.928-35.728-23.584-83.04-43.056-118.992c-0.224,0.064-0.448,0.128-0.688,0.176   c18.304,36.672,30.96,80.432,38.992,119.792c10.704,47.536,10.976,96.352,6.672,145.952   C404.32,412.584,400.496,363.208,394.016,334.552z"
                                    fill="#ffffff" data-original="#ffffff" />
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">
                                <path style=""
                                    d="M407.76,332.344c-13.072-49.008-33.376-91.808-49.792-119.136c-0.112,0.048-0.224,0.08-0.336,0.128   c16.448,28.416,35.872,71.936,47.792,119.616c14.672,51.408,14.8,102.128,12.256,150.256c-0.384,4.912,4.448,5.296,4.832,0.384   C424.32,434.632,421.888,379.048,407.76,332.344z"
                                    fill="#ffffff" data-original="#ffffff" />
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">
                                <path style=""
                                    d="M422.848,329.848c-12.432-40.176-32.288-84.288-56.672-121.008   c-1.232,0.816-2.528,1.584-3.856,2.288c24.608,35.776,45.104,79.248,58.24,119.488c20.72,56.048,22.64,111.904,23.68,154.704   C443.776,433.672,441.744,384.776,422.848,329.848z"
                                    fill="#ffffff" data-original="#ffffff" />
                            </g>
                            <path xmlns="http://www.w3.org/2000/svg" style=""
                                d="M340.928,225.304c-18.224,0-36.432-7.216-51.296-21.648c-4.224-4.112-4.32-10.848-0.224-15.088  c4.096-4.224,10.864-4.32,15.088-0.224c21.44,20.832,51.408,20.832,72.864,0c4.224-4.112,10.976-4,15.088,0.224  s4,10.976-0.224,15.088C377.36,218.088,359.152,225.304,340.928,225.304z"
                                fill="#3e4347" data-original="#3e4347" />
                            <g xmlns="http://www.w3.org/2000/svg">
                                <path style=""
                                    d="M32.464,483.016c-1.968,1.152-3.088,2.384-3.088,3.6c0,2.256,3.472,4.304,9.472,6.048   c-10.688,2-17.264,4.752-17.264,7.904c0,6.288,27.776,11.44,61.936,11.44c24.4,0,45.504-2.64,55.552-6.496c0,0,0,0,0.096,0   c1.968,0.064,4.128,0.064,6.192,0.064c34.256,0,61.936-5.136,61.936-11.44c0-3.344-7.792-6.368-20.176-8.416   c10.976-2.048,17.824-4.88,17.824-8.032c0-6.368-27.68-11.44-61.84-11.44c-9.76,0-18.96,0.384-27.12,1.152   c-10.512-3.536-30.784-5.92-54.048-5.92C27.776,461.464,0,466.616,0,472.92C0,477.224,13.136,481.016,32.464,483.016z"
                                    fill="#45cbea" data-original="#45cbea" />
                                <path style=""
                                    d="M67.36,486.776c-10.16-1.728-17.376-10.784-16.656-21.072c3.376-48.08,11.136-97.36,28.992-141.504   c14.96-40.576,35.12-79.792,62.736-117.344c6.48,4.96,14.48,8.416,23.952,9.824c-18.672,40.8-29.52,80.784-35.168,120.848   c-4.96,28.096-6.384,69.92,1.36,125.056c1.248,8.88-3.728,17.648-12.08,20.88C106.464,488.872,89.216,490.504,67.36,486.776z"
                                    fill="#45cbea" data-original="#45cbea" />
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">
                                <path style=""
                                    d="M117.84,332.728c6.816-35.216,23.248-81.856,42.432-117.296c0.224,0.064,0.448,0.128,0.672,0.176   c-18.032,36.144-30.528,79.28-38.432,118.08c-10.56,46.864-10.816,94.976-6.576,143.872   C107.696,409.656,111.472,360.984,117.84,332.728z"
                                    fill="#ffffff" data-original="#ffffff" />
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">
                                <path style=""
                                    d="M104.304,330.552c12.88-48.304,32.912-90.496,49.088-117.424c0.112,0.048,0.224,0.08,0.336,0.128   c-16.224,28.016-35.36,70.912-47.104,117.904c-14.464,50.672-14.576,100.672-12.08,148.112c0.288,3.584-4.48,3.952-4.768,0.384   C87.984,431.384,90.384,376.6,104.304,330.552z"
                                    fill="#ffffff" data-original="#ffffff" />
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">
                                <path style=""
                                    d="M89.424,328.088c12.256-39.6,31.824-83.072,55.872-119.28c1.216,0.8,2.48,1.552,3.808,2.256   c-24.256,35.28-44.448,78.112-57.408,117.792c-20.432,55.232-22.32,110.304-23.344,152.496   C68.8,430.44,70.8,382.232,89.424,328.088z"
                                    fill="#ffffff" data-original="#ffffff" />
                            </g>
                            <path xmlns="http://www.w3.org/2000/svg" style=""
                                d="M171.072,225.304c-18.224,0-36.432-7.216-51.296-21.648c-4.224-4.112-4.32-10.848-0.224-15.088  c4.096-4.224,10.848-4.32,15.088-0.224c21.44,20.832,51.408,20.832,72.864,0c4.224-4.112,10.976-4,15.088,0.224  c4.096,4.224,4,10.976-0.224,15.088C207.504,218.088,189.28,225.304,171.072,225.304z"
                                fill="#3e4347" data-original="#3e4347" />
                            <g xmlns="http://www.w3.org/2000/svg">
                                <path style=""
                                    d="M296.016,104.52c-2.208-5.456,0.416-11.68,5.888-13.888c5.456-2.208,11.68,0.416,13.888,5.872   c20.816,51.376,75.392,43.536,77.68,43.184c5.76-0.88,11.264,3.088,12.176,8.912c0.896,5.824-3.088,11.264-8.912,12.176   C365.168,165.64,315.936,153.688,296.016,104.52z"
                                    fill="#e9b02c" data-original="#e9b02c" />
                                <path style=""
                                    d="M115.264,160.776c-5.824-0.896-9.808-6.352-8.912-12.176c0.912-5.808,6.352-9.808,12.16-8.912   c2.432,0.352,57.056,7.792,77.712-43.184c2.224-5.456,8.432-8.08,13.888-5.872s8.096,8.432,5.872,13.888   C197.408,150.344,151.072,166.296,115.264,160.776z"
                                    fill="#e9b02c" data-original="#e9b02c" />
                            </g>
                            <path xmlns="http://www.w3.org/2000/svg" style=""
                                d="M451.072,228.888c7.424,7.36,7.488,19.344,0.128,26.784c-7.36,7.424-19.344,7.488-26.784,0.128  s-14.112-40.608-14.112-40.608S443.632,221.528,451.072,228.888z"
                                fill="#45cbea" data-original="#45cbea" />
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">

                                <ellipse transform="matrix(-0.1858 -0.9826 0.9826 -0.1858 292.8773 725.6349)" style=""
                                    cx="447.08" cy="241.474" rx="5.92" ry="3.744" fill="#ffffff"
                                    data-original="#ffffff" />
                            </g>
                            <path xmlns="http://www.w3.org/2000/svg" style=""
                                d="M482.08,301.176c3.248,9.936-2.176,20.64-12.112,23.888c-9.936,3.248-20.64-2.176-23.888-12.112  c-3.248-9.936,6-42.576,6-42.576S478.816,291.24,482.08,301.176z"
                                fill="#45cbea" data-original="#45cbea" />
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">

                                <ellipse transform="matrix(-0.9591 -0.2832 0.2832 -0.9591 838.3405 742.1682)" style=""
                                    cx="472.812" cy="310.492" rx="3.744" ry="5.92" fill="#ffffff"
                                    data-original="#ffffff" />
                            </g>
                            <path xmlns="http://www.w3.org/2000/svg" style=""
                                d="M68.88,229.144c-6.608,8.112-5.376,20.032,2.736,26.64s20.032,5.376,26.64-2.736  s9.68-41.888,9.68-41.888S75.488,221.032,68.88,229.144z"
                                fill="#45cbea" data-original="#45cbea" />
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">

                                <ellipse transform="matrix(-0.9968 -0.0799 0.0799 -0.9968 128.6875 487.595)" style=""
                                    cx="74.099" cy="241.223" rx="3.728" ry="5.92" fill="#ffffff"
                                    data-original="#ffffff" />
                            </g>
                            <path xmlns="http://www.w3.org/2000/svg" style=""
                                d="M25.616,327.352c-1.2,10.384,6.24,19.792,16.64,20.992c10.384,1.2,19.792-6.24,20.992-16.64  c1.2-10.384-14.368-40.512-14.368-40.512S26.816,316.952,25.616,327.352z"
                                fill="#45cbea" data-original="#45cbea" />
                            <g xmlns="http://www.w3.org/2000/svg" style="opacity:0.2;">

                                <ellipse transform="matrix(-0.4691 -0.8832 0.8832 -0.4691 -241.835 523.9981)" style=""
                                    cx="36.587" cy="334.69" rx="5.92" ry="3.744" fill="#ffffff"
                                    data-original="#ffffff" />
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                        </g>
                    </svg>
                </a>
                <a href="javascript:void(0)" class="bannerbutn d-md-none d-block">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="30" height="30" x="0" y="0"
                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                        <g>
                            <g xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path
                                        d="m49.5 90.902c-27.214-.652-49.5 21.274-49.5 48.4v71.44c0 30.58 7.08 60.71 20.46 87.13 15.85 31.28 35.75 49.42 46.36 57.54.283.216 7.755 4.181 8.109 4.181 10.971 6.58 21.891-.818 21.891-11.981v-207.65c0-26.47-21.23-48.48-47.32-49.06zm17.32 223.07c-6.43-7.56-13.37-17.36-19.6-29.66-11.26-22.24-17.22-47.68-17.22-73.57v-71.44c0-10.11 8.166-18.41 18.41-18.41h.43c9.91.22 17.98 8.78 17.98 19.07v21.76l17.15 71.608z"
                                        fill="#6aa9ff" data-original="#6aa9ff" style="" />
                                </g>
                                <g>
                                    <path
                                        d="m415.18 139.962v207.65c0 11.135 11.484 17.732 22.49 11.131.355 0 7.226-3.114 7.51-3.331 10.61-8.12 30.51-26.26 46.36-57.54 13.38-26.42 20.46-56.55 20.46-87.13v-71.44c0-27.11-22.271-49.052-49.5-48.4-26.09.58-47.32 22.59-47.32 49.06zm21.602 94.493 8.398-72.733v-21.76c0-10.29 8.07-18.85 17.98-19.07h.43c10.237 0 18.41 8.291 18.41 18.41v71.44c0 25.89-5.96 51.33-17.22 73.57-6.23 12.3-13.17 22.1-19.6 29.66z"
                                        fill="#3c91f7" data-original="#3c91f7" style="" class="" />
                                </g>
                                <path
                                    d="m445.18 161.722v193.69c-.26.2-41.27 30.36-95.74 50.26-28.19 10.29-60.82 15.44-93.44 15.44-32.64 0-65.27-5.15-93.44-15.44-54.47-19.9-95.48-50.06-95.74-50.26v-193.69c54.34-36.32 123.46-62.78 189.18-62.78 65.76 0 134.89 26.49 189.18 62.78z"
                                    fill="#d5e8fe" data-original="#d5e8fe" style="" />
                                <path
                                    d="m445.18 161.722v193.69c-.26.2-41.27 30.36-95.74 50.26-28.19 10.29-60.82 15.44-93.44 15.44v-322.17c65.76 0 134.89 26.49 189.18 62.78z"
                                    fill="#b5dbff" data-original="#b5dbff" style="" />
                                <path
                                    d="m445.18 220.912v78.16c-57.31 34.86-131.61 61.98-189.18 61.98s-131.87-27.12-189.18-61.98v-78.16c57.31-34.85 131.61-61.97 189.18-61.97s131.87 27.12 189.18 61.97z"
                                    fill="#edf5ff" data-original="#edf5ff" style="" />
                                <path
                                    d="m445.18 220.912v78.16c-57.31 34.86-131.61 61.98-189.18 61.98v-202.11c57.57 0 131.87 27.12 189.18 61.97z"
                                    fill="#d5e8fe" data-original="#d5e8fe" style="" />
                                <path
                                    d="m307.06 260.002c0 8.28-6.72 15-15 15h-21.06v21.05c0 8.29-6.72 15-15 15s-15-6.71-15-15v-21.05h-21.06c-8.28 0-15-6.72-15-15 0-8.29 6.72-15 15-15h21.06v-21.06c0-8.29 6.72-15 15-15s15 6.71 15 15v21.06h21.06c8.28 0 15 6.71 15 15z"
                                    fill="#ff884d" data-original="#ff884d" style="" />
                                <path
                                    d="m307.06 260.002c0 8.28-6.72 15-15 15h-21.06v21.05c0 8.29-6.72 15-15 15v-102.11c8.28 0 15 6.71 15 15v21.06h21.06c8.28 0 15 6.71 15 15z"
                                    fill="#ff4d4d" data-original="#ff4d4d" style="" />
                            </g>
                        </g>
                    </svg>

                </a>
            </div>
            <div class="container  ">

                <div class="col-lg-6 col-8 px-3">
                    <h4 class="text-white banner-aptin">Enjoy your latest articles</h4>
                </div>

            </div>
        </div>
    </div>
    <div class="b5containeradjust">
        <div class="container">
            <div class="d-flex flex-wrap align-items-start justify-content-between">
                <div class="col-md-12 px-lg-3 px-md-0 px-3">
                    <div class="d-flex align-items-center">
                        <div class="col-md-6 ps-md-0 ps-0">
                            <h2 class="page-caption mt-4 mb-4">Stay Savvy</h2>
                        </div>
                        <!-- <div class="col-6 px-0 d-md-none d-block">
                            <div class="position-relative">
                                <button class="catebut w-100" (click)=toggleDisplayDiv() >Categories
                                    <i class="ps-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="15" height="15" x="0" y="0" viewBox="0 0 426.66667 426.66667" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path xmlns="http://www.w3.org/2000/svg" d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0" fill="#ffffff" data-original="#000000" style="" class=""/></g></svg>
                
                                    </i>
                                </button>
                                <div class="catlist" [hidden]="isShowDiv">
                                    <nav class="owl-filter-bar">
                                        <a href="#" class="item" data-owl-filter="*">All</a>
                                        <a href="#" class="item" data-owl-filter=".banking" >Banking (633)</a>
                                        <a href="#" class="item" data-owl-filter=".ccards" >Credit Cards (27)</a>
                                        <a href="#" class="item" data-owl-filter=".financial">Financial News (1233)</a>
                                        <a href="#" class="item" data-owl-filter=".investments">Investments (238)</a>
                                        <a href="#" class="item" data-owl-filter=".insurance">Insurance (222)</a>
                                        <a href="#" class="item" data-owl-filter=".debt">Debt (179)</a>
                                        <a href="#" class="item" data-owl-filter=".medical">Medical aid (97)</a>
                                    </nav>
                                </div>
                            </div>
                        </div>  -->
                    </div>

                    <!--mobile-->
                    <div class="news-content d-block d-md-none">
                        <nav class="news-tab">
                            <!-- <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home"
                                    role="tab" aria-controls="nav-home" aria-selected="true">Suggested for You</a>
                                <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile"
                                    role="tab" aria-controls="nav-profile" aria-selected="false">Trending</a>
                                <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact"
                                    role="tab" aria-controls="nav-contact" aria-selected="false">Most Viewed</a>
                            </div> -->
                        </nav>
                        <div class="tab-content pb-3" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
                                aria-labelledby="nav-home-tab"
                                *ngIf="staySavvyListForMobile != null && staySavvyListForMobile.length > 0">

                                <div class="vdo-outer position-relative mb-2"
                                    *ngFor="let staySavvy of staySavvyListForMobile; let i = index">
                                    <div class="position-relative">
                                        <span class="cat-pill" *ngIf="staySavvy.categories">{{staySavvy.categories[0]}}</span>
                                        <i class="position-absolute articleicon d-none">
                                            <svg id="_x31__x2C_5" enable-background="new 0 0 24 24" height="50"
                                                viewBox="0 0 24 24" width="50" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path fill="#fff"
                                                        d="m12.25 12c-.414 0-.75-.336-.75-.75v-.75h-7v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1.5c0-.414.336-.75.75-.75h8.5c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m8 18.75c-.414 0-.75-.336-.75-.75v-8c0-.414.336-.75.75-.75s.75.336.75.75v8c0 .414-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m9.25 19h-2.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h2.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 10.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 14.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 18.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>

                                            </svg>

                                    </i>
                                    
                                    <img *ngIf="staySavvy && staySavvy.imageUrl && staySavvy.imageUrl.includes('https://www.justmoney.co.za')" src="{{staySavvy.imageUrl}}" class="w-100" />
                                    <img *ngIf="staySavvy && staySavvy.imageUrl && !staySavvy.imageUrl.includes('https://www.justmoney.co.za')" src="https://www.justmoney.co.za{{ staySavvy.imageUrl}}" class="w-100" />
                                    
                                </div>
                                <div class="position-absolute bottom d-flex bg-lightblack py-2 px-2 w-100" routerLink="/portal/news-details/{{staySavvy.id}}">
                                    <div class="col-8 text-white">
                                        <span class="text-sm aurthornam">{{staySavvy.author}}</span> <br />
                                        <a class="m-0 capt">{{staySavvy.title}}</a>

                                        </div>
                                        <div class="col-4 px-3 d-flex flex-wrap text-white justify-content-end text-right">

                                            <div class="dropdown customdrpbtn d-none">

                                                <button name="btn_DropDownDetails_Article" class="btn btn-transparent p-0 m-0 btn-sm dropdown-toggle"
                                                    aria-haspopup="true" aria-expanded="false" type="button"
                                                    data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="20"
                                                        height="20" x="0" y="0" viewBox="0 0 515.555 515.555"
                                                        style="enable-background:new 0 0 512 512" xml:space="preserve"
                                                        class="">
                                                        <g>
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a name="btn_DropDownDetails_Article" class="dropdown-item"
                                                        href="">Details</a>
                                                </div>
                                            </div>

                                            <span class="time text-sm w-100 mt-auto text-end">{{staySavvy.publishedDate | date: 'yyyy/MM/dd'}}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!-- <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div class="vdo-outer position-relative mb-2">
                                    <div class="position-relative">
                                        <i class="position-absolute articleicon">
                                            <svg id="_x31__x2C_5" enable-background="new 0 0 24 24" height="50"
                                                viewBox="0 0 24 24" width="50" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path fill="#fff"
                                                        d="m12.25 12c-.414 0-.75-.336-.75-.75v-.75h-7v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1.5c0-.414.336-.75.75-.75h8.5c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m8 18.75c-.414 0-.75-.336-.75-.75v-8c0-.414.336-.75.75-.75s.75.336.75.75v8c0 .414-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m9.25 19h-2.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h2.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 10.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 14.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 18.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                            </svg>
                                        </i>
                                        <img src="../../assets/images/news-3.jpg" class="w-100" />
                                    </div>
                                    <div class="position-absolute bottom d-flex bg-lightblack py-2 w-100">
                                        <div class="col-9 text-white">

                                            <span class="text-sm">Angelicque White</span> <br />
                                            <h6 class="m-0">What ocean microbes reveal about the changing climate</h6>

                                        </div>
                                        <div class="col-3 d-flex flex-wrap text-white justify-content-end text-right">

                                            <div class="dropdown customdrpbtn ">

                                                <button class="btn btn-transparent p-0 m-0 btn-sm dropdown-toggle"
                                                    aria-haspopup="true" aria-expanded="false" type="button"
                                                    data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="20"
                                                        height="20" x="0" y="0" viewBox="0 0 515.555 515.555"
                                                        style="enable-background:new 0 0 512 512" xml:space="preserve"
                                                        class="">
                                                        <g>
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="/portal/news-details">Details</a>
                                                </div>
                                            </div>

                                            <span class="time w-100 mt-auto">13:05</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="vdo-outer position-relative mb-2">

                                    <div class="embed-responsive embed-responsive-4by3"><iframe
                                            allowfullscreen="allowfullscreen" height="398"
                                            mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen"
                                            oallowfullscreen="oallowfullscreen"
                                            src="https://www.youtube.com/embed/NOznfLu97pA"
                                            webkitallowfullscreen="webkitallowfullscreen" width="708"></iframe></div>

                                    <div class="d-flex bg-black py-2 w-100">
                                        <div class="col-9 text-white">

                                            <span class="text-sm">Angelicque White</span> <br />
                                            <h6 class="m-0">What ocean microbes reveal about the changing climate</h6>

                                        </div>
                                        <div class="col-3 d-flex flex-wrap text-white justify-content-end text-right">

                                            <div class="dropdown customdrpbtn ">

                                                <button class="btn btn-transparent p-0 m-0 btn-sm dropdown-toggle"
                                                    aria-haspopup="true" aria-expanded="false" type="button"
                                                    data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="20"
                                                        height="20" x="0" y="0" viewBox="0 0 515.555 515.555"
                                                        style="enable-background:new 0 0 512 512" xml:space="preserve"
                                                        class="">
                                                        <g>
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="/portal/news-details">Details</a>
                                                </div>
                                            </div>

                                            <span class="time w-100 mt-auto">13:05</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                <div class="vdo-outer position-relative mb-2">
                                    <div class="position-relative">
                                        <i class="position-absolute audioicon">
                                            <svg version="1.1" width="30px" height="30px" id="Capa_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;"
                                                xml:space="preserve">
                                                <g>
                                                    <g>
                                                        <path d="M278.944,17.577c-5.568-2.656-12.128-1.952-16.928,1.92L106.368,144.009H32c-17.632,0-32,14.368-32,32v128
                                                            c0,17.632,14.368,32,32,32h74.368l155.616,124.512c2.912,2.304,6.464,3.488,10.016,3.488c2.368,0,4.736-0.512,6.944-1.568
                                                            c5.536-2.688,9.056-8.288,9.056-14.432v-416C288,25.865,284.48,20.265,278.944,17.577z M96,304.009H32v-128h64V304.009z
                                                            M256,414.697l-128-102.4V167.721l128-102.4V414.697z" />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <path d="M369.024,126.857c-6.304-6.24-16.416-6.144-22.624,0.128c-6.208,6.304-6.144,16.416,0.128,22.624
                                                            c24.16,23.904,37.472,56,37.472,90.4c0,34.4-13.312,66.496-37.472,90.4c-6.304,6.208-6.368,16.32-0.128,22.624
                                                            c3.136,3.168,7.264,4.736,11.36,4.736c4.064,0,8.128-1.536,11.264-4.64c30.304-29.92,46.976-70.08,46.976-113.12
                                                            C416,196.969,399.328,156.809,369.024,126.857z" />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <path d="M414.144,81.769c-6.272-6.208-16.416-6.176-22.624,0.096c-6.208,6.272-6.176,16.416,0.096,22.624
                                                            C427.968,140.553,448,188.681,448,240.009s-20.032,99.456-56.384,135.52c-6.272,6.208-6.304,16.352-0.096,22.624
                                                            c3.136,3.168,7.232,4.736,11.36,4.736c4.064,0,8.128-1.536,11.264-4.64C456.608,356.137,480,299.945,480,240.009
                                                            C480,180.073,456.608,123.881,414.144,81.769z" />
                                                    </g>
                                                </g>

                                            </svg>
                                        </i>
                                        <img src="../../assets/images/news-4.jpg" class="w-100" />
                                    </div>
                                    <div class="position-absolute bottom d-flex bg-lightblack py-2 w-100">
                                        <div class="col-9 text-white">

                                            <span class="text-sm">Angelicque White</span> <br />
                                            <h6 class="m-0">What ocean microbes reveal about the changing climate</h6>

                                        </div>
                                        <div class="col-3 d-flex flex-wrap text-white justify-content-end text-right">

                                            <div class="dropdown customdrpbtn ">

                                                <button class="btn btn-transparent p-0 m-0 btn-sm dropdown-toggle"
                                                    aria-haspopup="true" aria-expanded="false" type="button"
                                                    data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="20"
                                                        height="20" x="0" y="0" viewBox="0 0 515.555 515.555"
                                                        style="enable-background:new 0 0 512 512" xml:space="preserve"
                                                        class="">
                                                        <g>
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="/portal/news-details">Details</a>
                                                </div>
                                            </div>

                                            <span class="time w-100 mt-auto">13:05</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="vdo-outer position-relative mb-2">
                                    <div class="position-relative">
                                        <i class="position-absolute articleicon">
                                            <svg id="_x31__x2C_5" enable-background="new 0 0 24 24" height="50"
                                                viewBox="0 0 24 24" width="50" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path fill="#fff"
                                                        d="m12.25 12c-.414 0-.75-.336-.75-.75v-.75h-7v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1.5c0-.414.336-.75.75-.75h8.5c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m8 18.75c-.414 0-.75-.336-.75-.75v-8c0-.414.336-.75.75-.75s.75.336.75.75v8c0 .414-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m9.25 19h-2.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h2.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 10.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 14.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 18.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                            </svg>
                                        </i>
                                        <img src="../../assets/images/news-5.jpg" class="w-100" />
                                    </div>
                                    <div class="position-absolute bottom d-flex bg-lightblack py-2 w-100">
                                        <div class="col-9 text-white">

                                            <span class="text-sm">Angelicque White</span> <br />
                                            <h6 class="m-0">What ocean microbes reveal about the changing climate</h6>

                                        </div>
                                        <div class="col-3 d-flex flex-wrap text-white justify-content-end text-right">

                                            <div class="dropdown customdrpbtn ">

                                                <button class="btn btn-transparent p-0 m-0 btn-sm dropdown-toggle"
                                                    aria-haspopup="true" aria-expanded="false" type="button"
                                                    data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="20"
                                                        height="20" x="0" y="0" viewBox="0 0 515.555 515.555"
                                                        style="enable-background:new 0 0 512 512" xml:space="preserve"
                                                        class="">
                                                        <g>
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="/portal/news-details">Details</a>
                                                </div>
                                            </div>

                                            <span class="time w-100 mt-auto">13:05</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="vdo-outer position-relative mb-2">
                                    <div class="position-relative">
                                        <i class="position-absolute articleicon">
                                            <svg id="_x31__x2C_5" enable-background="new 0 0 24 24" height="50"
                                                viewBox="0 0 24 24" width="50" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path fill="#fff"
                                                        d="m12.25 12c-.414 0-.75-.336-.75-.75v-.75h-7v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1.5c0-.414.336-.75.75-.75h8.5c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m8 18.75c-.414 0-.75-.336-.75-.75v-8c0-.414.336-.75.75-.75s.75.336.75.75v8c0 .414-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m9.25 19h-2.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h2.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 10.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 14.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                                <g>
                                                    <path fill="#fff"
                                                        d="m20.25 18.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                                </g>
                                            </svg>
                                        </i>
                                        <img src="../../assets/images/news-6.jpg" class="w-100" />
                                    </div>
                                    <div class="position-absolute bottom d-flex bg-lightblack py-2 w-100">
                                        <div class="col-9 text-white">

                                            <span class="text-sm">Angelicque White</span> <br />
                                            <h6 class="m-0">What ocean microbes reveal about the changing climate</h6>

                                        </div>
                                        <div class="col-3 d-flex flex-wrap text-white justify-content-end text-right">

                                            <div class="dropdown customdrpbtn ">

                                                <button class="btn btn-transparent p-0 m-0 btn-sm dropdown-toggle"
                                                    aria-haspopup="true" aria-expanded="false" type="button"
                                                    data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="20"
                                                        height="20" x="0" y="0" viewBox="0 0 515.555 515.555"
                                                        style="enable-background:new 0 0 512 512" xml:space="preserve"
                                                        class="">
                                                        <g>
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                            <path xmlns="http://www.w3.org/2000/svg"
                                                                d="m303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0"
                                                                fill="#ffffff" data-original="#000000" style="" class="" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="/portal/news-details">Details</a>
                                                </div>
                                            </div>

                                            <span class="time w-100 mt-auto">13:05</span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                        </div>
                    </div>
                    <!--mobile-->

                    <!--desktop-->
                    <div class="d-none d-md-block">
                        <div class="news-contnent-desktop px-3 mb-5 " id="slidernews">


                            <owl-carousel-o (window:resize)="onResize($event)"   [options]="customOptions" *ngIf="staySavvyList != null && staySavvyList.length > 0">
                                 <!--first loop-->
                                <ng-template carouselSlide *ngFor="let staySavvy of staySavvyList; let i = index">
                                    <div class="d-flex w-100 align-items-stretch ps-1 pt-1 pb-1 spacright bg-white">

                                            <!--sec loop-->
                                            <div class="col-md-4  d-flex align-items-stretch p-1" *ngFor="let item of staySavvy; let cardIndex = index">
                                                <div class="vdo-outer position-relative mb-0 d-flex flex-wrap">
                                                    <div class="top-content w-100">
                                                        <div class="vdheight">
                                                            <span class="cat-pill" *ngIf="item.categories">{{item.categories[0]}}</span>
                                                            <div *ngIf="item.imageUrl.includes('https://www.justmoney.co.za')" class="image-holder" [ngStyle]="{'background-image': 'url(' + item.imageUrl + ')'}"></div>
                                                            <div *ngIf="!item.imageUrl.includes('https://www.justmoney.co.za')" class="image-holder" [ngStyle]="{'background-image': 'url('+'https://www.justmoney.co.za' + item.imageUrl + ')'}">
                                                            </div>
                                                        </div>
                                                        <div class="p-md-2 p-3 p-lg-3">
                                                            <div class="d-flex flex-wrap justify-content-between pb-3">
                                                                <div
                                                                    class="col-lg-6 col-md-12 ps-0 pe-md-0 pe-0 pb-md-2 text-sm">
                                                                    <i>
                                                                        <svg width="15px" height="15px" version="1.1"
                                                                            id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            x="0px" y="0px" viewBox="0 0 512 512"
                                                                            style="enable-background:new 0 0 512 512;"
                                                                            xml:space="preserve">
                                                                            <g>
                                                                                <g>
                                                                                    <polygon
                                                                                        points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                                                                255.898,401.21 416.035,502.431 369.263,318.842 		" />
                                                                                </g>
                                                                            </g>

                                                                        </svg>
                                                                    </i>
                                                                    {{item.author}}
                                                                </div>
                                                                <div
                                                                    class="col-lg-6 col-md-12 pe-0 ps-md-0 ps-3 text-sm text-right text-md-left text-lg-right">
                                                                    <i>
                                                                        <svg version="1.1" width="15px" height="15px"
                                                                            id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            x="0px" y="0px" viewBox="0 0 512 512"
                                                                            style="enable-background:new 0 0 512 512;"
                                                                            xml:space="preserve">
                                                                            <g>
                                                                                <g>
                                                                                    <path
                                                                                        d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
                                                                                c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
                                                                                h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="76" y="230" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="156" y="230" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="236" y="230" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="316" y="230" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="396" y="230" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="76" y="310" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="156" y="310" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="236" y="310" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="316" y="310" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="76" y="390" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="156" y="390" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="236" y="390" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="316" y="390" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="396" y="310" width="40"
                                                                                        height="40" />
                                                                                </g>
                                                                            </g>

                                                                        </svg>
                                                                    </i>
                                                                    {{item.publishedDate | date: 'yyyy/MM/dd'}}
                                                                </div>
                                                            </div>
                                                            <h6> {{item.title}} </h6>
                                                            <p [innerHtml]="item.intro"> 
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-start align-items-center">
                                                        <!-- old class="btn btn-custom mb-md-0 mb-3" -->
                                                        <a name="btn_NewsDetailsReadMore_Article" href="/portal/news-details/{{item.id}}"
                                                            class="btn btn-custom mb-md-0 mb-3"> Read More
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
    

                                        </div>
                                </ng-template>  
                            </owl-carousel-o>

                        </div>


                        <!-- <h2 class="page-caption mt-4 mb-4">Suggested for You</h2> 
                        <div class="news-contnent-desktop mb-5">
                            <div id="slidersuggestforyou" (window:resize)="onResize($event)" class="carousel slide" data-ride="carousel"  data-interval="false">
                                <div class="carousel-inner  d-flex align-items-stretch">
                                <div class="carousel-item active" data-interval="false">
                                    <div class="d-flex w-100 align-items-stretch">
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3 ">
                                                            <iframe src="https://www.youtube.com/embed/B9BRKW9YaUo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <div class="d-flex flex-wrap justify-content-between pb-3">
                                                            <div class="col-lg-6 col-md-12 ps-0 pe-md-0 pe-0 pb-md-2 text-sm">
                                                                <i>
                                                                    <svg width="15px" height="15px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <polygon points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                                                                255.898,401.21 416.035,502.431 369.263,318.842 		"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                Athenkosi Sawutana
                                                            </div>
                                                            <div class="col-lg-6 col-md-12 pe-0 ps-md-0 ps-3 text-sm text-right text-md-left text-lg-right">
                                                                <i>
                                                                    <svg version="1.1" width="15px" height="15px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
                                                                                c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
                                                                                h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                2021/02/23
                                                            </div>
                                                        </div>
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-1.jpg);">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3">
                                                            <iframe src="https://www.youtube.com/embed/9t4GqgLEmfo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item" data-interval="false">
                                    <div class="d-flex w-100  align-items-stretch">
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-2.jpg);">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                            
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-4.jpg);">
                                                        
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                            
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-4.jpg);">
                                                        
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item" data-interval="false">
                                    <div class="d-flex w-100  align-items-stretch">
                                        <div class="col-md-4 d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3 ">
                                                            <iframe src="https://www.youtube.com/embed/B9BRKW9YaUo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <div class="d-flex flex-wrap justify-content-between pb-3">
                                                            <div class="col-lg-6 col-md-12 ps-0 pe-md-0 pe-0 pb-md-2 text-sm">
                                                                <i>
                                                                    <svg width="15px" height="15px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <polygon points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                                                                255.898,401.21 416.035,502.431 369.263,318.842 		"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                Athenkosi Sawutana
                                                            </div>
                                                            <div class="col-lg-6 col-md-12 pe-0 ps-md-0 ps-3 text-sm text-right text-md-left text-lg-right">
                                                                <i>
                                                                    <svg version="1.1" width="15px" height="15px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
                                                                                c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
                                                                                h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                2021/02/23
                                                            </div>
                                                        </div>
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-1.jpg);">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3">
                                                            <iframe src="https://www.youtube.com/embed/9t4GqgLEmfo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <a class="carousel-control-prev" href="#slidersuggestforyou" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#slidersuggestforyou" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                                </a>
                            </div>
                        
                        </div> -->

                        <!-- <h2 class="page-caption mt-4 mb-4">Trending</h2> 
                        <div class="news-contnent-desktop mb-5">
                            <div id="sliderTrending" (window:resize)="onResize($event)" class="carousel slide" data-ride="carousel"  data-interval="false">
                                <div class="carousel-inner  d-flex align-items-stretch">
                                <div class="carousel-item active" data-interval="false">
                                    <div class="d-flex w-100 align-items-stretch">
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3 ">
                                                            <iframe src="https://www.youtube.com/embed/B9BRKW9YaUo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <div class="d-flex flex-wrap justify-content-between pb-3">
                                                            <div class="col-lg-6 col-md-12 ps-0 pe-md-0 pe-0 pb-md-2 text-sm">
                                                                <i>
                                                                    <svg width="15px" height="15px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <polygon points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                                                                255.898,401.21 416.035,502.431 369.263,318.842 		"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                Athenkosi Sawutana
                                                            </div>
                                                            <div class="col-lg-6 col-md-12 pe-0 ps-md-0 ps-3 text-sm text-right text-md-left text-lg-right">
                                                                <i>
                                                                    <svg version="1.1" width="15px" height="15px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
                                                                                c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
                                                                                h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                2021/02/23
                                                            </div>
                                                        </div>
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-1.jpg);">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3">
                                                            <iframe src="https://www.youtube.com/embed/9t4GqgLEmfo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item" data-interval="false">
                                    <div class="d-flex w-100  align-items-stretch">
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-2.jpg);">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                            
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-4.jpg);">
                                                        
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                            
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-4.jpg);">
                                                        
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item" data-interval="false">
                                    <div class="d-flex w-100  align-items-stretch">
                                        <div class="col-md-4 d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3 ">
                                                            <iframe src="https://www.youtube.com/embed/B9BRKW9YaUo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <div class="d-flex flex-wrap justify-content-between pb-3">
                                                            <div class="col-lg-6 col-md-12 ps-0 pe-md-0 pe-0 pb-md-2 text-sm">
                                                                <i>
                                                                    <svg width="15px" height="15px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <polygon points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                                                                255.898,401.21 416.035,502.431 369.263,318.842 		"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                Athenkosi Sawutana
                                                            </div>
                                                            <div class="col-lg-6 col-md-12 pe-0 ps-md-0 ps-3 text-sm text-right text-md-left text-lg-right">
                                                                <i>
                                                                    <svg version="1.1" width="15px" height="15px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
                                                                                c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
                                                                                h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                2021/02/23
                                                            </div>
                                                        </div>
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-1.jpg);">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3">
                                                            <iframe src="https://www.youtube.com/embed/9t4GqgLEmfo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <a class="carousel-control-prev" href="#sliderTrending" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#sliderTrending" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                                </a>
                            </div>
                            
                        </div>

                        <h2 class="page-caption mt-4 mb-4">Most Viewed</h2> 
                        <div class="news-contnent-desktop mb-5">
                            <div id="sliderMostviewed" (window:resize)="onResize($event)" class="carousel slide" data-ride="carousel"  data-interval="false">
                                <div class="carousel-inner  d-flex align-items-stretch">
                                <div class="carousel-item active" data-interval="false">
                                    <div class="d-flex w-100 align-items-stretch">
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3 ">
                                                            <iframe src="https://www.youtube.com/embed/B9BRKW9YaUo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <div class="d-flex flex-wrap justify-content-between pb-3">
                                                            <div class="col-lg-6 col-md-12 ps-0 pe-md-0 pe-0 pb-md-2 text-sm">
                                                                <i>
                                                                    <svg width="15px" height="15px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <polygon points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                                                                255.898,401.21 416.035,502.431 369.263,318.842 		"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                Athenkosi Sawutana
                                                            </div>
                                                            <div class="col-lg-6 col-md-12 pe-0 ps-md-0 ps-3 text-sm text-right text-md-left text-lg-right">
                                                                <i>
                                                                    <svg version="1.1" width="15px" height="15px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
                                                                                c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
                                                                                h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                2021/02/23
                                                            </div>
                                                        </div>
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-1.jpg);">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3">
                                                            <iframe src="https://www.youtube.com/embed/9t4GqgLEmfo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item" data-interval="false">
                                    <div class="d-flex w-100  align-items-stretch">
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-2.jpg);">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                            
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-4.jpg);">
                                                        
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                            
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-4.jpg);">
                                                        
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item" data-interval="false">
                                    <div class="d-flex w-100  align-items-stretch">
                                        <div class="col-md-4 d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3 ">
                                                            <iframe src="https://www.youtube.com/embed/B9BRKW9YaUo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <div class="d-flex flex-wrap justify-content-between pb-3">
                                                            <div class="col-lg-6 col-md-12 ps-0 pe-md-0 pe-0 pb-md-2 text-sm">
                                                                <i>
                                                                    <svg width="15px" height="15px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <polygon points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                                                                255.898,401.21 416.035,502.431 369.263,318.842 		"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                Athenkosi Sawutana
                                                            </div>
                                                            <div class="col-lg-6 col-md-12 pe-0 ps-md-0 ps-3 text-sm text-right text-md-left text-lg-right">
                                                                <i>
                                                                    <svg version="1.1" width="15px" height="15px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                                    <g>
                                                                        <g>
                                                                            <path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
                                                                                c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
                                                                                h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="230" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="76" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="156" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="236" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="316" y="390" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <rect x="396" y="310" width="40" height="40"/>
                                                                        </g>
                                                                    </g>
                        
                                                                    </svg>
                                                                </i>
                                                                2021/02/23
                                                            </div>
                                                        </div>
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="image-holder" style="background-image: url(../../assets/images/news-1.jpg);">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-lg-3 p-md-2">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4  d-flex align-items-stretch">
                                            <div class="vdo-outer position-relative mb-2 d-flex flex-wrap">
                                                <div class="top-content w-100">
                                                    <div class="vdheight">
                                                        <div class="embed-responsive embed-responsive-4by3">
                                                            <iframe src="https://www.youtube.com/embed/9t4GqgLEmfo" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" width="708" height="398"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 p-md-2 p-lg-3">
                                                        <h6>WHY YOU NEED A WILL AND OTHER RELATED QUESTIONS - ANSWERED</h6>
                                                        <p>
                                                            JustMoney spoke to an insurance expert to help you answer some of the burning questions you might have regarding wills. Find out if yours were answered.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="buttonholder w-100 mt-auto pb-3 px-3 d-flex justify-content-between align-items-center">
                                                    <a href="/portal/news-details" class="btn btn-custom text-uppercase mb-md-0 mb-3"> Read More </a>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <a class="carousel-control-prev" href="#sliderMostviewed" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#sliderMostviewed" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                                </a>
                            </div>
                            
                        </div> -->
                    </div>
                    <!--desktop-->
                </div>
                <!-- <div class="col-md-2 px-0 mt-4 ">
                    <div class="position-relative d-md-block d-none  mb-4">
                        <button class="catebut w-100" (click)=toggleDisplayDiv() >Categories
                            <i class="ps-2">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="15" height="15" x="0" y="0" viewBox="0 0 426.66667 426.66667" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path xmlns="http://www.w3.org/2000/svg" d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0" fill="#ffffff" data-original="#000000" style="" class=""/></g></svg>
        
                            </i>
                        </button>
                        <div class="catlist" [hidden]="isShowDiv">
                            <nav class="owl-filter-bar">
                                <a href="#" class="item" data-owl-filter="*">All</a>
                                <a href="#" class="item" data-owl-filter=".banking" >Banking (633)</a>
                                <a href="#" class="item" data-owl-filter=".ccards" >Credit Cards (27)</a>
                                <a href="#" class="item" data-owl-filter=".financial">Financial News (1233)</a>
                                <a href="#" class="item" data-owl-filter=".investments">Investments (238)</a>
                                <a href="#" class="item" data-owl-filter=".insurance">Insurance (222)</a>
                                <a href="#" class="item" data-owl-filter=".debt">Debt (179)</a>
                                <a href="#" class="item" data-owl-filter=".medical">Medical aid (97)</a>
                            </nav>
                        </div>
                    </div>
                    <div class="">
                        <h2 class="sidepanel-caption mt-4 mb-2">Deals</h2>
                    <div class="delscontnt text-center mb-3 bg-white px-2 py-2">
                        <img src="../../assets/images/news-5.jpg" alt=""/>
                        <p class="m-0">Lorem Ipsum</p>
                    </div>
                    <div class="delscontnt text-center mb-3 bg-white px-2 py-2">
                        <img src="../../assets/images/news-6.jpg" alt=""/>
                        <p class="m-0">Lorem Ipsum</p>
                    </div>
                    <div class="text-center pb-3">
                        <a class="ssbtn" href="/portal/news-details">See more</a> 
                    </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</section>