import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../services/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DashboardService } from '../services/dashboard.service';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Helper } from '../helpers/helper';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { SessionService } from '../services/active-session-management.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  host: { 'class': 'd-flex flex-column h-100 register-bg' },
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  @ViewChild('updateIncomeModel', { static: false }) updateIncomeModel: ElementRef;
  modalOptions: NgbModalOptions;

  accountData: any = {};
  otherAccountsData: any = {};
  showMore = {
    accountsSummary: false,
    judgmentInfo: false,
    debtRestructure: false,
    previousSearchInfo: false,
    propertyInfo: false
  };
  creditReportYear; creditReportMonth;
  isDataAvailable: true;
  creditHealthInfo: {
    grossIncome: '',
    totalCurrentBalance: '',
    totalMonthlyInstalments: ''
  };
  env = environment;
  updatedDate = '';
  updateIncomeForm;
  updateIncomeErrorMsg = '';
  UpdateIncomeDisabled = true;

  constructor(
    private accountService: AccountService,
    private dashboardService: DashboardService,
    private oauthService: OAuthService,
    private helper: Helper,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private userService: UserService,
    private sessionService:SessionService
  ) { }

  ngOnInit() {
    this.sessionService.checkActiveSession();
    this.creditReportYear = new Date().getFullYear();
    this.creditReportMonth = new Date().getMonth() + 1;
    this.accounts();
    this.getCreditHealthInfo();
    this.getCreditHistory();

    
 
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    var date = new Date();
    var hour = date.getUTCHours() + 2;
    var day = date.getDay();
    var minutes = date.getUTCMinutes();
    console.log("this is hour", hour, day, minutes); 
    console.log("time1", (hour >= 7 && hour <= 18) && (day > 0 && day < 6));
    if(day == 1 || day == 2 || day == 3 || day == 4)
    {
      if(hour == 8 && minutes >= 30){
        this.createChat();
      }
      if(hour >=8 && hour <= 17){
        this.createChat();
      }
    }
    if( day == 5){
      if(hour == 8 && minutes >= 30){
        this.createChat();
      }
      if(hour >=8 && hour <= 16){
        this.createChat();
      }
    }

    this.updateIncomeForm = this.formBuilder.group({
      GrossIncome: ['', [Validators.required, Validators.min(0)]],
    });

  }
  get updateIncomeFormControls() { return this.updateIncomeForm.controls; }
  
  //#region  Accounts
  accounts() {

    var accountsData = sessionStorage.getItem('accounts');

    if(accountsData!=null) {
      this.BindAccounts(JSON.parse(accountsData)); 
      return;
    }

    this.accountService.accounts().subscribe((data: any) => { 
      this.BindAccounts(data);
      sessionStorage.setItem('accounts', JSON.stringify(data));
    }, (err: HttpErrorResponse) => { });
  }
 
  private BindAccounts(data: any) { 
    this.accountData = data?.accountInformation;
    this.otherAccountsData = data?.otherAccounts;
  }

  //#endregion Accounts


  getCreditHistory() {
    
    var loadCreditHistoryData = sessionStorage.getItem('loadCreditHistory');
    if(loadCreditHistoryData!=null) {
      var data =JSON.parse(loadCreditHistoryData); 
      this.isDataAvailable = data.isDataAvailable;
      this.updatedDate = data.createdDate;
      return;
    }

    const param = { Year: this.creditReportYear, Month: this.creditReportMonth };
    this.dashboardService.creditHistory(param).subscribe((data: any) => { 
      this.isDataAvailable = data.isDataAvailable;
      this.updatedDate = data.createdDate;
    }, (err: HttpErrorResponse) => { });
  }

  getCreditHealthInfo() { 
    
    var loadData = sessionStorage.getItem('load');
    var loadCreditHealthInfoData = sessionStorage.getItem('loadAccountCreditHealthInfo');
    if(loadCreditHealthInfoData!=null) {
      var data =JSON.parse(loadCreditHealthInfoData); 
      this.creditHealthInfo =  data?.creditHealthInfo;
      return;
    }

    const param = { Year: this.creditReportYear, Month: this.creditReportMonth };
    this.dashboardService.creditHealthInfo(param).subscribe((data: any) => {
      if(data!=null) {
    sessionStorage.removeItem('loadAccountCreditHealthInfo');
    sessionStorage.setItem('loadAccountCreditHealthInfo', JSON.stringify(data));
      this.creditHealthInfo = data.creditHealthInfo; 
      }
    }, (err: HttpErrorResponse) => { });
  }

  scrollToElement(id: any): void {
    const element = document.getElementById(id);
    if (element != null) {
      const offset = 100;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }
  createChat(){
    if(!environment.hideChat)
    {
      if ((window as any).fcWidget){
        var userData = this.oauthService.getIdentityClaims();
        (window as any).fcWidget.init({
          token: this.env.freshChatToken,
          host: this.env.freshChatHost,
          tags: ["JustMoney"],
          externalId: userData['name'],     // user’s id unique to your system
          firstName: userData['given_name'],              // user’s first name
          lastName: userData['family_name']
        });
      }
    }
  }

  showUpdateIncomeModel() {
    if (this.isDataAvailable) {
      this.UpdateIncomeDisabled = true;
      this.updateIncomeForm.patchValue({
        GrossIncome: "R " + this.creditHealthInfo?.grossIncome
      });
      this.modalService.open(this.updateIncomeModel, this.modalOptions);
    }
  }

    enableUpdateIncome() {
      var regexPatternIncome = /^R\d+/;
      var income = this.updateIncomeForm.value.GrossIncome.toString().replace(/\s/g, "");
      this.UpdateIncomeDisabled = false;

      if (this.updateIncomeForm.value.GrossIncome.toString().trim() === 'R' || !(regexPatternIncome.test(income))) {
        this.updateIncomeForm.patchValue({
          GrossIncome: ''
        });
        this.UpdateIncomeDisabled = true;
      }
    }

    updateGrossIncome() {
      this.updateIncomeErrorMsg = '';
      if (this.updateIncomeForm.invalid) {
        this.updateIncomeForm.markAllAsTouched();
        return;
      }
  
      if (this.toNumber(this.updateIncomeForm.value.GrossIncome) >= 0) {
        this.updateIncomeForm.value.GrossIncome = parseFloat(this.toNumber(this.updateIncomeForm.value.GrossIncome));
      }
      this.userService.updateGrossIncome(this.updateIncomeForm.value).subscribe((data: any) => {
        this.modalService.dismissAll();
        this.updateLoad();
        this.getDebtConsolidationOnIncomeUpdate();
      },
        (err: HttpErrorResponse) => {
          this.updateIncomeErrorMsg = err.error;
        });
    }
    getDebtConsolidationOnIncomeUpdate()
    {
        this.userService.getDebtConsolidation().subscribe((data: any) => {     
        }, (err: HttpErrorResponse) => {
        });
    }

  toNumber(val: any) {
    val = val || '';
    const valArr = val.toString().split('');
    const valFiltered = valArr.filter((x: number) => !isNaN(x));
    const valProcessed = valFiltered.join('').trim();
    return valProcessed;
  }

  updateLoad() {
    var loadData = sessionStorage.getItem('load');
    const param = { Year: this.creditReportYear, Month: this.creditReportMonth };
    sessionStorage.removeItem('loadProductsQualify');
    this.dashboardService.creditHealthInfo(param).subscribe((data: any) => {
    sessionStorage.setItem('loadAccountCreditHealthInfo', JSON.stringify(data));
    sessionStorage.setItem('loadCreditHealthInfo', JSON.stringify(data));
    this.creditHealthInfo = data.creditHealthInfo; 
    if(loadData != null){ 
      var loadJson = JSON.parse(loadData);
      loadJson.creditHealthInfo = data;        
      sessionStorage.setItem('load', JSON.stringify(loadJson));   
    }
    }, (err: HttpErrorResponse) => { });
  }
}
