import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WelcomeTextService {

  env = environment;
  constructor(private http: HttpClient) { }

  getLatestWelcomeMessage() {
    return this.http.post(this.env.webApiUrl + 'api/WelcomeText/GetLatestWelcomeMessage',null);
  }
  
  updatedWelcomeId(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/WelcomeText/UpdatedWelcomeTextId', data);
  }

}
