import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BaseService {
   
  constructor(private httpClient: HttpClient) {}
 
  Post(url: string, data: any): Observable<any> { 
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }
 
  get(url: string, data: any): Observable<any> {
    return this.httpClient.get<any>(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) { 
    return throwError('Error: ', error.error); 
  }
 

}
