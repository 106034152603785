<div *ngIf="accountData==null && accountData?.accountInformation?.length== 0 && accountData?.accountInformation?.length<= 0;">
  <section class="pt-3">
    <div class="container">
      <div class="d-flex flex-wrap cshadow cshadownew p-md-4 p-3 rounded bg-white">
        <p class="text-center bureauNull m-0 text-red col-12 ">
          Vericred Credit Bureau has little to no information on you and your credit history. If you think that you
          should be seeing information here but can't, please ask a Financial Assessor how to submit a query
          to credit bureau.
        </p>
      </div>
    </div>  
  </section>
</div>


  <section class="bg-grey b5containeradjust pt-4 pb-4  pb-md-5">
    <div class="container">
      <div class="d-flex flex-wrap">
        <div class="col-12 col-md-6 px-3 text-center text-md-start">
          <h2>Account Summary</h2>
          <div class="divider_yellow"></div>
          <p>Take a look below to see all details of your active accounts.</p>
        </div>
        <div class="col-12 col-md-6 px-3">
          <div class="row gx-lg-4 gx-2 crw align-items-stretch">
            <div class="col position-relative d-flex align-items-stretch">
              <div class="health_card orange" (click)="showUpdateIncomeModel()">
                <h3 class="text-white totcp">Total <strong>Monthly Income</strong></h3>
                <span name="btnUpdateIncomePopupAccountPage" *ngIf="isDataAvailable || isDataAvailable==null" >
                  <i class="edit-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="17" height="22" x="0" y="0" viewBox="0 0 401.52289 401" style="enable-background:new 0 0 512 512" xml:space="preserve" class="width-adjust">
                        <path xmlns="http://www.w3.org/2000/svg" d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" fill="#ffffff" data-original="#000000" stroke="white" stroke-width="15"/>              
                        <path xmlns="http://www.w3.org/2000/svg" d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" fill="#ffffff" data-original="#000000" class="" stroke="white" stroke-width="20"/>                
                    </svg>
                  </i>    
                </span>
                <div class="divider"></div>
                <h3 class="text-white font-weight-bold"> R {{creditHealthInfo?.grossIncome | currency:' ': 'symbol' : '1.0-0'}} </h3>
              </div>
            </div>

            <div class="col position-relative d-flex align-items-stretch">
              <div class="health_card lblue">
                <h3 class="text-white totcp">Total <strong>Current Balance</strong></h3>
                <div class="divider"></div>
                <h3 class="text-white font-weight-bold"> R {{creditHealthInfo?.totalCurrentBalance | currency:' ': 'symbol' : '1.0-0'}} </h3>
              </div>
            </div>

            <div class="col position-relative d-flex align-items-stretch">
              <div class="health_card blue">
                <h3 class="text-white totcp">Total <strong>Monthly Repayment</strong></h3>
                <div class="divider"></div>
                <h3 class="text-white font-weight-bold"> R {{creditHealthInfo?.totalMonthlyInstalments | currency:' ': 'symbol' : '1.0-0'}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex px-3 mt-4">
        <div class="col">
          <div id="accordion_summary" class="accordion agrey"
            *ngIf="accountData?.accountInformation!=null && accountData?.accountInformation?.length>0">

            <div class="card" *ngFor="let accInfo of accountData?.accountInformation; let i = index">
              <div class="card-header" id="heading{{i}}">
                <h5 class="mb-0">
                  <button name="btn_{{accInfo.name}}_AccountPage" class="btn btn-link d-flex align-items-center collapsed" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse_' + i" aria-expanded="true" aria-controls="collapse{{i}}">
                    <h4 class="title font-weight-bold title_lblue text-white">{{accInfo.name}}</h4>
                    <h4 class="value"><span class="text-lblue font-weight-bold"></span>
                      {{accInfo.current_Balance | currency:'R ' : 'symbol' : '1.0-0'}}</h4>
                    <div class="clear"></div>
                  </button>
                </h5>
              </div>

              <div id="collapse_{{i}}" class="collapse" aria-labelledby="heading{{i}}" data-bs-parent="#accordion_summary">
                <div class="card-body">
                  <div class="row g-3 pt-2">
                    <div class="col">
                      <p><strong> Account Opened:</strong> {{accInfo.date_Account_Opened | date: 'dd-MM-yyyy'}} </p>
                      <p><strong> Opening Balance Credit
                          Limit:</strong> {{accInfo.opening_Balance_Credit_Limit | currency:' ': 'symbol' : '1.0-0'}}
                      </p>
                      <p><strong> Current Balance:</strong>
                        {{accInfo.current_Balance | currency:' ': 'symbol' : '1.0-0'}}</p>
                      <p><strong> Instalment Amount:</strong>
                        {{accInfo.installment_Amount | currency:' ': 'symbol' : '1.0-0'}} </p>
                    </div>
                    <div class="col mt-0 mt-md-3">
                      <p><strong> Overdue Amount:</strong> {{accInfo.amount_Overdue | currency:' ': 'symbol' : '1.0-0'}}
                      </p>
                      <p><strong> Account No:</strong> {{accInfo.account_No}} </p>
                      <p><strong> Acc Status:</strong> {{accInfo.acc_Status}} </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-4 bg-white b5containeradjust pb-md-5 pb-4 judgmentInfo">
    <div class="container">
      <div class="d-flex">
        <div class="col-12 col-lg-12 col-md-12 px-3">
          <h2 class="text-center text-md-start">Judgements & Legal Action</h2>
          <div class="divider_yellow"></div>
          <div id="accordion_judgements" class="accordion "
            *ngIf="otherAccountsData?.judgmentInformation!=null && otherAccountsData?.judgmentInformation?.length>0">

            <div class="card" *ngFor="let accInfo of otherAccountsData.judgmentInformation; let i = index">
              <div class="card-header" id="headingj{{i}}">
                <h5 class="mb-0">
                  <button name="btn_{{accInfo.plaintiff}}_AccountPage" class="btn btn-link d-flex align-items-center  collapsed" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapsej_' + i" aria-expanded="true" aria-controls="collapsej{{i}}">
                    <h4 class="title font-weight-bold title_lblue text-white">{{accInfo.plaintiff}}</h4>
                    <h4 class="value"><span
                        class="text-lblue font-weight-bold"></span>{{accInfo.amount | currency:'R '}}</h4>
                    <div class="clear"></div>
                  </button>
                </h5>
              </div>

              <div id="collapsej_{{i}}" class="collapse" aria-labelledby="headingj{{i}}"
                data-bs-parent="#accordion_judgements">
                <div class="card-body">
                  <div class="row pt-2">
                    <div class="col-12 col-md-6">
                      <p><strong> Amount:</strong> {{accInfo.amount | currency:' '}} </p>
                      <p><strong> Application Date:</strong> {{accInfo.application_Date | date: 'dd-MM-yyyy'}} </p>
                      <p><strong> Court Name:</strong> {{accInfo.court_Name}} </p>
                    </div>
                    <div class="col-12 col-md-6">
                      <p><strong> Case Type:</strong> {{accInfo.case_Type}}  </p>
                                <p><strong> Case Reason:</strong> {{accInfo.case_Reason}} </p>
                      <p><strong> Case Number:</strong> {{accInfo.case_Number}} </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>


          </div>
          <div *ngIf="otherAccountsData?.judgmentInformation == null || otherAccountsData?.judgmentInformation?.length == 0">
            You do not have any judgements and legal action against you
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-4 bg-grey b5containeradjust pb-md-5 pb-4 debtCoun">
    <div class="container">
      <div class="d-flex">
        <div class="col-12 col-lg-12 col-md-12 px-3">
          <h2 class="text-center text-md-start">Debt Counselling</h2>
          <div class="divider_yellow"></div>
          <div id="accordion_debt" class="accordion agrey halfpaccor"
            *ngIf="otherAccountsData?.debtRestructureReview!=null && otherAccountsData?.debtRestructureReview.length>0">

            <div class="card" *ngFor="let accInfo of otherAccountsData?.debtRestructureReview; let d = index">
              <div class="card-header" id="headingdebt_{{d}}">
                <h5 class="mb-0">
                  <button name="btn_{{accInfo.counsellor_First_Name}}_AccountPage" class="btn btn-link d-flex align-items-stretch collapsed" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapsedebt_' + d" aria-expanded="true" aria-controls="collapsedebt_{{d}}">
                    <h4 class="title font-weight-bold title_yellow text-white">{{accInfo.counsellor_Registration_Number}}</h4>
                    <h4 class="value"><span class="text-yellow font-weight-bold"></span>
                      {{accInfo.counsellor_First_Name}} {{accInfo.counsellor_Last_Name}}</h4>
                    <div class="clear"></div>
                  </button>
                </h5>
              </div>

              <div id="collapsedebt_{{d}}" class="collapse" aria-labelledby="headingdebt_{{d}}"
                data-bs-parent="#accordion_debt">
                <div class="card-body">
                  <div class="row pt-2">
                    <div class="col-12 col-md-6">
                      <p><strong> Status Date:</strong> {{accInfo.status_Date | date: 'dd-MM-yyyy'}}</p>
                    </div>
                    <div class="col-12 col-md-6">
                      <p><strong> Debt Review Status:</strong> {{accInfo.debt_Review_Status}} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="otherAccountsData?.debtRestructureReview == null || otherAccountsData?.debtRestructureReview?.length == 0">
            You are not under debt counselling
          </div>

        </div>

        <div class="col-12 col-lg-5 d-none mt-3 mt-md-0 px-3">
          <div class="bordered_bottom pb-1">
            <h3 class="font-weight-bold">Understanding Your Score</h3>
            <p class="text-grey">Learn how we calculate your score</p>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <a href="#">
                <div class="blog_item blue">
                  <img src="assets/images/blog_image.jpg" alt="">
                  <div class="content">
                    <h6 class="fwmed text-white">79% of women are in charge of their money</h6>
                    <div class="divider"></div>
                    <p class="sm text-white">30 Sep 2020</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-6">
              <a href="#">
                <div class="blog_item lblue">
                  <img src="assets/images/blog_image.jpg" alt="">
                  <div class="content">
                    <h6 class="fwmed text-white">79% of women are in charge of their money</h6>
                    <div class="divider"></div>
                    <p class="sm text-white">30 Sep 2020</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="offer_banner_yellow mt-2">
                <h6 class="text-white mb-0">Learn How To</h6>
                <h4 class="text-white mb-0 font-weight-bold">Manage Money</h4>
                <img src="assets/images/piggy.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-4 bg-white b5containeradjust pb-md-5 pb-4 search_history">

    <div class="container">
      <div class="d-flex">
        <div class="col-12 col-lg-12 col-md-12 px-3">
          <h2 class="text-center text-md-start">Recent Search History</h2>
          <div class="divider_yellow"></div>
          <div class="px-3" *ngIf="otherAccountsData?.previousSearchInformation!=null && otherAccountsData?.previousSearchInformation?.length>0">

            <div class=" d-flex align-items-center item m-md-0"
              *ngFor="let accInfo of otherAccountsData.previousSearchInformation; let i = index">
              <div class="col-1 col-md-1 hide320"><img src="assets/images/clothing_blue.svg" alt=""></div>
              <div class="col-7 col-md-6">
                <h4 class="font-weight-bold text-lblue mb-0">{{accInfo.company_Name}}</h4>
              </div>
              <div class="col-5 col-md-4 text-end">
                <p class="text-blue mb-0"> {{accInfo.search_Date | date: 'dd MMMM yyyy'}}</p>
              </div>
            <!--  <div class="col-2"><a href="#" class="text-link font-weight-bold text-lblue">View</a></div> -->
            </div>

          </div>
          <div *ngIf="otherAccountsData?.previousSearchInformation == null || otherAccountsData?.previousSearchInformation?.length == 0">
            You do not have any recent search history
          </div>
        </div>
      </div>
    </div>

  </section>

  <section class="pt-4 bg-white d-md-none pb-4">
    <div class="">
      <a name="btn_RedireactSolutionPage_DashBoard"  href="/portal/solutions">
      <div class="d-flex justify-content-center">
        
        <div class="col-12 d-flex bg-orange px-3 py-4">
          <div class="col-5 text-center  px-3 py-2">
            <h3 class="mbanner-caption mb-4">
              View your suggested solutions
            </h3>
            
            <!-- old class="" -->
            <span class="reportbtn btn-black mx-auto">
              Take me there
            </span>
          </div>
        </div>
        
      </div>
    </a>
    </div>
  </section>
`

  <section class="pt-4 b5containeradjust bg-white pb-md-5 pb-4">
    <div class="container">
      <div class="d-flex justify-content-center">
        <div class="col-md-9 col-12 px-md-3 px-3 text-center">
          <h5 class="font-weight-bold updatefnt mb-4">Updated {{updatedDate | date:"dd LLLL yyyy"}}</h5>
          <P class="pxtxt">
            Please note that all credit data is sourced from the credit bureau. If you disagree with any information retrieved from the bureau, you can log a dispute now by clicking <a name ="btn_VccbEnquiry_AccountPage" class="hlinktxt" target="_blank" href="https://www.vccb.co.za/vici/enquiry/enquiryLog/JustMoney">here</a>.
          </P>
        </div>
      </div>
      
    </div>
  </section>
<!--
  <section class="pt-4 b5containeradjust bg-white pb-4 mb-5">
    <div class="container">
      <div class="row text-center text-md-start">
        <div class="col">
          <h3 class="font-weight-bold">Property Information</h3>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div id="accordion_judgements" class="accordion agrey"
            *ngIf="otherAccountsData.propertyOwnerDetail!=null && otherAccountsData.propertyOwnerDetail.length>0">

            <div class="card" *ngFor="let accInfo of otherAccountsData.propertyOwnerDetail; let i = index">
              <div class="card-header" id="headingp{{i}}">
                <h5 class="mb-0">
                  <button class="btn btn-link d-flex align-items-center  collapsed" data-toggle="collapse"
                    [attr.data-target]="'#collapsep_' + i" aria-expanded="true" aria-controls="collapsep{{i}}">
                    <h4 class="title font-weight-bold title_lblue text-white"><img src="assets/images/clothing_a.svg"
                        alt="">{{accInfo.street_Number}}</h4>
                    <h4 class="value"><span class="text-bal font-weight-bold"></span>{{accInfo.street_Name}}</h4>
                    <div class="clear"></div>
                  </button>
                </h5>
              </div>

              <div id="collapsep_{{i}}" class="collapse" aria-labelledby="headingp{{i}}"
                data-parent="#accordion_judgements">
                <div class="card-body">
                  <div class="row pt-2">
                    <div class="col-12 col-md-6">
                      <p><strong> Purchase Date:</strong> {{accInfo.purchase_Date | date: 'dd-MM-yyyy'}}</p>
                      <p><strong> Property Type:</strong> {{accInfo.property_type}}</p>
                      <p><strong> Person Type:</strong> {{accInfo.person_Type}}</p>
                      <p><strong> Registrar:</strong> {{accInfo.registrar}}</p>
                    </div>
                    <div class="col-12 col-md-6">
                      <p><strong> Address :</strong> {{accInfo.street_Number}} {{accInfo.street_Name}}</p>
                      <p><strong> Postal Code:</strong>{{accInfo.postal_Code}} </p>
                      <p><strong> Title Deed No:</strong> {{accInfo.title_Deed_No}}</p>
                      <p><strong> Size :</strong>{{accInfo.size}} </p>
                    </div>


                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </section>
-->
  
<section class="py-4">
  <app-bureau-data-provided></app-bureau-data-provided>
</section>


<ng-template #updateIncomeModel let-modal>
  <div class="modal-header orangeheader">
    <h4 class="modal-title text-white" id="modal-basic-title">Update your monthly income</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" name="btn_UpdateIncomemModel_Close_DashboardPage" (click)="modal.dismiss('Cross click')" isCloseButton="true">
      <svg enable-background="new 0 0 256 256" height="30px" id="Layer_1" version="1.1" viewBox="0 0 256 256" width="30px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M137.051,128l75.475-75.475c2.5-2.5,2.5-6.551,0-9.051s-6.551-2.5-9.051,0L128,118.949L52.525,43.475  c-2.5-2.5-6.551-2.5-9.051,0s-2.5,6.551,0,9.051L118.949,128l-75.475,75.475c-2.5,2.5-2.5,6.551,0,9.051  c1.25,1.25,2.888,1.875,4.525,1.875s3.275-0.625,4.525-1.875L128,137.051l75.475,75.475c1.25,1.25,2.888,1.875,4.525,1.875  s3.275-0.625,4.525-1.875c2.5-2.5,2.5-6.551,0-9.051L137.051,128z" fill="#fff"/></svg>
    </button>
  </div>
  <form class="capture-income-form" [formGroup]="updateIncomeForm" (ngSubmit)="updateGrossIncome()">
    <div class="modal-body">
      <div *ngIf="updateIncomeErrorMsg!=''" class="alert alert-danger" role="alert">
        {{updateIncomeErrorMsg}}
      </div>
      <div class="form-group fullwidth ">
        <div class="form-field  gi-hld">
          <p>This is your take-home salary calculated after tax. </p>
          <div class="row">
            <div class="col-md-8 col-sm-12">
                <input class="form-control" type="text" appCurrencyMask formControlName="GrossIncome"
                                id="currency-number" (input)="enableUpdateIncome()" isRequired="true">
                <div *ngIf="updateIncomeFormControls.GrossIncome.touched && updateIncomeFormControls.GrossIncome.errors"
                  class="invalid-feedback">
                  <div *ngIf="updateIncomeFormControls.GrossIncome.errors.required">Take-home salary is required</div>
                  <div *ngIf="updateIncomeFormControls.GrossIncome.errors.min">Invalid Take-home salary</div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12 text-sm-right text-md-center text-end">      
                <button name="btnUpdateGrossIncomeAccountPage" type="submit" class="toppbtn updatebtn" [class.toppbtn-disabled]="UpdateIncomeDisabled" [disabled]="UpdateIncomeDisabled">Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<!-- Dashboard HTML End-->