import {Component, OnChanges, Input, SimpleChange} from '@angular/core';

@Component({
    selector: 'app-password-strength-bar',
        styles: [`
    ul#strengthBar {
        display:inline;
        list-style:none;
        margin:0;
        margin-left:4px;
        padding:0;
        vertical-align:2px;
    }
    .point:last {
        margin:0 !important;
    }
    .point {
        background:#DDD;
        border-radius:2px;
        display:inline-block;
        height:5px;
        margin-right:1px;
        width:20px;
    }`],
    template: `
    <div id="strength" #strength>
        <small>{{barLabel}}</small>
        <ul id="strengthBar">
            <li class="point" [style.background-color]="bar0"></li><li class="point" [style.background-color]="bar1"></li>
            <li class="point" [style.background-color]="bar2"></li><li class="point" [style.background-color]="bar3"></li>
            <li class="point" [style.background-color]="bar4"></li>
        </ul>
        <small class="invalid-feedback">{{errorLabel}}</small>
    </div>
`
})
export class PasswordStrengthBar implements OnChanges {
    @Input() passwordToCheck: string;
    @Input() barLabel: string;
    bar0: string;
    bar1: string;
    bar2: string;
    bar3: string;
    bar4: string;

    private colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
    public errorLabel: string;

    private measureStrength(pass: string) {
        let score = 0;
        // award every unique letter until 5 repetitions
        const letters = {};
        for (let i = 0; i < pass.length; i++) {
          letters[pass[i]] = (letters[pass[i]] || 0) + 1;
          score += 5.0 / letters[pass[i]];
        }
        // bonus points for mixing it up
        const variations = {
          digits: /\d/.test(pass),
          lower: /[a-z]/.test(pass),
          upper: /[A-Z]/.test(pass),
          nonWords: /[$@$!%*?& "%'()*+,-./:;<=>[\]^_`{|}~#]/.test(pass),
          length: pass.length >= 8,
        };

        this.errorLabel = '';

        if (!variations.length) {
          this.errorLabel = 'Be at least 8 characters';
        } else if (!variations.digits) {
          this.errorLabel = 'At least one number';
        } else if (!variations.lower) {
          this.errorLabel = 'At least one lowercase letter';
        } else if (!variations.upper) {
          this.errorLabel = 'At least one uppercase letter';
        } else if (!variations.nonWords) {
          this.errorLabel = 'At least one special character';
        }

        let variationCount = 0;
        for (let check in variations) {
          variationCount += (variations[check]) ? 1 : 0;
        }
        return variationCount;
    }

    private getColor(score: number) {
      const idx = score - 1;
      return {
        idx: idx + 1,
        col: this.colors[idx]
      };
    }

    ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
        const passwordToCheck = 'passwordToCheck';
        const password = changes[passwordToCheck].currentValue;

        this.setBarColors(5, '#DDD');
        if (password) {
            const c = this.getColor(this.measureStrength(password));
            this.setBarColors(c.idx, c.col);
        }
    }

    private setBarColors(count: any, col: any) {
        for (let n = 0; n < count; n++) {
            this['bar' + n] = col;
        }
    }
}
