import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class RegistrationRoutingService {



  constructor(public router: Router, private http: HttpClient,
    private activatedRoute: ActivatedRoute) { }

  routeTo(webSource) {

    if (webSource != null && webSource != '') {
      if (webSource.toLowerCase() == 'cars') {
        this.router.navigateByUrl('/cars-registration' + this.queryStringParams());
      } else if (webSource.toLowerCase() == 'wingfield') {
        this.router.navigateByUrl('/wingfield' + this.queryStringParams());
      } else if (webSource.toLowerCase() == 'blu') {
        this.router.navigateByUrl('/blu' + this.queryStringParams());
      }else {
        this.router.navigateByUrl('/register' + this.queryStringParams());
      }
    }
    else {
      this.router.navigateByUrl('/register' + this.queryStringParams());
    }


  }

  queryStringParams() { //check there if query strings are there then one by one take in loop and append in one url
    let queryString = '';
    this.activatedRoute.queryParamMap.subscribe((params) => {

      var num = 0;
      while (num < params.keys.length) {
        var splitChar = '&';
        if (num == 0) {
          splitChar = '?';
        }
        queryString += splitChar + params.keys[num] + '=' + params["params"][params.keys[num]];
        num++;
      }

    });
    console.log(queryString);
    return queryString;
  }

  
  getScrollData() { //check there if query strings are there then one by one take in loop and append in one url
    let fragString = '';
    this.activatedRoute.fragment.subscribe((params) => {
        fragString = params;
    });
    return fragString;
  }

}
