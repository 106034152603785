import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SecurityQuestionService } from '../services/security-question.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { LocationStrategy } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { RegistrationRoutingService } from '../services/registration-routing.service';

@Component({
  selector: 'app-security-question',
  templateUrl: './security-question.component.html',
  styleUrls: ['./security-question.component.css'],
})
export class SecurityQuestionComponent implements OnInit {
  @ViewChild('backButtonError', { static: false }) backButtonError: ElementRef;
  modalOptions: NgbModalOptions;

  securityQuestionList = [];
  errorMsg = ''; securityQuestionErrorMsg = '';
  securityQuestionAttempt = 1;
  successMsg = '';
  time;
  securityQuestion: any;
  questionIndex = 0;
  totalQuestion = 5;
  correctAnswarId = 0;
  isActivePrevNext = false;
  isbuttonSubmit = false;
  isDefalutMessage = true;
  WebSource = '';
  allowForAnotherPage = false;
  IsBureauDown = false;
  constructor(
    private spinner: NgxSpinnerService,
    private securityQuestionService: SecurityQuestionService,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private location: LocationStrategy,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private registrationRouting: RegistrationRoutingService
  ) {

    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      this.isDefalutMessage = false;
      if (this.allowForAnotherPage == false) {
        history.pushState(null, null, window.location.href);
        this.modalService.open(this.backButtonError, this.modalOptions);
      }
    });

  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunload(event: any) {
    if (this.allowForAnotherPage == false) {
      event.preventDefault();
      return false;
    }
  }



  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && (params.websource || params.WebSource || params.WEBSOURCE|| params.webSource)) {
        this.WebSource = params.websource || params.WebSource || params.WEBSOURCE|| params.webSource;
      }
    });

    var onLoadCheck = localStorage.getItem('OnLoadCheck');
    var tempToken = localStorage.getItem('tempToken');

    if (onLoadCheck == '' || onLoadCheck == null && (tempToken != null && tempToken != '')) {
      localStorage.setItem('OnLoadCheck', '1');
      this.openSecurityQuestions(1, () => {
        this.startTimeOut();
      });
    } else {
      this.removeLocalStorage();
      this.doNavigation();
      return;
    }

    // this.openSecurityQuestions(1,()=>{
    //   this.startTimeOut();
    // });

  }

  stopNavigation() {
    this.modalService.dismissAll();
    this.isDefalutMessage = true;
  }

  doNavigation() {
    this.allowForAnotherPage = true;
    this.removeLocalStorage();
    this.modalService.dismissAll();
    this.registrationRouting.routeTo(this.WebSource);
  
  }

  removeLocalStorage() {
    localStorage.removeItem('OnLoadCheck');
    localStorage.removeItem('tempToken');
  }

  securityQuestionVerficationFailed() {
    this.hideSecurityQuestion();
  }

  openSecurityQuestions(part, callback) {
    this.correctAnswarId = 0;
    this.questionIndex = 0;
    this.isbuttonSubmit = false;
    if (part === 1) {
      this.getSecurityQuestions();
    }
    else if (part === 2) {
      this.getSecurityQuestionsPart2();
      this.securityQuestionPrevNext('');
    }

    if (callback) {
      callback();
    }
  }

  securityQuestionPrevNext(nextprev) {
    if (this.securityQuestionList.length > 0) {
      this.correctAnswarId = this.questionIndex >= 0 ? this.securityQuestionList[this.questionIndex].securityAnswerId : 0;
      if (nextprev == 'next') {
        if (this.correctAnswarId === 0 || this.correctAnswarId == null || this.questionIndex >= this.totalQuestion - 1) return;
        //this.securityQuestionList[this.questionIndex].securityAnswerId=this.correctAnswarId;
        this.correctAnswarId = 0;
        this.questionIndex = this.questionIndex + 1;
      }
      else if (nextprev == 'prev') {
        if (this.questionIndex <= 0) return;
        this.questionIndex = this.questionIndex - 1;
        //this.correctAnswarId=this.securityQuestionList[this.questionIndex].securityAnswerId;
      }
    }
  }

  getSequrityList() {
    console.log(this.securityQuestionList);
  }

  updateQuestionAttemt() {

    const totalQuestionAttemt = this.securityQuestionList.filter(
      question => question.securityAnswerId !== 0 && question.securityAnswerId != null);
    console.log(this.securityQuestionList);
    this.securityQuestionErrorMsg = '';
    if (totalQuestionAttemt && totalQuestionAttemt.length >= this.totalQuestion)
      this.isbuttonSubmit = true;
    this.securityQuestionPrevNext('next');
  }

  getSecurityQuestions() {

    this.securityQuestionService.getSecurityQuestionsPart1().subscribe((data: any) => {
      this.securityQuestionList = data;
      this.totalQuestion = this.securityQuestionList.length;
      console.log(this.securityQuestionList);
      if (data.length === 0) {
        this.IsBureauDown = true;
      } else {
        this.IsBureauDown = false;
      }

    },
      (err: HttpErrorResponse) => {
        console.log(err);
        if (err.status === 501) {

          //this.resetRegisterForm();
          this.securityQuestionErrorMsg = err.error;

          this.securityQuestionVerficationFailed();

        }
      });
  }

  validateQuestions() {
    this.isDefalutMessage = false;
    this.securityQuestionErrorMsg = '';

    const notMarkedQuestions = this.securityQuestionList.filter(
      question => question.securityAnswerId === 0 || question.securityAnswerId == null);

    if (notMarkedQuestions && notMarkedQuestions.length > 0) {
      this.securityQuestionErrorMsg = 'Please attempt all the questions';
      return;
    }

    const param = { SecurityQuestion: this.securityQuestionList, Attempt: this.securityQuestionAttempt };
    this.securityQuestionService.validateSecurityQuestions(param).subscribe((data: any) => {
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        'event': 'New User Register Successfully'
      });
      this.login();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 422) {
          if (this.securityQuestionAttempt === 1) {
            this.securityQuestionAttempt = 2;
            this.openSecurityQuestions(2, null);
          } else {
            this.securityQuestionVerficationFailed();
            this.securityQuestionErrorMsg = err.error;
            localStorage.setItem('errorMsg', err.error);
          }
        } else {
          this.securityQuestionErrorMsg = err.error;
          localStorage.setItem('errorMsg', err.error);
        }
      });
  }

  getSecurityQuestionsPart2() {
    this.isDefalutMessage = true;
    this.securityQuestionErrorMsg = 'We were unable to verify you based on your previous answers. Please try again.';

    const param = { SecurityQuestion: this.securityQuestionList };
    this.securityQuestionService.getSecurityQuestionsPart2(param).subscribe((data: any) => {
      this.securityQuestionList = data;
      console.log(this.securityQuestionList);
      this.totalQuestion = this.securityQuestionList.length;

      if (data.length === 0) {
        this.IsBureauDown = true;
      } else {
        this.IsBureauDown = false;
      }

    },
      (err: HttpErrorResponse) => {
      });
  }

  login() {
    this.allowForAnotherPage = true;
    this.isDefalutMessage = false;
    localStorage.removeItem('loginFired');
    this.spinner.show();
    this.azureAdlogin('User Registered Successfully');
  }

  azureAdlogin(message) {
    this.removeLocalStorage();
    let submissionId = '';
    if (sessionStorage.getItem('submissionId')) {
      submissionId = sessionStorage.getItem('submissionId');
    }

    this.oauthService.initLoginFlow('', {
      submissionId, ccAppUrl: window.location.origin,
      instrumentationKey: environment.appInsights.instrumentationKey, message
    });
  }

  hideSecurityQuestion() {
    this.isDefalutMessage = false;
    localStorage.removeItem('loginFired');

    this.doNavigation();
  }

  startTimeOut() {
    window.onload = () => {
      this.resetTimer();
    };

    document.onmousemove = () => {
      this.resetTimer();
    };

    document.onkeypress = () => {
      this.resetTimer();
    };

  }

  resetTimer() {
    if (this.time !== 'stop') {
      console.log('no Idle');
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        console.log('timeout');
        this.modalService.dismissAll();
        this.doNavigation();
        this.errorMsg = 'Your session has timed-out and your registration could not be completed.';
        localStorage.setItem('errorMsg', this.errorMsg);

        this.securityQuestionVerficationFailed();

      }, 3 * 60 * 1000);
    }
  }

  stopTimer() {
    clearTimeout(this.time);
    this.time = 'stop';
    console.log('Timer Stop');
    console.log(this.time);
  }

}
