import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsService } from '../services/appinsights.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  env = environment;
  appInsights: ApplicationInsights;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private authService: OAuthService,
    private appInsightsService: AppInsightsService
  ) {
    this.appInsights = appInsightsService.appInsightsObj();

  }

  totalRequests = 0;
  completedRequests = 0; 
  showLoader: boolean = true;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showLoader=true;
    const excludeShowLoader = ['Product/GetProductsQualify','api/submission/create','api/track/action','api/track/field',
    'api/track/actionCumField'];

    for (const item of excludeShowLoader) {
      if (request.url.includes(item)) {
        this.showLoader=false;
        return next.handle(request);
      }      
    }

    if(this.showLoader){
    this.spinner.show();
    this.totalRequests++;
    }

    if (request.url.indexOf(environment.azureAdConfig.b2cDomain) === -1) {
      request = request.clone({
        headers: this.addExtraHeaders(request.headers)
      });
    }

    return next.handle(request).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {

          if (request.urlWithParams.startsWith(this.env.webApiUrl)) {      
           
              if(request.body){
                if(request.body.hasOwnProperty('Password') || request.body.hasOwnProperty('ConfirmPassword')) 
                 {
                   request.body.Password = "****";
                   request.body.ConfirmPassword = "****";
                 }
              }
          this.appInsights.trackEvent({
            name: 'APIRequestResponseSuccess',
            properties: {
              URL: request.urlWithParams,
              RequestParam: request.body,
              Response: evt,
              SubmissionId: sessionStorage.getItem('submissionId'),
              UserId: this.cookieService.get('userId')
            }
          });
       
        }
       
        }
      }),
      catchError(
        err =>
          new Observable<HttpEvent<any>>(observer => {
            if (err instanceof HttpErrorResponse) {
               const errResp = err as HttpErrorResponse;

              if (request.urlWithParams.startsWith(this.env.webApiUrl)) {
             
                  if(request.body){
                    if(request.body.hasOwnProperty('Password') || request.body.hasOwnProperty('ConfirmPassword')) 
                     {
                       request.body.Password = "****";
                       request.body.ConfirmPassword = "****";
                     }
                  }
              this.appInsights.trackEvent({
                name: 'APIRequestResponseException',
                properties: {
                  URL: request.urlWithParams,
                  RequestParam: request.body,
                  Response: err,
                  SubmissionId: sessionStorage.getItem('submissionId'),
                  UserId: this.cookieService.get('userId')
                }
              });

                  
              if (errResp.status === 401 || errResp.status === 0) {

                ///Note on remove Cookies or Storage:- What code you going to apply here for remove Keys, Must apply the same code in(header.component, app.component)
                this.cookieService.delete('submissionId', '/');
                this.cookieService.delete('uType', '/');
                this.cookieService.delete('userId', '/');
                localStorage.removeItem('isLoginSuccess'); 
                sessionStorage.clear();
                //End Note
                this.authService.logOut();
              }
            }
            }
            observer.error(err);
            observer.complete();
          })
      )
    ).pipe(
      finalize(() => {
       
        this.completedRequests++;
        if (this.completedRequests === this.totalRequests) {
          this.spinner.hide();
          this.completedRequests = 0;
          this.totalRequests = 0;
       
      }      
      })
      
    );
  }

  private addExtraHeaders(headers: HttpHeaders): HttpHeaders {
    let submissionId = '';
    if (sessionStorage.getItem('submissionId')) {
      submissionId = sessionStorage.getItem('submissionId');
    }
    headers = headers.append('SubmissionId', submissionId);
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    headers = headers.append('Version', 'V1');
    if (localStorage.getItem('tempToken')) {
      headers = headers.append('Authorization', `Bearer ${localStorage.getItem('tempToken')}`);
    }
    return headers;
  }
}
