import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header.component';
import { FooterComponent } from './footer.component';
import { ContactComponent } from './contact.component';
import { CallMeBackComponent } from './callmeback.component';
import { RouterModule } from '@angular/router';
import { NumericFieldDirective } from '../helpers/directive/numeric-field.directive';
import { TrackingDirective } from '../helpers/directive/tracking.directive';
import { PasswordStrengthBar } from '../helpers/components/password-strength-bar';
import { PasswordStrengthCheck } from '../helpers/components/password-strength-check';
import { FormatTimePipe } from '../helpers/pipe/format-time.pipe';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
    declarations: [CallMeBackComponent, HeaderComponent, FooterComponent, ContactComponent, 
        NumericFieldDirective, TrackingDirective,PasswordStrengthBar,PasswordStrengthCheck, FormatTimePipe],
    exports: [CallMeBackComponent, HeaderComponent, FooterComponent, ContactComponent,
         CommonModule, FormsModule, ReactiveFormsModule, NumericFieldDirective, TrackingDirective,PasswordStrengthBar,
         PasswordStrengthCheck, FormatTimePipe]

})
export class SharedModule { }
