<!-- Header HTML Start -->
<header>
    <!-- Fixed navbar -->
    <nav class="navbar navbar-expand-md nav-cutom b5containeradjust">
        <div class="container">
            <a name="btn_JustmoneyLogo_SecurityQuestion" class="logo-hld navbar-brand p-0" href="https://www.justmoney.co.za/"  target="_blank" >
                <img src="../../assets/images/logoJM.png" alt="" width="192px"/>
            </a>
        </div>
    </nav>
</header>
<!-- Header HTML End -->

<section *ngIf="securityQuestionList && securityQuestionList.length > 0">
    <div class="forgotfrm forgothight d-flex align-items-center py-md-5 py-3 b5containeradjust"
        style="background: url('../../assets/images/seq.png') #e59a2c; background-size: 95%;background-repeat: no-repeat;background-position: right 22%;">
        <div class="container">
            <div class="d-flex">
                <div class="col-md-2 px-0 col-2 d-flex  justify-content-start">
                    <a *ngIf="questionIndex>0" href="javascript:void(0)" class="nextbtn"
                        (click)="securityQuestionPrevNext('prev')">
                        <svg height="60px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
                            viewBox="0 0 512 512" width="60px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " fill="#fff" />
                        </svg>

                    </a>
                </div>
                <div class="col-8 px-0 col-lg-6 col-md-8">
                    <h2 class="mb-2 topqn text-center">Questions {{questionIndex + 1}} of {{totalQuestion}}</h2>
                    <h4 class="btmqn text-center">{{securityQuestionList[questionIndex].question}}</h4>
                </div>
                <div class="col-md-2 px-0 col-2 d-flex justify-content-end">
                    <a *ngIf="questionIndex<4 && securityQuestionList[questionIndex].securityAnswerId!=null"
                        href="javascript:void(0)" class="nextbtn" (click)="securityQuestionPrevNext('next')">

                        <svg height="60px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
                            viewBox="0 0 512 512" width="60px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <polygon points="160,115.4 180.7,96 352,256 180.7,416 160,396.7 310.5,256 " fill="#fff" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="container my-lg-4 my-3 my-md-3">
        <div class="col-xl-8 col-md-10 mx-auto">

            <div *ngIf="securityQuestionErrorMsg!=''" class="alert alert-danger" role="alert">
                {{securityQuestionErrorMsg}}
            </div>

        </div>
        <div class="col-xl-8 col-md-10 mx-md-auto px-0 d-flex flex-wrap align-items-stretch">

            <label class="check-container"
                *ngFor="let answer of securityQuestionList[questionIndex].securityAnswer; let i=index">
                <input name="option-{{i}}" type="radio" id="option-{{i}}" [value]="answer.answerId"
                    metadata="QuestionIndex-{{questionIndex + 1}}  --- option-{{i}}"
                    [(ngModel)]="securityQuestionList[questionIndex].securityAnswerId"
                    (change)="updateQuestionAttemt()" />
                <span class="checkmark">{{answer.answer}}</span>
            </label>

        </div>
    </div>
    <div class="button-sectionouter">
        <div class="container">
            <div
                class="col-xl-8 button-section py-2 col-md-10 mx-auto d-flex justify-content-center align-items-center">

                <button *ngIf="isbuttonSubmit" id="btn_sequrityquestion_submit" type="submit" name="btn_sequrityquestion_submit" class="submit"
                    (click)="validateQuestions()">Submit</button>
            </div>
        </div>
    </div>


</section>
<section *ngIf="IsBureauDown">
    <div class="button-sectionouter">
        <div class="container">
            <div
                class="col-xl-8 button-section py-2 col-md-10 mx-auto d-flex justify-content-center align-items-center">
                <div class="alert alert-danger" role="alert"> 
                    A technical error has occurred. Please contact us on: 0875376113, for assistance with completing
                    your registration. 
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Modal creditHistoryUpdate -->
<ng-template #backButtonError let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Navigation Warning</h4>
        <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>-->
    </div>
    <div class="modal-body">
        <div>You have not completed your registration verification. If you leave now, you will not be able to continue
            this journey. You will have to speak to a coach. Are you sure you want to Leave?</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn-border" (click)="stopNavigation()"
            name="btn_sequrityquestion_navigation_No">No</button>
        <button type="button" class="btn btn-primary" (click)="doNavigation()"
            name="btn_sequrityquestion_navigation_Yes">Yes</button>
    </div>
</ng-template>