import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ChatService {

  env = environment;

  constructor(private http: HttpClient) { }

  saveChat(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/Chat/Save', data);
  }

}
