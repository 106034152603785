import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private cookieService: CookieService, private oAuthService: OAuthService) {}

  // Always sets the 'activeSession' cookie with a value of 'true'
  setActiveSession() {
    const sessionValue = 'true';

    // Set the cookie with an expiration time (240 minutes)
    const expirationDate = new Date();
   // expirationDate.setMinutes(expirationDate.getMinutes() + 240);
   expirationDate.setHours(expirationDate.getHours() + 1);
   


    try {
      // Set the cookie with the path '/', undefined domain (current domain), Secure, and Strict attributes
      this.cookieService.set('activeSession', sessionValue, expirationDate, '/', undefined, false, 'Strict');
    } catch (error) {
    }
  }

  checkActiveSession() {
    try {
      const activeSession = this.cookieService.get('activeSession');
      console.log(`Checking cookie 'activeSession': ${activeSession}`);
      if (activeSession !== 'true') {
        this.clearSession();
        this.oAuthService.logOut();
      }
    } catch (error) {
      console.error('Error checking cookie:', error);
      this.clearSession();
      this.oAuthService.logOut();
    }
  }

  private clearSession() {
    console.log('Clearing session cookies and local storage');
    this.cookieService.delete('submissionId', '/');
    this.cookieService.delete('uType', '/');
    this.cookieService.delete('userId', '/');
    localStorage.removeItem('isLoginSuccess');
    localStorage.clear();
    sessionStorage.clear();
  }
}
