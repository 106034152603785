import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { CommunicationService } from '../services/communication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.css']
})
export class InvestmentsComponent implements OnInit {
  env = environment;
  constructor(
    private oauthService: OAuthService,
    private communicationService: CommunicationService
  ) { }

  ngOnInit() {
   this.redirectToInvestmentPage();
  }

  redirectToInvestmentPage(){

    this.communicationService.GetInvestmentsQueryParameters().subscribe((data: any) => {
      console.log(data);
       if(data!==null && data.encryptedData!==null)
       {
        let queryParam='?src='+ data.source + '&cx='+ data.cx + '&wc-encode='+ data.encryptedData;
        let InvestmentUrl : any=data.serviceEndpoint + this.env.investmentDashboardUrl +  queryParam;
        window.location = InvestmentUrl;
        console.log(InvestmentUrl);
       }
      
    },(err: HttpErrorResponse) => {
      console.log(err);
      });
   
  }

}
