import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppInsightsService {
    appInsights: ApplicationInsights;

    constructor() {
        if (environment.appInsights.isEnable) {
            const appInsightsKey = environment.appInsights.instrumentationKey;
            if (!appInsightsKey) {
                // add custom behavior to handle this scenario
                console.error('Application Insights key not found.');
                return;
            }

            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: appInsightsKey,
                    enableAutoRouteTracking: true, // optional: auto-log all route changes
                },
            });

            
            this.appInsights.loadAppInsights();
            this.appInsights.addTelemetryInitializer(t => {
                // Update criteria as per your need.
                if (t.baseType == 'PageviewData' && t.baseData.properties.SubmissionId == undefined) {
                    // console.log('tracking failed ------ ', t);
                    // console.log('tracking failed ------ ', t.baseData.properties.SubmissionId);
                    return false; 
                }

                console.log('tracking ------ ' + t.baseType + ' - ' + t.ext.trace.name);
                return true; // enable everything else
              });
            
            console.log('log from appinsights service');
        }
    }

    appInsightsObj() {
        return this.appInsights;
    }

    // // helper methods to track a variety of events and metric
    // logPageView(name?: string, url?: string) {
    //     this.appInsights.trackPageView({
    //         name: name,
    //         uri: url,
    //     });
    // }

    // logEvent(name: string, properties?: { [key: string]: any }) {
    //     this.appInsights.trackEvent({ name: name }, properties);
    // }

    // logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    //     this.appInsights.trackMetric({ name: name, average: average }, properties);
    // }

    // logException(exception: Error, severityLevel?: number) {
    //     this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    // }

    // logTrace(message: string, properties?: { [key: string]: any }) {
    //     this.appInsights.trackTrace({ message: message }, properties);
    // }
}