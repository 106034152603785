import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NonNullAssert } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdatePhoneNumberService {

  env = environment;
  constructor(private http: HttpClient) { }


  updatePhoneNumber(data: any){
    return this.http.post(this.env.webApiUrl + 'api/User/UpdateUserPhoneNumberRequest', data);
  }

 
  validateOtpandUpdatePhonenUmber(data: any){
    return this.http.post(this.env.webApiUrl + 'api/User/UpdateUserPhoneNumber', data);
  }
  
}
