import {Component, OnChanges, Input, SimpleChange} from '@angular/core';

@Component({
    selector: 'app-password-strength-check',
        styles: [`.success-msg { color: #72ae49;}
                  #strength{font-size: 80%; line-height: 1.5;}`],
    template: ` <div id="strength" #strength class="invalid-feedback pt-1">
        <span >{{errorLabel}}</span>
        <span class="success-msg">{{successLabel}}</span>
    </div>`
})
export class PasswordStrengthCheck implements OnChanges {
    @Input() passwordToCheck: string;

    public errorLabel: string;
    public successLabel: string;

    private measureStrength(pass: string) {
        let score = 0;
        // award every unique letter until 5 repetitions
        const letters = {};
        for (let i = 0; i < pass.length; i++) {
          letters[pass[i]] = (letters[pass[i]] || 0) + 1;
          score += 5.0 / letters[pass[i]];
        }
        // bonus points for mixing it up
        const variations = {
          digits: /\d/.test(pass),
          lower: /[a-z]/.test(pass),
          upper: /[A-Z]/.test(pass),
          nonWords: /[$@$!%*?& "%'()*+,-./:;<=>[\]^_`{|}~#]/.test(pass),
          length: pass.length >= 8,
        };

        var popover = document.querySelectorAll("#ngb-popover-0, #ngb-popover-1"); 
        if(popover.length != 0){
          if (variations.length) {
            var passwordLen = document.getElementById('password-length');
            var lengthSp = document.getElementById('password-length-sp');
            passwordLen.classList.add('password-requirement-matched');
            lengthSp.classList.add('tick-mark');
          } else {
            var passwordLen = document.getElementById('password-length');
            var lengthSp = document.getElementById('password-length-sp');
            passwordLen.classList.remove('password-requirement-matched');
            lengthSp.classList.remove('tick-mark');
          }
          if (variations.digits) {
            var passwordDig = document.getElementById('password-digit');
            var DigitSp = document.getElementById('password-digit-sp');
            passwordDig.classList.add('password-requirement-matched');
            DigitSp.classList.add('tick-mark');
          } else {
            var passwordDig = document.getElementById('password-digit');
            var DigitSp = document.getElementById('password-digit-sp');
            passwordDig.classList.remove('password-requirement-matched');
            DigitSp.classList.remove('tick-mark');
          }
          if (variations.lower) {
            var passwordLow = document.getElementById('password-lowercase');
            var LowerSp = document.getElementById('password-lowercase-sp');
            passwordLow.classList.add('password-requirement-matched');
            LowerSp.classList.add('tick-mark');
          } else {
            var passwordLow = document.getElementById('password-lowercase');
            var LowerSp = document.getElementById('password-lowercase-sp');
            passwordLow.classList.remove('password-requirement-matched');
            LowerSp.classList.remove('tick-mark');
          }
          if (variations.upper) {
            var passwordUp = document.getElementById('password-uppercase');
            var UpperSp = document.getElementById('password-uppercase-sp');
            passwordUp.classList.add('password-requirement-matched');
            UpperSp.classList.add('tick-mark');
          } else {
            var passwordUp = document.getElementById('password-uppercase');
            var UpperSp = document.getElementById('password-uppercase-sp');
            passwordUp.classList.remove('password-requirement-matched');
            UpperSp.classList.remove('tick-mark');
          }
          if (variations.nonWords) {
            var passwordSpecial = document.getElementById('password-specialchar');
            var SpecialSp = document.getElementById('password-specialchar-sp');
            passwordSpecial.classList.add('password-requirement-matched');
            SpecialSp.classList.add('tick-mark');
          } else {
            var passwordSpecial = document.getElementById('password-specialchar');
            var SpecialSp = document.getElementById('password-specialchar-sp');
            passwordSpecial.classList.remove('password-requirement-matched');
            SpecialSp.classList.remove('tick-mark');
          }               
    }

        this.errorLabel = '';
        this.successLabel = '';

        if (!variations.length || !variations.digits || !variations.lower
            || !variations.upper || !variations.nonWords ) {
            this.errorLabel = 'Password requirements are not met';
            this.successLabel = '';
        } 
        else {
          this.successLabel = 'Password requirements are met';
            this.errorLabel = '';
        }

        let variationCount = 0;
        for (let check in variations) {
          variationCount += (variations[check]) ? 1 : 0;
        }
        return variationCount;
    }

    ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
        const passwordToCheck = 'passwordToCheck';
        const password = changes[passwordToCheck].currentValue;
        if (password) {this.measureStrength(password);}
    }
}
