import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class CMSService {

  env = environment;

  constructor(private http: HttpClient) { }

  getArticleDetails(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/Cms/GetArticleDetails', data); 
  }

  getStaySavvy(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/Cms/GetStaySavvy', data); 
  }

}
