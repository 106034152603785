export class Helper {

    //#region LocalStorageWithExpireTime
    SetLocalStorage(key, value, hours: number) {
        let now = new Date();
        const item = {
            value: value,
            expiry: now.getTime() + (hours * 3600 * 1000),
        }
        localStorage.setItem(key, JSON.stringify(item));
    }

    GetLocalStorage(key) {
        const itemStr = localStorage.getItem(key);
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value
    }

    RemoveLocalStorage(key) {
        localStorage.removeItem(key);
    }
    //#endregion LocalStorageWithExpireTime

    
}
