import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { TermsComponent } from './terms/terms.component';
import { ProductsComponent } from './products/products.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { RouterModule, UrlSerializer } from '@angular/router';
import { appRoutes } from './routes';
import { UserService } from './services/user.service';
import { DataService } from './services/data.service';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { InputValidationService } from './helpers/validators/input-validation.service';
import { NameFieldDirective } from './helpers/directive/name-field.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProfileComponent } from './profile/profile.component';
import { CurrencyMaskDirective } from './helpers/directive/currency-mask.directive';
import { SecurityQuestionService } from './services/security-question.service';
import { ForgotComponent } from './forgot/forgot.component';
import { AccountComponent } from './account/account.component';
import { AccountService } from './services/account.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { SettingComponent } from './setting/setting.component';
import { CookieService } from 'ngx-cookie-service';
import { NameFieldHyphenSpaceDirective } from './helpers/directive/name-field-hyphen-space.directive';
import { CommunicationService } from './services/communication.service';
import { ChatService } from './services/chat.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { PolicyComponent } from './policy/policy.component';
import { LoginComponent } from './login/login.component';
import { ParseAccountTypeClassPipe } from './helpers/pipe/parse-account-type-class.pipe';
import { NameFieldWithSpaceDirective } from './helpers/directive/name-field-with-space.directive';
import { BureauDataProvidedComponent } from './bureau-data-provided/bureau-data-provided.component';
import { NewsComponent } from './news/news.component';
//import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { CMSService } from './services/cms.service';
import { InvestmentsComponent } from './investments/investments.component';
import { SecurityQuestionComponent } from './security-question/security-question.component';
import { RegisterStepTwoComponent } from './registration/register-step-two/register-step-two.component';
import { CarsRegistrationComponent } from './registration/cars-registration/cars-registration.component'; 
import { RegisterStepOneBaseComponent } from './registration/register-step-one-base.component'; 
import { TrackingService } from './services/tracking.service';
import { BaseService } from './services/baseService';
import { WingfieldRegistrationComponent } from './registration/wingfield-registration/wingfield-registration.component';
import { CreditScoreHistoryComponent } from './credit-score-history/credit-score-history.component'; 
import { CellNumberUpdateComponent } from './cell-number-update/cell-number-update.component'; 
import { Helper } from './helpers/helper';
import { AppInsightsService } from './services/appinsights.service';
import { BluRegistrationComponent } from './registration/blu-registration/blu-registration.component';
import { FormatTimePipe } from './helpers/pipe/format-time.pipe';
import { AutoIdDirective } from './helpers/directive/auto-id-buttons.directive';
import { MotorVehicleFinanceCalculatorComponent } from './motor-vehicle-finance-calculator/motor-vehicle-finance-calculator.component';
import { Placement as PopperPlacement, Options } from '@popperjs/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs/operators';
export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 0,
  prefix: 'R ',
  suffix: '',
  thousands: ',',
  nullable: true
};


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AutoIdDirective,
    TermsComponent,
    ProductsComponent,
    DashboardComponent,
    HomeComponent,
    NameFieldDirective,
    ProfileComponent,
    SettingComponent,
    ForgotComponent,
    CurrencyMaskDirective,
    AccountComponent,
    NameFieldHyphenSpaceDirective,
    NameFieldWithSpaceDirective,
    PolicyComponent,
    LoginComponent,
    ParseAccountTypeClassPipe,
    BureauDataProvidedComponent,
    NewsComponent,
    NewsDetailsComponent,
    InvestmentsComponent,
    SecurityQuestionComponent,
    RegisterStepTwoComponent,
    CarsRegistrationComponent,
    RegisterStepOneBaseComponent,
    WingfieldRegistrationComponent,
    CreditScoreHistoryComponent,
    CellNumberUpdateComponent,
    BluRegistrationComponent,
    MotorVehicleFinanceCalculatorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forRoot(appRoutes),
    NgbModule,
    NgxSpinnerModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    BrowserAnimationsModule,
    CarouselModule ,
    OAuthModule.forRoot({
      resourceServer: {
          allowedUrls: [environment.webApiUrl],
          sendAccessToken: true
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.enableServiceWorker }) ,
  ],
  exports: [
    CurrencyMaskDirective
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    AuthGuard,
    SecurityQuestionService,
    UserService,
    DataService,
    AccountService,
    CookieService,
    CommunicationService,
    InputValidationService,
    ChatService,
    CMSService, 
    TrackingService,
    BaseService,
    Helper,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
     },
     AppInsightsService,
     FormatTimePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter(event => event.type === 'VERSION_READY')
        )
        .subscribe(() => {
          if (Notification.permission === 'granted') {
            new Notification('New version available', {
              body: 'Click to reload and apply the update'
            }).onclick = () => {
              window.location.reload();
            };
          } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
              if (permission === 'granted') {
                new Notification('New version available', {
                  body: 'Click to reload and apply the update'
                }).onclick = () => {
                  window.location.reload();
                };
              }
            });
          }
        });
    }
  }
 }
