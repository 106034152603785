import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent {

  @HostBinding('style.width') public readonly WIDTH = '100%';

  title = 'webapp';
  scrollToElement($element: any): void {
    console.log($element);
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
