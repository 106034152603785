import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {

  env = environment;

  constructor(private http: HttpClient) { }
 
  register(data: any) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.env.webApiUrl + 'api/user/Register', data, { headers: reqHeader });
  }

  logout() {
    return this.http.post(this.env.webApiUrl + 'api/user/Logout', null);
  }

  updateGrossIncome(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/user/UpdateUserGrossIncome', data);
  }

  validateUserBasicInfoWithBureau() {
    return this.http.post(this.env.webApiUrl + 'api/user/ValidateUserBasicInfoWithBureauForJM', null);
  }

  IsExternalLead(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/User/IsExternalLead', data);
  }

  dbcUserBasicInfoVerify() {
    return this.http.post(this.env.webApiUrl + 'api/User/DbcUserBasicInfoVerify', null);
  }

  GetDbcUserSolutionInfo(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/User/GetDbcUserSolutionInfo', data);
  }

  GetDbcUserEligibility(){
    return this.http.post(this.env.webApiUrl + 'api/User/GetDbcUserEligibility', null);
  }

    autoRegisterUser(data: any){
    return this.http.post(this.env.webApiUrl + 'api/User/AutoRegister', data);
  }

  getDebtConsolidation(){
    return this.http.post(this.env.webApiUrl + 'api/User/GetDebtConsolidation', null);
  }

  IsWingfieldUser(){
    return this.http.post(this.env.webApiUrl + 'api/User/IsWingfieldUser', null);
  }

  saveMonthlyExpenses(data: any){
    return this.http.post(this.env.webApiUrl + 'api/User/SaveMonthlyExpenses', data);
  }

  getUserVehicleFinanceAssessment(){
    return this.http.post(this.env.webApiUrl + 'api/User/GetUserVehicleFinanceAssessment', null);
  }

}
