<!----header---->
<header>
  <!-- Fixed navbar -->
 
    <nav class="navbar navbar-expand-md b5containeradjust nav-cutom">
      <div class="container ">
        <a href="javascript:void(0)" title="DebtBusters-Client" class="logo-hld navbar-brand p-0"
          [routerLink]="userData!=null?['/portal/dashboard']:['/register']">
          <img src="../../assets/images/logoJM.png" alt="" width="192px"/>
        </a>

         <!--<a class="mobile-proflink d-md-none" href="javascript:void(0)"
         routerLink="/portal/profile">
          
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 37 37">
            <g id="Group_59" data-name="Group 59" transform="translate(-0.5)">
              <path id="user-regular" d="M11.453,11.1c-1.048,0-1.552.584-3.272.584S5.96,11.1,4.908,11.1A4.91,4.91,0,0,0,0,16.011v.935A1.753,1.753,0,0,0,1.753,18.7H14.608a1.753,1.753,0,0,0,1.753-1.753v-.935A4.91,4.91,0,0,0,11.453,11.1Zm3.155,5.843H1.753v-.935a3.161,3.161,0,0,1,3.155-3.155,14.3,14.3,0,0,0,3.272.584,14.162,14.162,0,0,0,3.272-.584,3.161,3.161,0,0,1,3.155,3.155ZM8.181,10.518A5.259,5.259,0,1,0,2.922,5.259,5.26,5.26,0,0,0,8.181,10.518Zm0-8.765A3.506,3.506,0,1,1,4.675,5.259,3.512,3.512,0,0,1,8.181,1.753Z" transform="translate(10.906 8.479)" fill="#e59a2c"/>
              <g id="Ellipse_106" data-name="Ellipse 106" transform="translate(0.5)" fill="none" stroke="#e59a2c" stroke-width="2">
                <circle cx="18.5" cy="18.5" r="18.5" stroke="none"/>
                <circle cx="18.5" cy="18.5" r="17.5" fill="none"/>
              </g>
            </g>
          </svg>
          </a>-->
          <a class="navbar-toggler border-0 p-0">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 512 512" height="25px"
              viewBox="0 0 512 512" width="25px">
              <g>
                <path
                  d="m464.883 64.267h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"
                  data-original="#000000" class="active-path" data-old_color="#000000" fill="#e59a2c" />
                <path
                  d="m464.883 208.867h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"
                  data-original="#000000" class="active-path" data-old_color="#000000" fill="#e59a2c" />
                <path
                  d="m464.883 353.467h-417.766c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.012-21.137-47.149-47.117-47.149z"
                  data-original="#000000" class="active-path" data-old_color="#000000" fill="#e59a2c" />
              </g>
            </svg>
          </a>
          <div class=" navbar-collapse">
  
            <!--<span class="navbar-close d-block d-block d-md-none">
              <img src="assets/images/cross.png" alt="" width="22px" />

            </span>-->
            <div class="ms-xl-5 ms-lg-3 d-flex w-100 column-reverse">

              <ul class="navbar-nav w-100 align-items-center custom-nav">
                <li *ngIf="userData!=null">
                  <a href="javascript:void(0)" id="dashboard-header"  routerLinkActive="active-link" routerLink="/portal/dashboard"><!--<i
                      class="header-co-dashbord">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background: #60a845;padding: 3px;
                    border-radius: 3px;" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"
                        xml:space="preserve" width="24px" height="22px">
                        <g transform="matrix(0.9995 0 0 0.9995 0.127974 0.127974)">
                          <g>
                            <g>
                              <path
                                d="M176,32H16C7.168,32,0,39.168,0,48v160c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16V48    C192,39.168,184.832,32,176,32z M160,192H32V64h128V192z"
                                data-original="#000000" class="active-path" data-old_color="#000000" fill="#ffffff" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path
                                d="M176,288H16c-8.832,0-16,7.168-16,16v160c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16V304    C192,295.168,184.832,288,176,288z M160,448H32V320h128V448z"
                                data-original="#000000" class="active-path" data-old_color="#000000" fill="#ffffff" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <rect x="256" y="80" width="128" height="32" data-original="#000000" class="active-path"
                                data-old_color="#000000" fill="#0075C9" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <rect x="256" y="128" width="256" height="50" data-original="#000000" class="active-path"
                                data-old_color="#000000" fill="#ffffff" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <rect x="256" y="336" width="128" height="32" data-original="#000000" class="active-path"
                                data-old_color="#000000" fill="#0075C9" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <rect x="256" y="375" width="256" height="50" data-original="#000000" class="active-path"
                                data-old_color="#000000" fill="#ffffff" />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </i> -->Dashboard</a></li>
                    <!-- Investments unhide for release v6 -->
                    <li *ngIf="userData!=null"><a href="javascript:void(0)" id="account-header" routerLinkActive="active-link" routerLink="/portal/account"><!--<i
                      class="header-co-accounts">
                      <svg enable-background="new 0 0 26 26" id="Слой_1" version="1.1" viewBox="0 0 26 26"
                        xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="36px" height="36px">
                        <path
                          d="M23.4335938,8.8789063h-2.2537842l-0.6456299-3.6582031  c-0.1484375-0.8496094-0.9697266-1.4169922-1.8115234-1.2705078L2.2939453,6.8476563  c-0.8486328,0.1494141-1.4179688,0.9628906-1.2675781,1.8125L2.7783203,18.59375  c0.0722656,0.4111328,0.2998047,0.7705078,0.6416016,1.0107422c0.2666016,0.1865234,0.5761719,0.2841797,0.8945313,0.2841797  c0.0917969,0,0.1835938-0.0078125,0.2753906-0.0244141l0.5986328-0.1057129v0.7512207  c0,0.8623047,0.7011719,1.5634766,1.5634766,1.5634766h16.6816406c0.8623047,0,1.5644531-0.7011719,1.5644531-1.5634766V10.4423828  C24.9980469,9.5800781,24.2958984,8.8789063,23.4335938,8.8789063z M23.4980469,10.4423828v1.4707031H6.694397l0.0575562-1.5341797  L23.4980469,10.4423828z M4.2548828,18.3339844l-1.46875-8.328125L2.5546875,8.3242188l16.5029297-2.84375l0.5994873,3.3984375  H6.7519531c-0.8623047,0-1.5634766,0.7011719-1.5634766,1.5634766v7.7669067L4.2548828,18.3339844z M23.4335938,20.5732422  L6.6884766,20.5097656v-5.9414063h16.8095703v5.9414063C23.4980469,20.5449219,23.46875,20.5732422,23.4335938,20.5732422z"
                          fill="#60a845" /></svg></i>-->Credit Report</a></li>
                  <li *ngIf="userData!=null"><a href="javascript:void(0)" id="products-header" routerLink="/portal/solutions"  routerLinkActive="active-link">Solutions</a>
                  <!-- <li *ngIf="userData!=null"><a href="javascript:void(0)"  routerLinkActive="active-link" routerLink="/portal/investments">Investments</a> -->
                
                  <li *ngIf="userData!=null"><a href="javascript:void(0)" routerLink="/portal/news"  id="news-header"  routerLinkActive="active-link">Articles</a> 
                    <li *ngIf="userData!=null" class="d-md-none"><a id="profile-header" href="javascript:void(0)"  routerLinkActive="active-link"  routerLink="/portal/profile">Profile</a>
                  
               <!-- <li *ngIf="userData!=null" id="liFinancialWellness" style="display: none;"><a href="javascript:void(0)" routerLink="/portal/financial-wellness"><i
                      class="header-co-offers">
                      <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 512 512"
                        height="25px" viewBox="0 0 512 512" width="30px" class="">
                        <g>
                          <g>
                            <path
                              d="m428.82 328.109-45.963 45.962-17.678-17.678c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l28.284 28.284c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.465 10.606-4.394l56.569-56.568c5.858-5.857 5.858-15.355 0-21.213-5.854-5.858-15.352-5.858-21.211 0z"
                              data-original="#000000" class="active-path" data-old_color="#000000" fill="#60A845" />
                            <path
                              d="m218.651 323.409c-2.317-.819-4.677-1.67-7.039-2.549v-42.301c4.34 1.496 6.586 3.673 6.883 3.976 4.545 5.135 12.391 5.651 17.571 1.138 5.205-4.536 5.748-12.432 1.212-17.636-4.271-4.901-13.126-11.05-25.666-13.146v-6.891c0-6.904-5.596-12.5-12.5-12.5s-12.5 5.596-12.5 12.5v8.276c-.876.228-1.759.474-2.652.743-15.112 4.552-26.398 17.456-29.455 33.676-2.803 14.873 2.02 29.26 12.584 37.546 5.057 3.967 11.23 7.602 19.523 11.389v54.913c-6.049-.804-10.573-2.782-17.661-7.418-5.778-3.78-13.524-2.16-17.303 3.618-3.779 5.777-2.16 13.524 3.618 17.303 12.257 8.018 20.874 10.781 31.346 11.646v8.308c0 6.904 5.596 12.5 12.5 12.5s12.5-5.596 12.5-12.5v-9.476c8.214-2.01 15.684-5.989 22.01-11.801 8.459-7.772 14.168-18.302 16.076-29.65 3.923-23.332-8.264-43.612-31.047-51.664zm-36.133-16.839c-3.144-2.466-4.497-7.665-3.445-13.245.794-4.212 3.172-9.1 7.539-12.153v28.233c-1.481-.926-2.864-1.87-4.094-2.835zm42.526 64.358c-1.217 7.239-5.768 14.875-13.432 19.011v-42.469c14.657 5.875 14.302 18.287 13.432 23.458z"
                              data-original="#000000" class="active-path" data-old_color="#000000" fill="#60A845" />
                            <path
                              d="m497 262c-18.954 0-35.995-10.532-44.473-27.485l-2.11-4.222c-2.541-5.083-7.735-8.293-13.417-8.293h-80c-5.682 0-10.876 3.21-13.417 8.292l-2.11 4.222c-.813 1.625-1.706 3.191-2.67 4.694-20.477-27.615-43.211-52.189-63.802-73.323v-49.014c19.905-28.102 29.999-58.983 29.999-91.871v-10c0-8.284-6.716-15-15-15h-10c-27.78 0-64.015 17.992-80 26.746-15.985-8.754-52.22-26.746-80-26.746h-10c-8.284 0-15 6.716-15 15v10c0 32.887 10.094 63.769 30 91.872v49.019c-62.534 64.284-125 141.711-125 231.109l.001 1.076c.14 62.818 52.091 113.924 115.81 113.924h168.378c23.723 0 46.819-7.257 66.205-20.618 12.868 8.681 27.07 15.418 42.295 19.985 1.406.422 2.858.633 4.311.633s2.904-.211 4.311-.633c32.234-9.67 59.892-29.054 79.981-56.054 20.09-27.001 30.708-59.062 30.708-92.716v-85.597c0-8.284-6.716-15-15-15zm-342-135h90v30h-90zm37.305-70.124c4.734 2.829 10.646 2.829 15.384.003 11.624-6.932 44.646-24.117 67.207-26.583-.951 23.872-8.382 45.805-22.604 66.704h-104.583c-14.223-20.9-21.653-42.833-22.604-66.704 22.56 2.466 55.582 19.651 67.2 26.58zm-76.494 425.124c-47.213 0-85.707-37.678-85.81-83.978l-.001-1.022c0-78.661 58.065-150.097 116.315-210h107.373c20.142 20.68 42.345 44.696 61.996 71.397-5.817 2.345-12.136 3.603-18.684 3.603-8.284 0-15 6.716-15 15v85.597c0 33.654 10.618 65.715 30.708 92.716 4.171 5.605 8.672 10.877 13.469 15.807-12.771 7.074-27.212 10.88-41.987 10.88zm366.189-119.403c0 54.256-33.971 101.385-85 118.66-51.029-17.275-85-64.404-85-118.66v-71.995c22.701-4.285 42.437-18.2 54.125-38.603h61.75c11.688 20.402 31.424 34.318 54.125 38.603z"
                              data-original="#000000" class="active-path" data-old_color="#000000" fill="#60A845" />
                          </g>
                        </g>
                      </svg>
                    </i>Solution</a></li>-->



                    

                <li *ngIf="userData==null" class="navlinks"><a  id="register-header" href="javascript:void(0)"  routerLinkActive="active-link" routerLink="/register"
                    class="btn-border">Register</a></li>
                <li *ngIf="userData==null" class="navlinks"><a  id="login-header" href="javascript:void(0)"  routerLinkActive="active-link" (click)="checkLoginFired()"
                    class="btn-border">Login</a></li>

                <li *ngIf="userData!=null" class="drp-holder ms-auto d-md-block d-none"><a href="javascript:void(0)" id="navbarDropdown"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">


                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
                      <g id="Group_59" data-name="Group 59" transform="translate(-0.5)">
                        <path id="user-regular" d="M11.453,11.1c-1.048,0-1.552.584-3.272.584S5.96,11.1,4.908,11.1A4.91,4.91,0,0,0,0,16.011v.935A1.753,1.753,0,0,0,1.753,18.7H14.608a1.753,1.753,0,0,0,1.753-1.753v-.935A4.91,4.91,0,0,0,11.453,11.1Zm3.155,5.843H1.753v-.935a3.161,3.161,0,0,1,3.155-3.155,14.3,14.3,0,0,0,3.272.584,14.162,14.162,0,0,0,3.272-.584,3.161,3.161,0,0,1,3.155,3.155ZM8.181,10.518A5.259,5.259,0,1,0,2.922,5.259,5.26,5.26,0,0,0,8.181,10.518Zm0-8.765A3.506,3.506,0,1,1,4.675,5.259,3.512,3.512,0,0,1,8.181,1.753Z" transform="translate(10.906 8.479)" fill="#e59a2c"/>
                        <g id="Ellipse_106" data-name="Ellipse 106" transform="translate(0.5)" fill="none" stroke="#e59a2c" stroke-width="2">
                          <circle cx="18.5" cy="18.5" r="18.5" stroke="none"/>
                          <circle cx="18.5" cy="18.5" r="17.5" fill="none"/>
                        </g>
                      </g>
                    </svg>
                    <h5 class="user-name-txt">Hi {{userData.given_name}} <i _ngcontent-fba-c5=""
                        class="mnu-barrow-down"></i></h5>
                  </a>
                  <ul class="logprofile dropdown-menu " aria-labelledby="navbarDropdown">

                    <li><a href="javascript:void(0)" routerLink="/portal/profile" id="profile-header"  routerLinkActive="active-link">Profile</a></li>
                    <li *ngIf="userData!=null"><a href="javascript:void(0)" routerLink="/portal/setting" id="settings-header"  routerLinkActive="active-link">Settings</a></li>
                    <!--<li><a href="javascript:void(0)" routerLink="/portal/faq">FAQs</a></li>-->
                    

                    <li><a href="javascript:void(0)" (click)="logout()" id="logout-header-browser">Logout
                        <svg class="logout-icon" viewBox="0 0 32 32">
                          <defs>
                            <style>
                              .logout-icon .cls-1 {
                                fill: none;
                                stroke: #666;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 2px;
                              }
                            </style>
                          </defs>
                          <g id="logout">
                            <line class="cls-1" x1="15.92" x2="28.92" y1="16" y2="16" />
                            <path
                              d="M23.93,25v3h-16V4h16V7h2V3a1,1,0,0,0-1-1h-18a1,1,0,0,0-1,1V29a1,1,0,0,0,1,1h18a1,1,0,0,0,1-1V25Z" />
                            <line class="cls-1" x1="28.92" x2="24.92" y1="16" y2="20" />
                            <line class="cls-1" x1="28.92" x2="24.92" y1="16" y2="12" />
                            <line class="cls-1" x1="24.92" x2="24.92" y1="8.09" y2="6.09" />
                            <line class="cls-1" x1="24.92" x2="24.92" y1="26" y2="24" />
                          </g>
                        </svg>
                      </a></li>
                  </ul>
                </li>

                <!--<li *ngIf="userData!=null" class="mobile-display"><a href="javascript:void(0)"
                    routerLink="/portal/profile">Profile</a></li>-->
                <li *ngIf="userData!=null" class="mobile-display" ><a href="javascript:void(0)"  routerLinkActive="active-link" id="settings-header-mobile"
                    routerLink="/portal/setting">Settings</a></li>
                <!--<li *ngIf="userData!=null"><a href="javascript:void(0)" class="mobile-display"
                    routerLink="/portal/faq">FAQs</a></li>-->

                <li *ngIf="userData!=null" class="mobile-display"><a href="javascript:void(0)" id="logout_mob_header" (click)="logout()">Logout
                    <svg class="logout-icon" viewBox="0 0 32 32">
                      <defs>
                        <style>
                          .logout-icon .cls-1 {
                            fill: none;
                            stroke: #666;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 2px;
                          }
                        </style>
                      </defs>
                      <g id="logout">
                        <line class="cls-1" x1="15.92" x2="28.92" y1="16" y2="16" />
                        <path
                          d="M23.93,25v3h-16V4h16V7h2V3a1,1,0,0,0-1-1h-18a1,1,0,0,0-1,1V29a1,1,0,0,0,1,1h18a1,1,0,0,0,1-1V25Z" />
                        <line class="cls-1" x1="28.92" x2="24.92" y1="16" y2="20" />
                        <line class="cls-1" x1="28.92" x2="24.92" y1="16" y2="12" />
                        <line class="cls-1" x1="24.92" x2="24.92" y1="8.09" y2="6.09" />
                        <line class="cls-1" x1="24.92" x2="24.92" y1="26" y2="24" />
                      </g>
                    </svg>
                  </a></li>
              </ul>
              <!--<div *ngIf="userData==null" class="button-sp justify-md-content-end flex-wrap align-items-center d-flex">
                <span class="pr-3 d-none d-md-block">Need Help?</span>
                <a class="teleph d-none d-md-block" href="tel:086 999 0606">086 999 0606</a>
                <button type="button" class="btn call-back" (click)="callbackPopup()">Call me back</button>
              </div>-->
            </div>
          </div>
      </div>
    </nav>
  
</header>


<ng-template #callbackConfirmationModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Free callback request</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')" 
      name="btn_callbackConfirmationModel_Closed" isCloseButton="true">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="callbackErrorMsg!=''" class="alert alert-danger" role="alert">
      {{callbackErrorMsg}}
    </div>
    <div *ngIf="callbackSuccessMsg!=''" class="alert alert-success" role="alert">
      {{callbackSuccessMsg}}
    </div>
    <div *ngIf="callbackSuccessMsg==''">Do you want a coach to call you now?</div>
  </div>
  <div class="modal-footer" *ngIf="callbackSuccessMsg==''">
    <button type="button" class="btn-border" (click)="modal.dismiss('No')">No</button>
    <button type="button" class="btn btn-primary" (click)="callback()">Yes</button>
  </div>
</ng-template>

<ng-template #LoginBackError let-modal>
  <div class="modal-header">
    <h4 class="modal-title backetitle">Navigation Warning</h4>
       <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>-->
    </div>
    <div class="modal-body">
      <div>You have not completed your registration verification. If you leave now, you will not be able to continue this journey. You will have to speak to a coach. Are you sure you want to Leave?</div>
    </div>
    <div class="modal-footer backefooter">
      <button type="button" class="btn-border" (click)="stopNavigation()" name="btn_sequrityquestion_navigation_No">No</button>
      <button type="button" class="btn btn-primary" (click)="redirectLogin()" name="btn_sequrityquestion_navigation_Yes">Yes</button>
    </div>
  </ng-template>
