import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ProfileService {

  env = environment;
  constructor(private http: HttpClient) { }

  userProfile() {
    return this.http.post(this.env.webApiUrl + 'api/user/Profile', null);
  }

  updateProfile(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/user/UpdateUserProfile', data);
  }
}
