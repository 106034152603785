import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SapiService {

  env = environment;
  constructor(private http: HttpClient) { }


  sapiSignUp(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/Sapi/SapiProcess1Signup', data);
  }


  
}