import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CMSService } from '../services/cms.service';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css']
})
export class NewsDetailsComponent implements OnInit {

  articleId:number;
  activeRoute;
  article: any = {}; 
  
  constructor(private _activatedRoute: ActivatedRoute, private cmsService: CMSService,) { }

  ngOnInit() {
    this.activeRoute = this._activatedRoute.paramMap.subscribe(params => {
       
      this.articleId = parseInt(params.get('id'));
      var param = {
        id: this.articleId
      }

      this.cmsService.getArticleDetails(param).subscribe((data: any) => {
        this.article = data; 
        console.log(this.article.content);
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });

    });

  
  }

  ngOnDestroy() {
    this.activeRoute.unsubscribe();
  }

}
