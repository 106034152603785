<!--footer--->
<footer class="footer">
  <div class="footop pt-4 pb-4 b5containeradjust">
    <div class="container">
        <div class="px-3">
          <h3 class="font-weight-bold text-left">Contact us</h3>
        <div class="d-flex flex-wrap align-items-center justify-content-between  mt-md-4 mt-5">
          <div class="col- d-flex ps-md-0 pe-md-5  pb-md-0 spacebtn">
            <i class="locationicon"><img src="../../assets/images/i-location.png" width="40"/></i>
            <span class="foofontstyle">
              4th Floor Mutual Park,<br/>
              Jan Smuts Drive, Pinelands,<br/>
              Cape Town,<br/>
              7405</span>
          </div>
          <div class="col- d-flex ps-md-0  pe-md-5 align-items-center pb-md-0 spacebtn">
            <i class="workinghoursicon"><img src="../../assets/images/i-workinghours.png" width="60"/></i>
            <span class="foofontstyle">Monday - Thursday: 07:00 - 17:00 <br/> 
                Friday: 07:00-16:00
              </span> 
          </div>
          <div class="col- d-flex align-items-center ps-md-0  pe-5 pb-md-0 spacebtn">
            <i class="cellicon"><img src="../../assets/images/i-cell.png" width="40"/></i>
            <a id="expertContactNumberLink"  href="tel:0875376113" class="foofontstyle text-decoration-none">087 537 6113</a>
          </div>
          <div class="col- d-flex align-items-center ps-md-0 pe-md-2 pb-md-0 spacebtn">
            <i class="emailicon"><img src="../../assets/images/i-email.png" width="50"/></i>
            <a href="mailto:info@justmoney.co.za" class="foofontstyle text-decoration-none">info@justmoney.co.za</a>
          </div>
        </div>
        </div>
    </div>
  </div>
  <div class="foobot pt-3 pb-3 b5containeradjust">
    <div class="container">
      <div class=" d-flex justify-content-between px-3">
        <div class="col- ps-0">
          <a class="foofontstyle ps-0" routerLink="/policy" href="https://justmoneycreditsav.co.za/policy" target="_blank">
            <u class="text-csm ">Privacy policy</u>
          </a> 
          <br/>
          <a class="foofontstyle  ps-0 " routerLink="/terms" target="_blank"><u class="text-csm "> T's and C's</u></a>
          
          
        </div>
        <div class="col- text-right pe-0">
          <a href="javascript:void(0)" >
            <img src="../../assets/images/foologo.png" alt="" class="foologo"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--footer--->