<header>
    <!-- Fixed navbar -->
    <nav class="navbar navbar-expand-md nav-cutom b5containeradjust">
        <div class="container d-flex justify-content-start">

            <a name="btn_BluRedirect_BluRegtration"  href="javascript:void(0)" target="_blank">
                <img src="../../../assets/images/blu-logo.png" alt=""  class="blulogo ps-md-0 ps-3"/>
            </a>

            <a name="btn_JustMoneyLogo_WingFieldRegtration" class="logo-rgister navbar-brand ms-md-5 me-0" href="https://www.justmoney.co.za/" target="_blank">
                <img src="../../assets/images/logoJM.png" alt="" width="192px"/>
            </a>

        </div>
    </nav>
</header>



<!--middle section-->
<section class="features pt-md-4 pb-md-5 b5containeradjust">
    <div class="container flex-wrap d-flex justify-content-between align-items-start pt-4 pb-5">
        <div class="col-md-4">

        </div>
        <div class="col-md-8 px-md-3 px-0">
            <h1 class="text-orange topcaption">
                Register for your JustMoney profile
            </h1>
            <p class="pText">JustMoney has partnered with Blu Credit Deals to help you understand and improve your credit score.</p>
            <div class="col-md-9 px-0">
                <form [formGroup]="idNumberForm" (ngsubmit)="idNumberCheck()">
                    <div *ngIf="errorMsg!=''" class="alert alert-danger" role="alert" [innerHTML]="errorMsg"></div>
                    <input id="IdNumber" type="tel" class="form-control frm-fld" placeholder="ID Number"
                    formControlName="IdNumber" numeric-field maxlength="13">
                    <div *ngIf="idNumberFormControls.IdNumber.touched && idNumberFormControls.IdNumber.errors"
                                class="invalid-feedback">
                                <div *ngIf="idNumberFormControls.IdNumber.errors.required">ID Number is
                                    required</div>
                                <div *ngIf="idNumberFormControls.IdNumber.errors.minlength">ID Number must
                                    be 13 digits long
                                </div>
                                <div *ngIf="idNumberFormControls.IdNumber.errors.isValidIdNumber">Please
                                    enter your valid 13
                                    digits South
                                    African ID Number</div>
                            </div>
                    <div class="custom-row mb-0 pt-3 d-flex justify-content-between align-items-center">
                        <span class="d-inline-dlock haveText">Have an account? 
                            <a href="javascript:void(0)" class="logLink" name="btnLoginRegisterCarsPage"
                            (click)="azureAdlogin('')">Login</a>
                            </span>
                                   
                                <!-- old class name, btn btn-custom mt-auto mb-md-0 mb-0 subresponsive -->

                                <button class="btn btn-custom mt-auto mb-md-0 mb-0 subresponsive"
                                name="btnRegisterRegisterBluPage"
                           (click)="idNumberCheck()">Register</button>
                        
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 d-md-none d-block">
            <img class="bgdesktop" src="../../../assets/images/blu-register-bg-mobile.png" />
        </div>
    </div>
</section>
<!--middle section-->

<!-- Footer HTML Start -->
<!--footer--->
<section class="footorebottom">
    <footer class="footer ">
        <div class=" p-md-0">
            <div class="d-flex justify-content-end align-items-center">
                <div class="col-md-4 text-end text-sm text-white pe-3">Credit Bureau data <br> provided by</div>
                <div class="col- pe-md-5 pe-3 "><span class="d-block vlogobg"><img src="../../../assets/images/veri-l.png" width="130px"
                            alt=""></span></div>
            </div>
        </div>
    </footer>
</section>
<!--footer--->
<!-- Footer HTML End -->
