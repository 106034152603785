import { Component , HostBinding } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  //host: {'class': 'footorebottom'},
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  @HostBinding('style.width') public readonly WIDTH = '100%';

  title = 'webapp';
}
