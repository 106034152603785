import { Component , HostBinding, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InputValidationService } from '../helpers/validators/input-validation.service';
import { CommunicationService } from '../services/communication.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-callme-back',
  templateUrl: './callmeback.component.html',
  styleUrls: ['./callmeback.component.css']
})
export class CallMeBackComponent implements OnInit {

  @HostBinding('style.width') public readonly WIDTH = '100%';

  title = 'webapp';
  callbackForm: FormGroup;
  idNumberAndErrorMsg: any = {};
  errorMsg = '';
  successMsg = '';
  successMsgAny: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
  ) { }

  ngOnInit() {
    this.callbackForm = this.formBuilder.group({
      FullName: ['', [Validators.required]],
      PhoneNumber: ['', [Validators.required, Validators.minLength(10), InputValidationService.isValidMobile]],
    });
  }

  get callbackFormControls() { return this.callbackForm.controls; }

  callback() {
    this.errorMsg = '';
    this.successMsg = '';
    if (this.callbackForm.invalid) {
      this.callbackForm.markAllAsTouched();
      return;
    }

    // this.communicationService.callback(this.callbackForm.value).subscribe((data: any) => {
    //   this.successMsg = 'Our coach will contact you shortly';
    //   this.callbackForm.patchValue({FullName: '', PhoneNumber: ''});
    //   this.callbackForm.markAsUntouched();
    // },
    //   (err: HttpErrorResponse) => {
    //     this.errorMsg = 'Error: Please try after some time';
    //   });
  }
}
