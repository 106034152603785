<section class="profile" (window:resize)="onResize($event)">
    <div class="profile-banner b5containeradjust py-md-5" style="background-image: url(assets/images/profile_header.png); background-size: cover; background-repeat: no-repeat;">
        <div class="container py-md-0">
            <div class="d-flex  flex-wrap align-items-center">
                <div class="col-md-9 col-8 px-3 no-space-right">
                    <h1 class="page-caption text-white m-0">Your Profile Details</h1>
                </div>
                <div class="col-md-3 text-end col-4 px-3">
                    <i class="profile-banner-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="30" height="30" x="0" y="0" viewBox="0 0 401.52289 401" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path xmlns="http://www.w3.org/2000/svg" d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" fill="#e59a2c" data-original="#000000" style="" class=""/><path xmlns="http://www.w3.org/2000/svg" d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" fill="#e59a2c" data-original="#000000" style="" class=""/></g></svg>
                    </i>
                </div>
            </div>

        </div>
    </div>

    <div class="profile-content pb-md-5 b5containeradjust">
        <div class="container">
            <div class="profile-holder px-3 pb-md-5">
                <form class="w-100" [formGroup]="updateProfileForm" (ngSubmit)="updateProfile()" autocomplete="off">
                    <div class="w-100">
                        <div *ngIf="successMsg!=''" class="alert alert-success mt-3" role="alert">
                            {{successMsg}}
                        </div>
                    </div>
                    <div class="w-100">
                        <div *ngIf="successMsgWarning!=''" class="alert alert-danger " role="alert">
                            {{successMsgWarning}}
                        </div>
                    </div>
                    <h2 class="profile-frm-caption mt-md-5 mb-md-4">Personal Details</h2>
                    <div class="form-group row row-cols-lg-4 gx-lg-3 profile-frm">
                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="FirstName" class="d-none">Name</label>
                            <input id="FirstName" type="text" readonly class="form-control" placeholder="First Name"
                                formControlName="firstName" name-field>

                        </div>
                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="Surname" class="d-none">Surname</label>
                            <input id="Surname" type="text" readonly class="form-control" formControlName="surname"
                                placeholder="Surname" name-field>

                        </div>
                        <div class="col-lg-3 col-md-6 mb-28 canupdate position-relative">
                            <label for="PhoneNumber" class="d-none">Cell Phone Number </label>
                            <input id="PhoneNumber" type="tel" readonly class="form-control"
                                placeholder="Cell Phone Number" formControlName="phoneNumber" numeric-field
                                maxlength="10" isRequired="true">
                        <a  href="javascript:void(0)" routerLink="/portal/updatenumber" name="updatenumber" class="updateLink">Update</a>
                        </div>
                        <div class="col-lg-3 col-md-6 p-0 d-lg-block d-none"></div>

                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="grossIncome" class="d-none">Take Home Salary</label>
                            <input class="form-control" type="text" appCurrencyMask formControlName="grossIncome"
                                id="currency-number-prof" (blur)="changeGrossIncome()" isRequired="true" [value]="this.updateProfileForm.value.GrossIncome">
                            <div *ngIf="profileFormControls.grossIncome.touched && profileFormControls.grossIncome.errors"
                                class="invalid-feedback">
                                <div *ngIf="profileFormControls.grossIncome.errors.required">Take-home salary is
                                    required</div>
                                <div *ngIf="profileFormControls.grossIncome.errors.min">Invalid Take-home salary
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="Email" class="d-none">Email address</label>
                            <input id="Email" type="email" class="form-control" placeholder="Email address"
                                formControlName="email" isRequired="true">
                            <div *ngIf="profileFormControls.email.touched && profileFormControls.email.errors"
                                class="invalid-feedback">
                                <div *ngIf="profileFormControls.email.errors.required">Email address is required</div>
                                <div *ngIf="profileFormControls.email.errors.email">Please enter a valid email address
                                </div>
                            </div>
                        </div>
                    </div>



                    <h2 class="profile-frm-caption  mt-md-5 mb-md-4">Employer Last Updated On :
                        {{ profileFormControls.employerDate.value | date }}</h2>
                    <div class="form-group row row-cols-lg-4 gx-lg-3 profile-frm">
                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="EmployerName" class="d-none">Employer Name</label>
                            <input id="EmployerName" type="text" class="form-control" placeholder="Employer Name"
                                formControlName="employerName">
                        </div>
                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="Occupation" class="d-none">Occupation</label>
                            <input id="Occupation" type="text" class="form-control" placeholder="Occupation"
                                formControlName="occupation">
                        </div>
                    </div>


                    <h2 class="profile-frm-caption  mt-md-5 mb-md-4">Address Last Updated On :
                        {{ profileFormControls.addressDate.value | date }}</h2>
                    <div class="form-group row row-cols-lg-4 gx-lg-3 profile-frm">
                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="AddressType" class="d-none">Address Type</label>
                            <input id="AddressType" type="text" readonly class="form-control" placeholder="Address Type"
                                formControlName="addressType">

                        </div>
                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="AddressLine1" class="d-none">Street Address</label>
                            <textarea id="AddressLine1" type="text" rows="3" class="form-control"
                                placeholder="Street Address" formControlName="addressLine1"></textarea>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="AddressLine2" class="d-none">Apartment / Suite number</label>
                            <textarea id="AddressLine2" type="text" rows="3" class="form-control"
                                placeholder="Apartment / Suite number" formControlName="addressLine2"></textarea>
                        </div>
                        <div class="col-lg-3 col-md-6 p-0 d-lg-block d-none"></div>
                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="Suburb" class="d-none">Suburb</label>
                            <input id="Suburb" type="text" class="form-control" formControlName="suburb"
                                placeholder="Suburb">
                        </div>
                        <div class="col-lg-3 col-md-6 mb-28">
                            <label for="Town" class="d-none">Town</label>
                            <input id="Town" type="text" class="form-control" formControlName="town"
                                placeholder="City / Town">
                        </div>
                    </div>

                    <div class="buttonholder">
                        <!-- old class=""btn btn-custom mb-md-0 mb-3" -->
                        <button name="btnUpdateProfilePage" type="submit" [disabled]="changeDisable"
                            class="btn btn-custom mb-md-0 mb-3"> Update profile </button>
                    </div>
                </form>

            </div>
        </div>
    </div>



</section>