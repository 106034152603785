import { Injectable } from '@angular/core';

@Injectable()
export class DataService {

   private errorMessage: string;
   private idNumber: string;
   private successMsg: string;

   setIdNumberAndErrorMessage(idNumber: string, errorMessage: string) {
      this.idNumber = idNumber;
      this.errorMessage = errorMessage;
   }

   clearIdNumberAndErrorMessage() {
      this.idNumber = '';
      this.errorMessage = '';
   }

   getIdNumberAndErrorMessage(): any {
      return { idNumber: this.idNumber, errorMessage: this.errorMessage };
   }

   setSuccessMessage(successMsg: string) {
      this.successMsg = successMsg;
   }

   getSuccessMessage(): any {
      return { successMsg: this.successMsg };
   }

   clearSuccessMessage() {
      this.successMsg = '';
   }

   constructor() { }

}
