import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  env = environment;
  constructor(private http: HttpClient) { }

  getProductsQualify() {
    return this.http.post(this.env.webApiUrl + 'api/Product/GetProductsQualify', null);
  }
  UserToCreditLifeCalculator() {
    return this.http.post(this.env.webApiUrl + 'api/Product/CreditLifeCalculator', null);
  }
  CalculateVehicleFinanceFinance(data:any){
    return this.http.post(this.env.webApiUrl + 'api/VehicleFinanceCalculator/CalculateVehicleFinance',data);
  }
  GetVehicleFinanceInterestRate(): Observable<any>  {
    return this.http.get(this.env.webApiUrl + 'api/VehicleFinanceCalculator/GetVehicleFinanceInterestRate');
  }

}
