import { AuthConfig } from 'angular-oauth2-oidc';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // webApiUrl: 'https://localhost:44301/',
  webApiUrl: 'https://app-jm-api-dev-001.azurewebsites.net/',
  sessionTimeOut: 60 * 5,
  appInsights: {
    isEnable: true,
    instrumentationKey: '17f054d0-366b-4d65-ab01-32a2edeb81cb'
  },
  appTrack: {
    isEnable: true,
    brandName: 'V1',
    //apiUrl: 'https://localhost:7181/',
    apiUrl: 'https://app-jm-tracking-api-dev-001.azurewebsites.net/'
  },
  freshChatToken: 'e64992ec-a719-4268-9e6a-c2b2914c6ff7',
  freshChatHost: 'https://wchat.freshchat.com',
  investmentDashboardUrl: 'client/dashboard',
  investmentProductUrl: 'invest-online/financial-planning',
  
  azureAdConfig: {
    b2cDomain: 'b2clogin.com',
    discoveryDocumentConfig: {
      url: 'https://justmoneydev.b2clogin.com/tfp/justmoneydev.onmicrosoft.com/B2C_1_jm/v2.0/.well-known/openid-configuration'
    },
    authConfig: {
      redirectUri: window.location.origin + '/portal/dashboard',
      postLogoutRedirectUri: window.location.origin + '/login',
      responseType: 'token id_token',
      issuer: 'https://justmoneydev.b2clogin.com/tfp/8205bdb7-2547-4231-bc82-1c6559d96398/b2c_1_jm/v2.0/',
      strictDiscoveryDocumentValidation: false,
      tokenEndpoint: 'https://justmoneydev.b2clogin.com/justmoneydev.onmicrosoft.com/b2c_1_jm/oauth2/v2.0/token',
      loginUrl: 'https://justmoneydev.b2clogin.com/justmoneydev.onmicrosoft.com/b2c_1_jm/oauth2/v2.0/token',
      clientId: '1217fbbb-7ef7-43f8-82a0-a279faaa3a66',
      scope: 'openid profile https://justmoneydev.onmicrosoft.com/jm/user_impersonation',
      skipIssuerCheck: true,
      clearHashAfterLogin: true,
      oidc: true,
    },
  },
  truworthsProductRedirect: 'https://accounts.truworths.staging.stackworx.cloud/?utm_source=affiliate_justmoney&utm_medium=referral&utm_campaign=justmoney&utm_id=00172',
  identityProductRedirect: 'https://accounts.identity.staging.stackworx.cloud/?utm_source=affiliate_justmoney&utm_medium=referral&utm_campaign=justmoney&utm_id=00173',
  ssfApplyRedirectUrl:"https://url.za.m.mimecastprotect.com/s/UKWjC0gMQlTrOD1nTwiunB",
  encryptionKey: 'GN2VQ7GmoV4KZX7izvLxiHFDsEQbrvr4K2k6',
  hideChat: false,
  trackingUrl:"https://devtrc.digitechlab.co.za/lib/minified-js/tracking-lib.js",
  trackingId:"003",
  enableServiceWorker: true,
  oneSignalAppid:'f9c9b9dd-061b-402b-83d1-b1568e5e0541'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
