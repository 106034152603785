<section class="contact-hld">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <ul class="contact-list">
            <li><i class="icon icon-logo"></i></li>
            <li><h3>Contact</h3></li>
            <li><i class="icon icon-phone"></i> <a href="tel:+27860726526">+27 86 072 6526</a></li>
            <li><p class="mt-3">Monday - Thursday 07:00-19:00</p></li>
			      <li><p class="mt-3">Friday 07:00-18:00</p></li>
          </ul>
        </div>
      </div>
    </div>
  </section>