import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ProfileService } from '../services/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { DashboardService } from '../services/dashboard.service';
import { CommunicationService } from '../services/communication.service';
import { SessionService } from '../services/active-session-management.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  host: { 'class': 'd-flex flex-column h-100 register-bg ' },
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  env = environment
  updateProfileForm: FormGroup;
  getUserProfileData: any = {};

  successMsg = '';
  successMsgWarning = '';
  changeDisable = true;
  updatePhoneAllowed : boolean = true;
  phoneNumberUpdateDisabledTillDate;
  IsPhoneNumberUpdateDisabled: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private oauthService: OAuthService,
    private cookieService: CookieService,
    private router: Router,
    private dashboardService: DashboardService,
    private communicationService: CommunicationService,
    private sessionService:SessionService
  ) { 

    if (this.router.getCurrentNavigation() != null) {
      let state = this.router.getCurrentNavigation()
                .extras.state as 
      {example: string, type: string};
      if(state != undefined)
      {
        if(state.type === 'error'){
          this.successMsgWarning = state.example;
        }else{
          this.successMsg = state.example;
        }
      }
    }

  }


  ngOnInit() {
    this.sessionService.checkActiveSession();
    this.updateProfileForm = this.formBuilder.group({
      firstName: [''],
      surname: [''],
      phoneNumber: [''],
      email: ['', [Validators.required, Validators.email]],
      grossIncome: ['', [Validators.required, Validators.min(0)]],
      occupation: [''],
      employerName: [''],
      employerDate: [''],
      addressDate: [''],
      addressLine1: [''],
      addressLine2: [''],
      suburb: [''],
      town: [''],
      addressType: ['']
    });

    this.userProfile();
    let type = this.cookieService.get('uType');   
    this.registerHeight();
    if ((window as any).fcWidget){
      (window as any).fcWidget.destroy();
    }
  }

  registerHeight() {
    var headerh = $("header").height(),
    windowh = $(window).height(),
    desiredHeight = windowh,
    forgotheight = windowh - headerh - 80;
    $('.min-h').css("min-height", desiredHeight + 'px');
    $('.profile').css("min-height", forgotheight + 'px');
  }
  onResize(ev) {
    var headerh = $("header").height(),
    windowh = $(window).height(),
    desiredHeight = windowh,
    forgotheight = windowh - headerh - 80;
    $('.min-h').css("min-height", desiredHeight + 'px');
    $('.profile').css("min-height", forgotheight + 'px');
  }


  onChanges(): void {
    this.updateProfileForm.valueChanges.subscribe(val => {
      this.changeDisable = false;
    });
  }


  get profileFormControls() { return this.updateProfileForm.controls; }

  userProfile() {
    this.profileService.userProfile().subscribe((data: any) => {
      this.updateProfileForm.patchValue(data);
      setTimeout(() => {
        this.updateProfileForm.patchValue({
            addressDate: this.toDateString(data.addressDate),
            employerDate: this.toDateString(data.employerDate),
        });
        this.onChanges();
      }, 200);

    }, (err: HttpErrorResponse) => {

    });
  }

  updateProfile() {
    this.successMsg ='';
    if (this.updateProfileForm.value.grossIncome.toString().trim() === 'R') {
      this.updateProfileForm.patchValue({
        grossIncome: ''
      });
    }

    if (this.updateProfileForm.invalid) {
      this.updateProfileForm.markAllAsTouched();
      console.log('invalid form');
      this.scrollToTop();
      return;
    }

    const grossIncomeName = 'grossIncome';
    let grossIncome: any = '';
    if (this.toNumber(this.updateProfileForm.controls.grossIncome.value) >= 0) {
      grossIncome = parseFloat(this.toNumber(this.updateProfileForm.controls.grossIncome.value));
    }

    this.updateProfileForm.controls[grossIncomeName].setValue((!isNaN(grossIncome)) ? grossIncome : null );

    console.log(this.updateProfileForm.value);

    this.profileService.updateProfile(this.updateProfileForm.value).subscribe((data: any) => {
      this.IDMLeadautoInsert();
      this.successMsg = 'Profile Updated Successfully.';
      console.log(data, "profile data");
      const isSecure = window.location.origin.startsWith('https');
      this.cookieService.set('uType', data.dbcUserType, null, '/', undefined, isSecure);
      if(data.dbcUserType == 2 || data.dbcUserType == 1){
        console.log("inside type");
        (window as any).fcWidget.destroy();
        $('#liFinancialWellness').hide();
      }else{
        $('#liFinancialWellness').show();
      }
      sessionStorage.clear();
      this.scrollToTop();
      //(window as any).location.reload(true);
    }, (err: HttpErrorResponse) => {
      if(err.status === 409){
        this.successMsgWarning = "Email already exists"
      }else
      {
        this.successMsgWarning = err.message;
      }
      console.log(err, "this is console error");
      
    });
  }

  private scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  toNumber(val: any) {
    val = val || '';
    const valArr = val.toString().split('');
    const valFiltered = valArr.filter((x: number) => !isNaN(x));
    const valProcessed = valFiltered.join('').trim();
    return valProcessed;
  }

  toDateString(dt) {
    const date = new Date(dt);
    const month = date.getMonth() + 1;
    return date.getFullYear() + '-' + (month.toString().length === 1 ? '0'
    + month.toString() : month) + '-' + (date.getDate().toString().length === 1 ? '0' + date.getDate().toString() : date.getDate());
  }

  changeGrossIncome() {
    if (this.updateProfileForm.value.grossIncome.toString().trim() === 'R') {
      this.updateProfileForm.patchValue({
        grossIncome: ''
      });
    }
  }

    //#region Load
    updateLoad() {
      sessionStorage.clear();
      var date = new Date();
      const param = { Year: date.getFullYear(), Month: date.getMonth() + 1 };
      this.dashboardService.load(param).subscribe((data: any) => { 
        sessionStorage.setItem('load',JSON.stringify(data));
      },(err: HttpErrorResponse) => { });
    }
    IDMLeadautoInsert() {
      this.communicationService.IDMLeadautoInsert().subscribe((data: any) => { });
    }
  
}
