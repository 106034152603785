import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class WonderPushService {

  constructor(private cookieService: CookieService) { }

  public setCustomUserId(): void {
    let userId = this.cookieService.get('userId');
    
    if (userId) {
      userId = this.rearrangeUserId(userId);
      window['WonderPush'].push(['setUserId', userId]);
      localStorage.setItem('wonderpushUserId', userId);
      console.log('WonderPush User ID set to:', userId);
    } else {
      console.log('No user ID found in cookies.');
    }
  }

  private rearrangeUserId(userId: string): string {
    const parts = userId.split('-');
        const rearranged = [parts[4], parts[2], parts[0], parts[3], parts[1]].join('-');
    return rearranged;
  }
}
