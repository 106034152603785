import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  env = environment;
  constructor(private http: HttpClient) { }

  creditHistory(params: any) {
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/CreditHistory', params);
  }

  creditHistoryMonths() {
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/CreditHistoryMonths', null);
  }

  creditHealthInfo(params: any) {
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/CreditHealthInfo', params);
  }

  radarValues(){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/GetRadarValues', null);
  }

  getCreditScoreChart(){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/GetCreditScore', null);
  }
 
  load(params: any) {
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/Load', params);
  }

  InsertUtmTracking(params: any){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/InsertUtmTracking', params);
  }

  getUserJourneyStatus(data: any){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/GetUserJourneyDetails', data);
  }

  getBannerData(data: any){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/GetBannerData', data);
  }

  getCampaignAndLinkedBannerData(data:any){
    return this.http.post(this.env.webApiUrl + 'api/LeaderBoardBanner/GetCampaignAndBannerData',data );
  }
}
