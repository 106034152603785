import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  env = environment;
  constructor(private http: HttpClient) { }

  sendOtp(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/Otp/SendOtp', data);
  }

  validateOtp(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/Otp/ValidateOtp', data);
  }

  forgotPasswordSendOtp(data: any) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.env.webApiUrl + 'api/ForgotPassword/ForgotPasswordSendOtpFromOtps', data, { headers: reqHeader });
  }

  forgotPasswordUpdate(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/ForgotPassword/ForgotPasswordValidateOTP', data);
  }

  sendOtps(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/Otp/SendOtps', data);
  }

  validateOtps(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/Otp/ValidateOtps', data);
  }

}
