<form [formGroup]="motorVehicleCalculatorForm" id="motorVehicleCalculatorForm"  (submit)="submitMotorVehicleCalculatorData()"  autocomplete="off">
  
  <div class="d-flex flex-wrap align-items-stretch motorvehicleCalculator">
    <div class="col-lg-7 col-md-6 col-12 ps-md-0 ps-0 pe-0 d-flex flex-wrap align-items-stretch">     
      <div class="w-100">
        <div class="form-group mb-4">

          <label for="GrossMonthlyIncome" class="m-0 checktext">Monthly Affordability
            <a class="collapsed arrow" data-bs-toggle="collapse" href="#mothlyaffordabilityinfo"
              id="lnkmothlyaffordabilityinfo" role="button" aria-expanded="false" aria-controls="mothlyaffordabilityinfo">
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <path
                    d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
                    id="info" />
                </defs>
                <use fill-rule="nonzero" xlink:href="#info" />
              </svg>
            </a>
          </label>
          <div>
  
          </div>
          <div class="collapse infobox" id="mothlyaffordabilityinfo" data-bs-parent="#accordion-info">
            <div class="card card-body p-3 pt-4 text-sm border-0 text-white ">
              <a class="closeinfo" data-bs-toggle="collapse" href="#mothlyaffordabilityinfo" id="lnkmothlyaffordability"
                role="button" aria-expanded="false" aria-controls="mothlyaffordabilityinfo">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <path id="cross"
                      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </defs>
                  <use fill="#ffffff" fill-rule="nonzero" xlink:href="#cross" />
                </svg>
              </a>
              This refers to your ability to pay your monthly car instalments, based on your income and budget. It is
              generally recommended not to spend more than 20% to 25% of your net income on vehicle finance.
            </div>
          </div>
          <input type="text" class="form-control" placeholder="R 10,000" appCurrencyMask formControlName="MonthlyAmount" (blur)="ShowMonthlyAmountErrorMessage()" (input)="resetValidation()">
          <div *ngIf="motorVehicleCalculatorForm.get('MonthlyAmount')?.invalid 
            && motorVehicleCalculatorForm.get('MonthlyAmount')?.touched" class="invalid-feedback">
            <div *ngIf="motorVehicleCalculatorForm.get('MonthlyAmount')?.errors?.required  && ValidMonthlyAmountErrorMsg === ''">Monthly Affordability is
              required </div>
              
            <div *ngIf="motorVehicleCalculatorForm.get('MonthlyAmount').errors?.min && ValidMonthlyAmountErrorMsg === ''">Monthly Affordability must be greater than 0 </div>
          </div>
          <div *ngIf="ValidMonthlyAmountErrorMsg !== ''" class="invalid-feedback">
            {{ ValidMonthlyAmountErrorMsg }}
          </div>
        </div>
        <div class="form-group mb-4">
          <label for="GrossMonthlyIncome" class="m-0 checktext">Interest rate
            <a class="collapsed arrow" data-bs-toggle="collapse" href="#interestRateinfo" id="lnkinterestRateinfo"
              role="button" aria-expanded="false" aria-controls="interestRateinfo">
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <path
                    d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
                    id="info" />
                </defs>
                <use fill-rule="nonzero" xlink:href="#info" />
              </svg>
            </a>
          </label>
          <div>
          </div>
          <div class="collapse infobox" id="interestRateinfo" data-bs-parent="#accordion-info">
            <div class="card card-body p-3 pt-4 text-sm border-0 text-white ">
              <a class="closeinfo" data-bs-toggle="collapse" href="#interestRateinfo" role="button" id="lnkinterestRate"
                aria-expanded="false" aria-controls="interestRateinfo">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <path id="cross"
                      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </defs>
                  <use fill="#ffffff" fill-rule="nonzero" xlink:href="#cross" />
                </svg>
              </a>
              The interest rate is a fee you pay for borrowing money and is expressed as a percentage (%). Each payment
              you
              make to pay back the loan goes towards interest first, then capital (the amount you are borrowing). It is
              important to know how much interest you are going to pay for any loan. Interest rates vary, and your credit
              score can affect the rate you’re offered.  
            </div>
          </div>
          <div class="d-flex flex-wrap bordorange rounded align-items-center">
            <input type="text" class="form-control border-0 width50 mb-0" placeholder="" formControlName="InterestRate" maxlength="5"
            oninput="this.value = this.value.slice(0, 5); if (this.value > 100) this.value = 100;">
            <span class="primeText col-6 ps-3">prime {{extraPercentageValue}} </span>
          </div>
          <div *ngIf="motorVehicleCalculatorForm.get('InterestRate')?.invalid 
          && motorVehicleCalculatorForm.get('InterestRate')?.touched" class="invalid-feedback">
            <div *ngIf="motorVehicleCalculatorForm.get('InterestRate')?.errors?.required">Interest Rate is required </div>
            <div *ngIf="motorVehicleCalculatorForm.get('InterestRate').errors?.min">Interest Rate must be greater than or
              equal to prime </div>
              <div *ngIf="motorVehicleCalculatorForm.get('InterestRate').errors?.max">Interest Rate must be less than 100 </div>
            <div *ngIf="motorVehicleCalculatorForm.get('InterestRate').errors?.pattern">Interest Rate must be a number
            </div>
          </div>
        </div>
        <div class="form-group mb-4">
          <label for="GrossMonthlyIncome" class="m-0 checktext">Term
            <a class="collapsed arrow" data-bs-toggle="collapse" href="#terminfo" role="button" id="lnkterminfo"
              aria-expanded="false" aria-controls="terminfo">
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <path
                    d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
                    id="info" />
                </defs>
                <use fill-rule="nonzero" xlink:href="#info" />
              </svg>
            </a>
          </label>
          <div>
          </div>
          <div class="collapse infobox" id="terminfo" data-bs-parent="#accordion-info">
            <div class="card card-body p-3 pt-4 text-sm border-0 text-white ">
              <a class="closeinfo" data-bs-toggle="collapse" href="#terminfo" role="button" id="lnkterm"
                aria-expanded="false" aria-controls="terminfo">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <path id="cross"
                      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </defs>
                  <use fill="#ffffff" fill-rule="nonzero" xlink:href="#cross" />
                </svg>
              </a>
              The term – or finance period – is the length of time you have to pay back your loan. This will affect your
              monthly instalment and interest rate. A longer period may mean lower monthly instalments, but you will pay
              more interest over the life of the loan. Opting for a shorter period will result in larger instalments, but
              you’ll pay less interest. 
            </div>
          </div>
  
          <select id="motorVehicleinputTerm" class="form-control form-select" formControlName="TermInMonths">
            <option *ngFor="let term of termsOfLoan" [value]="term.value">{{ term.display }}</option>
          </select>
         
        </div>
        
        <div class="form-group mb-4">
          <label for="GrossMonthlyIncome" class="m-0 d-flex justify-content-between align-items-center">
            <span class="checktext">
              I own a car I can trade in
              <a class="collapsed arrow" data-bs-toggle="collapse" href="#optionCarTradeinfo" id="lnkoptionCarTradeinfo"
                role="button" aria-expanded="false" aria-controls="optionCarTradeinfo">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <path
                      d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
                      id="info" />
                  </defs>
                  <use fill-rule="nonzero" xlink:href="#info" />
                </svg>
              </a>
            </span>
  
            <label class="switch">
              <input type="checkbox" (change)="showHideCarTrade($event);">
              <span class="slider round"></span>
            </label>
          </label>
  
          <div class="collapse infobox" id="optionCarTradeinfo" data-bs-parent="#accordion-info">
            <div class="card card-body p-3 pt-4 text-sm border-0 text-white ">
              <a class="closeinfo" data-bs-toggle="collapse" href="#optionCarTradeinfo" id="lnkoptionCarTrade"
                role="button" aria-expanded="false" aria-controls="optionCarTradeinfo">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <path id="cross"
                      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </defs>
                  <use fill="#ffffff" fill-rule="nonzero" xlink:href="#cross" />
                </svg>
              </a>
              If you already own a car, you can sell it to a car dealership and use that money to finance a new one, or
              make
              a deposit. Trade-in value is determined by factors such as the make, model, condition, mileage, and market
              popularity of your current car.  
            </div>
          </div>
          <div *ngIf="isDisplayedCarTrade">
            <input type="text" class="form-control mt-2" placeholder="Insert current vehicle value" appCurrencyMask
              formControlName="TradeInValue">
          </div>
        </div>
        <div class="form-group mb-4">
          <label for="GrossMonthlyIncome" class="m-0 d-flex justify-content-between align-items-center">
            <span class="checktext">
              I have savings for a deposit
              <a class="collapsed arrow" data-bs-toggle="collapse" href="#optionSavingsDepositinfo"
                id="lnkoptionSavingsDepositinfo" role="button" aria-expanded="false"
                aria-controls="optionSavingsDepositinfo">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <path
                      d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
                      id="info" />
                  </defs>
                  <use fill-rule="nonzero" xlink:href="#info" />
                </svg>
              </a>
            </span>
  
            <label class="switch">
              <input type="checkbox" (change)="showHideSavingsDeposit($event);">
              <span class="slider round"></span>
            </label>
          </label>
  
          <div class="collapse infobox" id="optionSavingsDepositinfo" data-bs-parent="#accordion-info">
            <div class="card card-body p-3 pt-4 text-sm border-0 text-white ">
              <a class="closeinfo" data-bs-toggle="collapse" href="#optionSavingsDepositinfo" id="lnkoptionSavingsDeposit"
                role="button" aria-expanded="false" aria-controls="optionSavingsDepositinfo">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <path id="cross"
                      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </defs>
                  <use fill="#ffffff" fill-rule="nonzero" xlink:href="#cross" />
                </svg>
              </a>
              Putting down a deposit increases your chances of obtaining vehicle finance because the lender knows you have
              funds available, and that you are serious about buying. A deposit paid upfront will secure the purchase and
              reduce the amount you ultimately have to borrow.  Therefore, paying a deposit will reduce your monthly
              installments and interest rate, and shorten the repayment period. Paying a deposit will reduce the total
              amount
              you pay over the life of the loan.
            </div>
          </div>
          <div *ngIf="isDisplayedSavingsDeposit">
            <input type="text" class="form-control mt-2" placeholder="Insert deposit amount" appCurrencyMask
              formControlName="Deposit">
          </div>
        </div>
      </div>

      <div class="form-group d-flex align-items-center mt-auto mb-3 w-100">
        <button name="btn_motorVehicleFinanceCalculator_Products" id="btn_motorVehicleFinanceCalculator_Products" type="submit"
         class="bank_button w-100 m-0 py-3 buttonfont">Calculate</button>
      </div>
    </div>
    <div class="col-lg-5 col-md-6 col-12 ps-md-3 d-flex flex-wrap align-items-stretch">
      <div class="d-flex flex-wrap d-flex mt-md-0 bg-orange bg-white-mobile rounded w-100">
        <div class="d-none d-md-block d-flex flex-wrap align-items-center justify-content-center w-100">

          <div class="col-12  mt-auto">
            <div class="w-100 d-md-block d-none mb-3 pt-3">
              <img src="../../assets/images/jm-white-logo.png" alt="" class="jmwlogo" />
            </div>
            <div class=" w-100  mb-3">
              <label for="term" class="m-0 text-center d-block"><b>Estimated vehicle price:</b></label>
              <span class="d-block text-center estimateammount">R {{ estimatedVehicleAmount | number:'1.0-0'}} </span>
            </div>
            <div class="w-100 ">
              <label for="term" class="m-0 text-center d-block"><b>If you pay</b></label>
              <span class="d-block text-center estimateammount">R {{ monthlyPaymentAmount | number:'1.0-0'}} <br /> <span class="prmonth"> per month
                </span></span>
            </div>
            <div class="w-100 mt-3">
              <label for="term" class="m-0 text-center d-block"><b>You can afford a vehicle of up to</b></label>
              <span class="d-block text-center estimateammount">R  {{ estimatedVehicleAmount | number:'1.0-0'}} </span>
            </div>
            <div class="w-100 mt-3">
              <label for="term" class="m-0 text-center d-block"><b>Of which </b></label>
              <div class="d-flex w-100 justify-content-center px-1 align-items-center mt-3"><span class="estimateammountsmall text-end pe-2">R {{ depositAmount | number:'1.0-0'}} </span> <span class="fw-bold valuText">is the deposit</span></div>
              <div class="d-flex w-100 justify-content-center px-1 align-items-center"><span class="estimateammountsmall text-end pe-2">R {{ tradeInValueAmount | number:'1.0-0'}} </span> <span class="fw-bold valuText">is from trade in</span></div>
              <div class="d-flex w-100 justify-content-center px-1 align-items-center mb-3"><span class="estimateammountsmall text-end pe-2">R {{ estimatedLoanAmount | number:'1.0-0'}} </span> <span class="fw-bold valuText">will be financed</span></div>
              
            </div>
          </div>

        </div>
        <!--  -->
        <div class="d-block d-md-none d-flex flex-wrap align-items-center justify-content-center w-100 mt-4 mb-5" *ngIf ="vehicleShowDiv">

          <div class="col-12  mt-auto">
            <div class="w-100 d-md-block d-none mb-4 pt-3">
              <img src="../../assets/images/jm-white-logo.png" alt="" class="jmwlogo" />
            </div>
            <div class=" w-100  mb-4">
              <label for="term" class="m-0 text-center d-block"><b>Estimated vehicle price:</b></label>
              <span class="d-block text-center estimateammount">R {{ estimatedVehicleAmount | number:'1.0-0'}} </span>
            </div>
            <div class="w-100 ">
              <label for="term" class="m-0 text-center d-block"><b>If you pay</b></label>
              <span class="d-block text-center estimateammount">R {{ monthlyPaymentAmount | number:'1.0-0'}} <br /> <span class="prmonth"> per month
                </span></span>
            </div>
            <div class="w-100 mt-4">
              <label for="term" class="m-0 text-center d-block"><b>You can afford a vehicle of up to</b></label>
              <span class="d-block text-center estimateammount">R  {{ estimatedVehicleAmount | number:'1.0-0'}} </span>
            </div>
            <div class="w-100 mt-4">
              <label for="term" class="m-0 text-center d-block"><b>Of which </b></label>

              <div class="d-flex w-100 justify-content-center px-1 align-items-center mt-3"><span class="estimateammountsmall estimateammountorange text-end pe-1">R {{ depositAmount | number:'1.0-0'}} </span> <span class="fw-bold">is the deposit</span></div>
              <div class="d-flex w-100 justify-content-center px-1 align-items-center"><span class="estimateammountsmall estimateammountorange text-end pe-1">R {{ tradeInValueAmount | number:'1.0-0'}} </span> <span class="fw-bold">is from trade in</span></div>
              <div class="d-flex w-100 justify-content-center px-1 align-items-center "><span class="estimateammountsmall   text-end pe-1">R {{ estimatedLoanAmount | number:'1.0-0'}} </span> <span class=" fw-bold">will be financed</span></div>
              
            </div>
          </div>

        </div>

        <div class="w-100 px-md-3 mt-md-auto pb-3 pt-md-3">
          <button name="btn_motorVehicleFinanceApply_Products" id="btn_motorVehicleFinanceApply_Products" type="button"
            class="bank_button w-100 m-0 py-3 buttonfont btn-black"(click)="userCallback()">Apply Now</button>
        </div>
      </div>

    </div>
  </div>
</form>
<ng-template #callbackSuccessModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Savvy move!</h4>
    <button type="button" id="callbackSuccessModelClosed" class="close" data-dismiss="modal" name="callbackSuccessModelClosed" isCloseButton="true"
    aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" class="alert alert-success" role="alert">
    <p>A JustMoney coach will give you a call to assist you with your application.</p>
  </div>
</ng-template>
<ng-template #callbackErrorModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Free callback request</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" name="callbackErrorModelClosed" isCloseButton="true"
    (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" class="alert alert-success" role="alert">
    <p>{{callBackErrorDebt}}</p>
  </div>
</ng-template>