import { Component , HostBinding } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent {

  @HostBinding('style.width') public readonly WIDTH = '100%';

  title = 'webapp';
  scrollToElement($element: any): void {
    console.log($element);
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}


