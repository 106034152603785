import { Injectable } from '@angular/core';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormBuilder } from '@angular/forms'; 
import { InputValidationService } from 'src/app/helpers/validators/input-validation.service';
import { RegExValidation } from 'src/app/helpers/constants/regex-validation.constants'; 
import { UserService } from 'src/app/services/user.service'; 
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from 'angular-oauth2-oidc';
import * as $ from 'jquery';
import { RegistrationRoutingService } from '../services/registration-routing.service';

@Component({
  template: ''
})

export class RegisterStepOneBaseComponent implements OnInit {
 
  idNumberForm; 
  errorMsg = '';   
  WebSource = '';
  messageLinkTemplet = `<a id='messagelink' href='routeValue' onclick='return false;'>linkText</a>`;

  constructor(private userService: UserService, 
    public router: Router,
    private formBuilder: FormBuilder,private cookieService: CookieService,
    private oauthService: OAuthService,
    private activatedRoute: ActivatedRoute,
    private registrationRouting: RegistrationRoutingService ) { }

  ngOnInit() {
    this.idNumberForm = this.formBuilder.group({
      IdNumber: ['', [Validators.required, Validators.minLength(13), InputValidationService.isValidIdNumber]]
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params && (params.websource || params.WebSource || params.WEBSOURCE|| params.webSource)) {
        this.WebSource = params.websource || params.WebSource || params.WEBSOURCE|| params.webSource; 
        localStorage.setItem('Url', window.location.href);
      }
    });
 
    if(localStorage.getItem('errorMsg')!='' && localStorage.getItem('errorMsg')!=null){
      this.errorMsg = localStorage.getItem('errorMsg');
      localStorage.removeItem('errorMsg');
    } 
    var queryParams = this.registrationRouting.queryStringParams();
    if(queryParams){
      localStorage.setItem('UtmParams', queryParams);
    }
  }
 
  idNumberCheck() {
    this.errorMsg = '';
    if (this.idNumberForm.invalid) {
      this.idNumberForm.markAllAsTouched();
      return;
    }
    const param = {
      IdNumber: this.idNumberForm.controls.IdNumber.value.replace(RegExValidation.numeric, '')
    };
    this.userService.IsExternalLead(param).subscribe((data: any) => { 
      console.log(data); 
      if(data.is_Lead){
        localStorage.setItem('IsAutoRegister', '1');
      }
      else{ 
        localStorage.setItem('IsAutoRegister', '0'); 
      }
      localStorage.setItem('IdNumber', param.IdNumber);

      this.router.navigateByUrl('/register-step-two' + this.registrationRouting.queryStringParams()); 
    },
      (err: HttpErrorResponse) => { 
        if (err.status === 409) {
          this.errorMsg = err.error.join('<br>');
        } else if (err.status === 410) { 
          this.errorMsg = err.error;
        } else {
          this.errorMsg = err.error;
        }
        this.createMessageLink();
      });
  }

  get idNumberFormControls() { return this.idNumberForm.controls; } 

  azureAdlogin(message) {
    localStorage.removeItem('tempToken');
    let submissionId = '';
    if (sessionStorage.getItem('submissionId')) {
      submissionId = sessionStorage.getItem('submissionId');
    }

    this.oauthService.initLoginFlow('', {
      submissionId, ccAppUrl: window.location.origin,
      instrumentationKey: environment.appInsights.instrumentationKey, message
    });
  }

  onResize(ev) {
    var headerh = $('header').height(),
      footerh = $('footer').height(),
      windowh = $(window).height(),
      desiredHeight = windowh - footerh - headerh;
    $('.min-h').css('min-height', desiredHeight + 'px');
  }

  createMessageLink() {
    if (this.errorMsg.includes('{LOGIN-LINK}')) {
      this.messageLinkTemplet = this.messageLinkTemplet.replace('linkText', 'LOGIN').replace('routeValue', '/login')
      this.errorMsg = this.errorMsg.replace('{LOGIN-LINK}', this.messageLinkTemplet);
    }
    else if (this.errorMsg.includes('{REGISTER-LINK}')) {
      this.messageLinkTemplet = this.messageLinkTemplet.replace('linkText', 'REGISTER').replace('routeValue', '/register' + this.registrationRouting.queryStringParams());
      this.errorMsg = this.errorMsg.replace('{REGISTER-LINK}', this.messageLinkTemplet);
    }
  }

}
