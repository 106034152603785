<section class="">
    <div class="gry-bg d-flex justify-content-center align-items-center bghight bgfoo py-md-5 min-spctbtn pt-5">
        <div class="col-lg-6 col-xl-4 col-md-8 col-11 bg-white c-shdow pt-md-4 spcebtn pt-3">
            <div class="text-center mb-3">
                <i>
                    <svg width="51" height="56" viewBox="0 0 51 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.3333 2H48.2221V25.1111H39.5555L27.9999 30.8889V25.1111H19.3333V2Z" stroke="#E49B2C" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M30.8889 39.5556V51.1112C30.8889 51.8773 30.5845 52.6121 30.0428 53.1539C29.501 53.6957 28.7662 54.0001 28 54.0001H4.88889C4.12271 54.0001 3.38791 53.6957 2.84614 53.1539C2.30436 52.6121 2 51.8773 2 51.1112V10.6667C2 9.90054 2.30436 9.16574 2.84614 8.62397C3.38791 8.0822 4.12271 7.77783 4.88889 7.77783H10.6667" stroke="#E49B2C" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        <rect x="4" y="48" width="25" height="5" fill="#E49B2C"/>
                        <path d="M16 52V52.0294" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </i>
            </div>
            <div class="otp-send-screen" *ngIf="showCellPhoneScreenCapture">
                <div class="text-holder">
                    <h3 class="page-caption text-center mb-md-3">What’s Your New Number?</h3>
                    <p class="text-small text-center mb-md-3">Enter your new cellphone number below.</p>
                    <p class="text-small text-center mb-md-3">
                        Please make sure your number is entered correctly as we will send you an OTP for verification to this number.  
                    </p>
                </div>
                <div class="px-md-5">
                    <form [formGroup]="phoneNumberForm" (ngSubmit)="updatePhoneNumber(false)">
                      
                            <div class="form-group spacingbothside mb-2" id="cellPhoneNumberForm" 
                            *ngIf="showCellPhoneScreenCapture">

                                <label class="text-msmall" for="cellnumber" >New Cellphone Number</label>
                                <input type="text" class="form-control mb-0" isRequired="true" formControlName="phoneNumber" numeric-field maxlength="10" (input)="onchange()"/>
                                <div class="invalid-feedback mt-0">
                                    <div *ngIf="phoneNumberForm.get('phoneNumber')?.errors?.required 
                                          && phoneNumberForm.get('phoneNumber')?.touched" >Cell Phone Number is required</div>
                                    <div *ngIf="phoneNumberForm.get('phoneNumber')?.errors?.minlength">Cell Phone Number 10 digits long</div>
                                    <div *ngIf="oldPhoneNumber == this.phoneNumberForm.controls['phoneNumber'].value">The provided cellphone number is the same as the current cellphone number in use.</div>
                                    <div *ngIf="phoneNumberForm.get('phoneNumber')?.errors?.isValidMobile">Please enter your valid phone number</div>
                                </div>
                                <div class=" mb-3">
                                    <span class="text-red" *ngIf="errorMsg != null">{{errorMsg}}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group justify-content-between align-items-center d-flex spacingbothside  mb-0">
                                <a name="btn_CancelSendOtp_Updatephonenumber" routerLink="/portal/profile" class="bnlink">Cancel</a>
                                <button class="sendOTP" name="btn_sendotp_updatephonenumber">Send OTP</button>
                            </div>
                    

                    </form> 

                </div>
            </div>
            <!--  -->
            <div class="otp-submit-screen" *ngIf="showOtpCaptureScreen">
                <div class="text-holder">
                    <h3 class="page-caption text-center mb-md-3 ">Enter OTP</h3>
                    <p class="text-small text-center mb-md-3">A 6-digit code has been sent to XXX XXX {{lastfourdigit}}. It might take a few moments to arrive. </p>
                    <div *ngIf="successMsg!=''" class="alert alert-success" role="alert">
                        {{successMsg}}
                    </div>
                    <div  *ngIf="successMsgWithPipe == true" class="alert alert-success" role="alert">
                        Your session will expire in {{counter | formatTime}}.
                    </div>
                </div>
                  
                <form class="otp-form" [formGroup]="otpForm" (ngSubmit)="validateOtp()">
                    <div class="">
                        <div class="passcode-wrapper">
                            <input formControlName="Otp" id="otp1" type="number" maxlength="1" (keyup)="onKeyUpEvent($event, 1)" (focus)="onFocusEvent(1)" isRequired="true">
                            <input formControlName="Otp" id="otp2" type="number" maxlength="1" (keyup)="onKeyUpEvent($event, 2)" (focus)="onFocusEvent(2)" isRequired="true">
                            <input formControlName="Otp" id="otp3" type="number" maxlength="1" (keyup)="onKeyUpEvent($event, 3)" (focus)="onFocusEvent(3)" isRequired="true">
                            <input formControlName="Otp" id="otp4" type="number" maxlength="1" (keyup)="onKeyUpEvent($event, 4)" (focus)="onFocusEvent(4)" isRequired="true">

                            <input formControlName="Otp" id="otp5" type="number" maxlength="1" (keyup)="onKeyUpEvent($event, 5)" (focus)="onFocusEvent(5)" isRequired="true">
                            <input formControlName="Otp" id="otp6" type="number" maxlength="1" (keyup)="onKeyUpEvent($event, 6)" (focus)="onFocusEvent(6)" isRequired="true">
                        </div>
                    </div>
                    <div class="text-center mb-3 mt-3">
                        <span class="text-red" *ngIf="otpErrorMsg != null">{{otpErrorMsg}} </span>
                        <span class="resend-otp" *ngIf="resendMsg != null">{{resendMsg}} </span>
                    </div>

                    

                    <p *ngIf="!hideResendOTPButton" class="text-msmall text-center mb-md-0">Didn’t receive the verification OTP? </p>
                    <div class="form-group text-center d-flex spacingbothside  mb-0">

                        <a *ngIf="!hideResendOTPButton" href="javascript:void(0)" name="btn_ResendOtp_Updatephonenumber" 
                        (click)="disableOTPSendButton ? $event.preventDefault() : updatePhoneNumber(true)" 
                        class="bnlink mx-auto text-underline text-bold" [ngClass]="{ 'disabled-anchor': disableOTPSendButton }">
                        Resend OTP <span *ngIf="secondsLeft > 0">in {{secondsLeft}}s</span>
                     </a>
                     
                    </div>

                    </form>

            </div>
        </div>

    </div>
</section>
