<section class="notofication">
    <div class="notification-banner b5containeradjust px-md-0 px-3" style="background-image: url(assets/images/profile_header.png);">
        <div class="container py-md-0">
            <div class="d-flex flex-wrap align-items-center">
                <div class="col-md-9 col-9 no-space-right">
                    <h1 class="page-caption text-white m-0">Settings</h1>
                </div>
                <div class="col-md-3 text-end col-3">
                    <i class="notification-banner-icon">
                        <svg viewBox="0 0 32 32" width="30px" height="30px">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: none;
                                        stroke: #e59a2c;
                                        stroke-linejoin: round;
                                        stroke-width: 2px;
                                    }
                                </style>
                            </defs>
                            <g data-name="35-Notification" id="_35-Notification">
                                <path class="cls-1"
                                    d="M27,23a2,2,0,0,1,2,2,2,2,0,0,1-2,2H5a2,2,0,0,1-2-2,2,2,0,0,1,2-2Z" />
                                <path class="cls-1" d="M12,27a4,4,0,0,0,8,0" />
                                <path class="cls-1" d="M5,23c0-7,2-7,2-13a9,9,0,0,1,18,0c0,6,2,6,2,13" />
                                <path class="cls-1" d="M21,10a5,5,0,0,0-5-5" />
                                <line class="cls-1" x1="21" x2="21" y1="12" y2="14" />
                                <path class="cls-1" d="M24,1s5,2,5,9" />
                                <path class="cls-1" d="M8,1S3,3,3,10" />
                            </g>
                        </svg>
                    </i>
                </div>
            </div>

        </div>
    </div>
    <section class="seetings-hld pb-md-5 b5containeradjust pb-0 ">
        <div class="container">
            <div class="d-flex flex-wrap">
                <div class="col-md-3 col-12 pe-md-3 ps-md-0 px-3">
                    <div class="whitebg mt-md-5 mt-3">
                        <ul class="left-nav">
                            <li class="active"><a>Communication Settings</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-9 col-12 ps-md-3 pe-md-0 px-3">
                    <form [formGroup]="updateSettingForm">
                        <div class="whitebg  mt-md-4 mt-3 pt-md-2">
                            <h2 class="noti-head">Communication Preferences</h2>

                            <div class="form-group m-0 fullwidth">
                                <div *ngIf="successMsg!=''" class="alert alert-success mb-4" role="alert">
                                    {{successMsg}}
                                </div>
                            </div>
                            <div class="form-group m-0 fullwidth">
                                <div *ngIf="successMsgWarning!=''" class="alert alert-danger  mb-4" role="alert">
                                    {{successMsgWarning}}
                                </div>
                            </div>
                            <ul class="notification-list">
                                <li class="flex-wrap">
                                    <hgroup>
                                        <h3>Allow Communication</h3>
                                    </hgroup>
                                    <div class="form-group">
                                        <label class="tc-hld">
                                            <label class="switch">
                                                <input type="checkbox" (change)="allowNotification()"
                                                    formControlName="allowNotifications">
                                                <span class="slider" data-off="Off" data-on="On"></span>
                                            </label>
                                        </label>
                                    </div>
                                    <div class="col-12 d-flex mb-3 mt-4 flex-wrap px-0 align-items-center">

                                        <div class="col-8 col-md-9 pl-0">
                                            <p class="m-0 ntxt">
                                                We will notify you when your new Report is ready
                                            </p>
                                        </div>
                                        <div class="col-4 col-md-3 pe-2 ps-4">
                                            <div class="form-check">
                                                <input type="checkbox" (change)="changeSetting()"
                                                    formControlName="allowEmail" class="form-check-input"
                                                    id="allowEmail">
                                                <label class="form-check-label" for="allowEmail">Email</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3 d-flex flex-wrap px-0 align-items-center">
                                        <div class="col-8 col-md-9 pl-0">
                                            <p class="m-0 ntxt">
                                                You can request to speak to a coach
                                            </p>
                                        </div>
                                        <div class="col-4 col-md-3 pe-2 ps-4">
                                            <div class="form-check">
                                                <input type="checkbox" (change)="changeSetting()"
                                                    formControlName="allowPhone" class="form-check-input"
                                                    id="allowPhone">
                                                <label class="form-check-label" for="allowPhone">Phone</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 mb-3 d-flex flex-wrap px-0 align-items-center">
                                        <div class="col-8 col-md-9 pl-0">
                                            <p class="m-0 ntxt">
                                                Used for OTP and Cellphone verification
                                            </p>
                                        </div>
                                        <div class="col-4 col-md-3 pe-2 ps-4">
                                            <div class="form-check">
                                                <input type="checkbox" (change)="changeSetting()"
                                                    formControlName="allowSMS" class="form-check-input"
                                                    id="allowSms">
                                                <label class="form-check-label" for="allowSms">SMS</label>
                                            </div>
                                        </div>

                                    </div>
                                </li>


                                <ng-template *ngIf="false"> 
                                    <li>
                                        <hgroup>
                                            <h3>Changes on Your Report</h3>
                                        </hgroup>
                                        <div class="form-group">
                                            <label class="tc-hld">
                                                <label class="switch">
                                                    <input type="checkbox" (change)="changeSetting()"
                                                        formControlName="notificationForReport">
                                                    <span class="slider" data-off="Off" data-on="On"></span>
                                                </label>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <hgroup>
                                            <h3>Solutions for You</h3>
                                        </hgroup>
                                        <div class="form-group">
                                            <label class="tc-hld">
                                                <label class="switch">
                                                    <input type="checkbox" (change)="changeSetting()"
                                                        formControlName="notificationForSolutions">
                                                    <span class="slider" data-off="Off" data-on="On"></span>
                                                </label>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <hgroup>
                                            <h3>News and Features</h3>
                                        </hgroup>
                                        <div class="form-group">
                                            <label class="tc-hld">
                                                <label class="switch">
                                                    <input type="checkbox" (change)="changeSetting()"
                                                        formControlName="notificationForNewsAndFeatures">
                                                    <span class="slider" data-off="Off" data-on="On"></span>
                                                </label>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <hgroup>
                                            <h3>Coaching and Tips</h3>
                                        </hgroup>
                                        <div class="form-group">
                                            <label class="tc-hld">

                                                <label class="switch">
                                                    <input type="checkbox" (change)="changeSetting()"
                                                        formControlName="notificationForCoachingAndTips">
                                                    <span class="slider" data-off="Off" data-on="On"></span>
                                                </label>
                                            </label>
                                        </div>
                                    </li>
                                </ng-template>
                            </ul>

<!--
                            <h2 class="noti-head">Newsletter Subscription</h2>
                            <ul class="notification-list  position-relative">
                                <li>
                                    <hgroup>
                                        <h3>Newsletter Subscription</h3>
                                    </hgroup>
                                    <div class="form-group">
                                        <label class="tc-hld">
                                            <label class="switch sp">
                                                <input type="checkbox" (change)="changeSetting()"
                                                    formControlName="newsLetterSubscription">
                                                <span class="slider" data-off="Off" data-on="On"></span>
                                                <div class="jointext">
                                                    <p class="m-0 orange-text">Join over 200,000 South Africans who
                                                        receive our free weekly Newsletter and stay informed!</p>
                                                </div>
                                            </label>

                                        </label>
                                    </div>
                                </li>

                            </ul>
-->
                        </div>
                    </form>


                </div>
            </div>
        </div>
    </section>
</section>



<!-- Contact HTML Start 
<app-contact></app-contact>-->
<!-- Contact HTML End -->