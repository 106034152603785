<section class="vccblogo py-2">
  <div class="container">
    <!-- <span class="logotext">Credit Bureau data provided by</span>
    <span class="creditlogo"><img src="../assets/images/vccb-logo.png" alt=""/></span> -->
    <div class="d-flex justify-content-center align-items-center">
      <div class="col- text-end text-sm">Credit Bureau data provided by</div>
      <div class="col- ps-3"><img src="assets/images/vccb-logo.png" width="130px" alt=""></div>
    </div>
  </div>
</section>
