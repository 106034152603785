import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { CMSService } from '../services/cms.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SessionService } from '../services/active-session-management.service';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  host: { 'class': 'd-flex flex-column h-100 register-bg  position-relative bg-white' },
  styleUrls: [ './news.component.css']
})
export class NewsComponent implements OnInit {
  isShowDiv = true;
  staySavvyList: any = [[]];
  staySavvyListForMobile: any = [[]];

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  onResize(_evt: any) {
    this.carouselNormalization();
  }

  constructor(private cmsService: CMSService,private sessionService:SessionService) { }

  ngOnInit() {
    this.sessionService.checkActiveSession();

    var newsData = sessionStorage.getItem('newsData');
    if(newsData!=null) {
      this.BindNews(JSON.parse(newsData)); 
      return;
    }

    var param = {
      skip: 0, take: 12
    }
    
    this.cmsService.getStaySavvy(param).subscribe((data: any) => {  
      this.BindNews(data); 
      sessionStorage.setItem('newsData', JSON.stringify(data));
    }, (err: HttpErrorResponse) => { console.log(err); });

    this.carouselNormalization();
  }

  private BindNews(data: any) {
    if (data.statusCode === 200) {
      this.staySavvyListForMobile = data.articleList;
      this.staySavvyList = this.chunk(data.articleList, 3);
      console.log(this.staySavvyList);
    } else {
      console.log('error 500, something is went wrong in api.');
    }
  }

  carouselNormalization() {
    $('.carousel-item').css('min-height', $('.carousel-item').height());
    $('.vdo-outer').css('min-height', $('.carousel-item').height());
  }
  chunk(arr: any, chunkSize:any) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  


}
