export const RegExValidation = {
  text: /[^a-zA-Z]*/g,
  textName: /[^a-zA-Z- ]*/g,
  textWithSpace: /[^a-zA-Z ]*/g,
  numeric: /[^0-9]*/g,
  cellNumber: /^0[678][\d]{8}$/,
  // tslint:disable-next-line: max-line-length
  password: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?& "%'()*+,-./:;<=>[\]^_`{|}~#])[A-Za-z\d$@$!%*?& "%'()*+,-./:;<=>[\]^_`{|}~#].{7,}$/,
  email: /^\w+([\.!#$%&'*.+=?^_`{|}~-]\w+)*@[a-zA-Z0-9]+([\-_\.][a-zA-Z0-9]+)*(\.[a-zA-Z0-9]{2,8})+$/
};
