import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class CommunicationService {

  env = environment;

  constructor(private http: HttpClient) { }

  userCallback() {
    return this.http.post(this.env.webApiUrl + 'api/communication/UserCallback', null);
  }
  leadCallback() {
    return this.http.post(this.env.webApiUrl + 'api/communication/IdmLeadCreate', null);
  }
  lesQualificationDecision() {
  	return this.http.post(this.env.webApiUrl + 'api/communication/GetUserLESQualificationDecision', null);
  }
  transferLead(){
  	return this.http.post(this.env.webApiUrl + 'api/communication/TransferUserToSplLead', null);
  }
  transferLeadFincheck(){
  	return this.http.post(this.env.webApiUrl + 'api/communication/TransferUserToFincheckLead', null);
  }
  userCallbackSource(data: any){
  	return this.http.post(this.env.webApiUrl + 'api/communication/UserCallback', data);
  }
  transferUserToTCImporterLead(){
  	return this.http.post(this.env.webApiUrl + 'api/communication/TransferUserToTCImporterLead', null);
  }
  GetInvestmentsQueryParameters(){
    return this.http.post(this.env.webApiUrl + 'api/communication/GetJMInvestmentsQueryParameters', null);
  }
  leadCallbackSource(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/communication/IdmLeadCreate', data);
  }
  generateOobaLead(oobaLeadData:any){
    return this.http.post(this.env.webApiUrl + 'api/communication/CreateOobaLeads', oobaLeadData);
  }
  getOobaPreQualificationScore(){
    return this.http.post(this.env.webApiUrl + 'api/Communication/GetOobaAffordabilityForProductTile', null);
  }
  getOobaAffordabilityCalculation(requestPayload:any){
    return this.http.post(this.env.webApiUrl + 'api/Communication/GetOobaAffordabilityForCalculatorUI',requestPayload);
  }
  IDMLeadautoInsert() {
    return this.http.post(this.env.webApiUrl + 'api/communication/IdmLeadCreateAutoInsert', null);
  }
}
