import { Component , HostBinding } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {

  @HostBinding('style.width') public readonly WIDTH = '100%';

  title = 'webapp';
}
