   <!-- Header HTML Start -->
   <app-header></app-header>
   <!-- Header HTML End -->
  
  <!-- Banner HTML Start -->
  <section class="banner-hld">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Get the <span>best offers</span> tailored for you based upon your credit Score!</h1>
          <!-- Login HTML Start -->
          <!-- <login-form> </login-form> -->
          <!-- Login HTML Start -->
        </div>
       


      </div>
    </div>
  </section>
  <!-- Banner HTML End -->
  
  <!-- Home content HTML Start -->
  <section class="home-cont-hld">
    <div class="container">
      <div class="row">
        <div class="col-md-6 d-flex  align-items-center">
          <ul class="bullet-list">
            <li>We offer much more than just credit reports </li>
            <li>We help you make better financial decisions</li>
            <li>We keep you updated with best offers</li>
            <li>We coach you to achieve your financial goals</li>
          </ul>
        </div>
        <div class="col-md-6">
          <img src="assets/images/info-graphics.png" alt="DebtBusters" />
        </div>
      </div>
    </div>
  </section>
  <!-- Home content HTML End -->



  
  
  <!-- Stats HTMl Start -->
  <section class="stats-hld">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <ul class="stats-list">
            <li>
              <i class="icon icon-customer"></i>
              <h2>5+</h2>
              <p>Million Customer</p>
            </li>
            <li>
              <i class="icon icon-lenders"></i>
              <h2>30+</h2>
              <p>Lenders</p>
            </li>
            <li>
              <i class="icon icon-offer"></i>
              <h2>500+</h2>
              <p>Offers</p>
            </li>
            <li>
              <i class="icon icon-secured"></i>
              <p>Your</p>
              <h2>Data</h2>
              <p>Is Secured</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- Stats HTML End -->
  
  
  
    <!-- Call me Back HTML Start -->
    <app-callme-back> </app-callme-back>
    <!-- Call me Back HTML End -->
  <!-- Contact HTML Start
  <app-contact></app-contact> -->
  <!-- Contact HTML End -->
  
  <!-- Footer HTML Start -->
  <app-footer></app-footer>
  <!-- Footer HTML End -->