import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';   
import { RegisterStepOneBaseComponent } from '../register-step-one-base.component';

@Component({
  selector: 'app-wingfield-registration',
  templateUrl: './wingfield-registration.component.html',
  styleUrls: ['./wingfield-registration.component.css']
})

export class WingfieldRegistrationComponent extends  RegisterStepOneBaseComponent {
    
}
