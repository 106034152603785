import { Component, OnInit } from '@angular/core';
import { RegisterStepOneBaseComponent } from '../register-step-one-base.component';

@Component({
  selector: 'app-blu-registration',
  templateUrl: './blu-registration.component.html',
  styleUrls: ['./blu-registration.component.css'],
  
})
export class BluRegistrationComponent extends  RegisterStepOneBaseComponent {
    
}

