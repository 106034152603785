<header>
    <!-- Fixed navbar -->
    <nav class="navbar navbar-expand-md nav-cutom b5containeradjust">
        <div class="container">
            <a name="btn_JustMonetLogo_RegisterStepTwo" class="logo-hld navbar-brand p-0" href="https://www.justmoney.co.za/" target="_blank"><svg id="Group_188"
                    data-name="Group 188" xmlns="http://www.w3.org/2000/svg" width="192.08" height="52.516"
                    viewBox="0 0 192.08 52.516">
                    <g id="Group_54" data-name="Group 54" transform="translate(0 13.348)">
                        <path id="Path_140" data-name="Path 140"
                            d="M-565.9,291.362h1.891v16.295a6.926,6.926,0,0,1-1.867,5.09,6.49,6.49,0,0,1-4.83,1.891,7.164,7.164,0,0,1-5-1.67,6.045,6.045,0,0,1-1.765-4.886l.031-.094h1.765a5.126,5.126,0,0,0,1.269,3.822,5.08,5.08,0,0,0,3.7,1.237,4.505,4.505,0,0,0,3.451-1.457,5.549,5.549,0,0,0,1.355-3.932Z"
                            transform="translate(577.467 -291.362)" fill="#e59a2c" />
                        <path id="Path_141" data-name="Path 141"
                            d="M-541.22,313.5a5.842,5.842,0,0,1-2.2,2.3,6.382,6.382,0,0,1-3.271.8,5.2,5.2,0,0,1-4.223-1.828q-1.53-1.828-1.529-5.673v-9.882h1.891v9.913a7.334,7.334,0,0,0,1.063,4.475,3.613,3.613,0,0,0,3.018,1.356,5.513,5.513,0,0,0,3.4-.954,5.05,5.05,0,0,0,1.789-2.607V299.226h1.892v17.052h-1.671Z"
                            transform="translate(571.197 -293.332)" fill="#e59a2c" />
                        <path id="Path_142" data-name="Path 142"
                            d="M-518.427,311.807a2.733,2.733,0,0,0-.985-2.088,8.07,8.07,0,0,0-3.554-1.458,11.642,11.642,0,0,1-4.5-1.725,3.6,3.6,0,0,1-1.473-3.065,4.138,4.138,0,0,1,1.647-3.31,6.643,6.643,0,0,1,4.357-1.356,6.814,6.814,0,0,1,4.555,1.443,4.257,4.257,0,0,1,1.623,3.553l-.031.094h-1.749a3.139,3.139,0,0,0-1.206-2.442,4.715,4.715,0,0,0-3.191-1.04,4.615,4.615,0,0,0-3.089.9,2.7,2.7,0,0,0-1.039,2.1,2.437,2.437,0,0,0,.89,1.931,8.76,8.76,0,0,0,3.554,1.348,11.559,11.559,0,0,1,4.531,1.844,3.807,3.807,0,0,1,1.536,3.183,4.172,4.172,0,0,1-1.725,3.46,7.265,7.265,0,0,1-4.562,1.331,7.377,7.377,0,0,1-4.877-1.481,4.193,4.193,0,0,1-1.679-3.53l.031-.094h1.734a3.122,3.122,0,0,0,1.5,2.679,6.275,6.275,0,0,0,3.286.835,5.161,5.161,0,0,0,3.23-.913A2.7,2.7,0,0,0-518.427,311.807Z"
                            transform="translate(565.425 -293.227)" fill="#e59a2c" />
                        <path id="Path_143" data-name="Path 143"
                            d="M-504.143,293.4v4.366h3.75v1.545h-3.75v11.111a3.632,3.632,0,0,0,.606,2.371,2.028,2.028,0,0,0,1.615.7,5.557,5.557,0,0,0,.67-.039c.215-.026.47-.07.764-.134l.284,1.4a3.939,3.939,0,0,1-.938.307,5.372,5.372,0,0,1-1.111.118,3.534,3.534,0,0,1-2.8-1.134,5.35,5.35,0,0,1-1-3.593V299.311H-509v-1.545h2.947V293.4Z"
                            transform="translate(560.313 -291.873)" fill="#e59a2c" />
                    </g>
                    <g id="Group_55" data-name="Group 55" transform="translate(64.82 13.348)">
                        <path id="Path_144" data-name="Path 144"
                            d="M-479.6,310.006h.094l7.328-18.644h3.862v22.946h-3.105V297.114l-.094-.016-6.95,17.209h-2.159l-7.154-17.776-.1.016v17.761h-3.105V291.362h4.082Z"
                            transform="translate(490.979 -291.362)" fill="#302e2f" />
                        <path id="Path_145" data-name="Path 145"
                            d="M-455.1,307.489a9.388,9.388,0,0,1,2.049-6.233,6.869,6.869,0,0,1,5.563-2.452,6.936,6.936,0,0,1,5.6,2.443,9.354,9.354,0,0,1,2.057,6.241v.346a9.34,9.34,0,0,1-2.048,6.241,6.916,6.916,0,0,1-5.578,2.427,6.928,6.928,0,0,1-5.586-2.435,9.321,9.321,0,0,1-2.057-6.233Zm3.1.346a8.093,8.093,0,0,0,1.143,4.477,3.783,3.783,0,0,0,3.4,1.764,3.774,3.774,0,0,0,3.373-1.764,8.057,8.057,0,0,0,1.15-4.477v-.346a8.023,8.023,0,0,0-1.159-4.46,3.8,3.8,0,0,0-3.4-1.781,3.745,3.745,0,0,0-3.365,1.781,8.108,8.108,0,0,0-1.143,4.46Z"
                            transform="translate(481.991 -293.227)" fill="#302e2f" />
                        <path id="Path_146" data-name="Path 146"
                            d="M-426.888,299.121l.22,2.538a6.19,6.19,0,0,1,2.136-2.1,5.7,5.7,0,0,1,2.923-.749,5.565,5.565,0,0,1,4.27,1.616,7.091,7.091,0,0,1,1.513,4.988v10.764h-3.1v-10.7a4.613,4.613,0,0,0-.89-3.2,3.564,3.564,0,0,0-2.718-.946,4.806,4.806,0,0,0-2.435.576,4.177,4.177,0,0,0-1.584,1.615v12.655h-3.1V299.121Z"
                            transform="translate(475.617 -293.227)" fill="#302e2f" />
                        <path id="Path_147" data-name="Path 147"
                            d="M-398.367,316.5a7.214,7.214,0,0,1-5.728-2.347,9.036,9.036,0,0,1-2.057-6.179v-.693a8.846,8.846,0,0,1,2.119-6.076,6.644,6.644,0,0,1,5.177-2.4,6.188,6.188,0,0,1,5.13,2.128,8.77,8.77,0,0,1,1.71,5.658v1.639h-10.905l-.047.079a7.056,7.056,0,0,0,1.134,4.169,3.966,3.966,0,0,0,3.468,1.6,7.8,7.8,0,0,0,2.766-.449,6.174,6.174,0,0,0,2.041-1.237l1.214,2.018a7.461,7.461,0,0,1-2.412,1.489A9.6,9.6,0,0,1-398.367,316.5Zm-.489-15.255a3.375,3.375,0,0,0-2.687,1.253,5.933,5.933,0,0,0-1.3,3.207l.031.079h7.69v-.252a4.965,4.965,0,0,0-.914-3.074A3.3,3.3,0,0,0-398.856,301.248Z"
                            transform="translate(469.727 -293.227)" fill="#302e2f" />
                        <path id="Path_148" data-name="Path 148"
                            d="M-378.3,309.958l.552,2.143h.094l4.192-12.876h3.451l-7.17,19.667a8.831,8.831,0,0,1-1.852,2.994,4.262,4.262,0,0,1-3.254,1.277,6.8,6.8,0,0,1-.962-.087q-.583-.087-.9-.165l.315-2.442a3.952,3.952,0,0,1,.56.031q.653.047.827.046a1.915,1.915,0,0,0,1.623-.882,9.165,9.165,0,0,0,1.056-1.955l.741-1.781-6.335-16.7h3.467Z"
                            transform="translate(464.518 -293.332)" fill="#302e2f" />
                    </g>
                    <path id="Path_149" data-name="Path 149"
                        d="M-360.642,286.56a2.224,2.224,0,0,0,2.225,2.225,2.225,2.225,0,0,0,2.225-2.225,2.225,2.225,0,0,0-2.225-2.225,2.225,2.225,0,0,0-2.225,2.225"
                        transform="translate(523.145 -276.254)" fill="#e59a2c" />
                    <path id="Path_150" data-name="Path 150"
                        d="M-349.466,286.56a2.225,2.225,0,0,0,2.225,2.225,2.225,2.225,0,0,0,2.225-2.225,2.225,2.225,0,0,0-2.225-2.225,2.225,2.225,0,0,0-2.225,2.225"
                        transform="translate(520.345 -276.254)" fill="#e59a2c" />
                    <path id="Path_151" data-name="Path 151"
                        d="M-360.642,296.2a2.224,2.224,0,0,0,2.225,2.225,2.225,2.225,0,0,0,2.225-2.225,2.225,2.225,0,0,0-2.225-2.225,2.224,2.224,0,0,0-2.225,2.225"
                        transform="translate(523.145 -278.669)" fill="#e59a2c" />
                    <path id="Path_152" data-name="Path 152"
                        d="M-349.466,296.2a2.225,2.225,0,0,0,2.225,2.225,2.225,2.225,0,0,0,2.225-2.225,2.225,2.225,0,0,0-2.225-2.225,2.225,2.225,0,0,0-2.225,2.225"
                        transform="translate(520.345 -278.669)" fill="#e59a2c" />
                    <path id="Path_153" data-name="Path 153"
                        d="M-338.291,296.2a2.225,2.225,0,0,0,2.226,2.225,2.225,2.225,0,0,0,2.225-2.225,2.225,2.225,0,0,0-2.225-2.225,2.225,2.225,0,0,0-2.226,2.225"
                        transform="translate(517.545 -278.669)" fill="#e59a2c" />
                    <path id="Path_154" data-name="Path 154"
                        d="M-360.642,305.845a2.225,2.225,0,0,0,2.225,2.226,2.226,2.226,0,0,0,2.225-2.226,2.225,2.225,0,0,0-2.225-2.224,2.224,2.224,0,0,0-2.225,2.224"
                        transform="translate(523.145 -281.085)" fill="#e59a2c" />
                    <path id="Path_155" data-name="Path 155"
                        d="M-349.466,305.845a2.226,2.226,0,0,0,2.225,2.226,2.226,2.226,0,0,0,2.225-2.226,2.225,2.225,0,0,0-2.225-2.224,2.225,2.225,0,0,0-2.225,2.224"
                        transform="translate(520.345 -281.085)" fill="#e59a2c" />
                    <path id="Path_156" data-name="Path 156"
                        d="M-338.291,305.845a2.226,2.226,0,0,0,2.226,2.226,2.226,2.226,0,0,0,2.225-2.226,2.225,2.225,0,0,0-2.225-2.224,2.225,2.225,0,0,0-2.226,2.224"
                        transform="translate(517.545 -281.085)" fill="#e59a2c" />
                    <path id="Path_157" data-name="Path 157"
                        d="M-327.115,305.845a2.226,2.226,0,0,0,2.226,2.226,2.225,2.225,0,0,0,2.224-2.226,2.225,2.225,0,0,0-2.224-2.224,2.225,2.225,0,0,0-2.226,2.224"
                        transform="translate(514.745 -281.085)" fill="#e59a2c" />
                    <path id="Path_158" data-name="Path 158"
                        d="M-360.642,315.489a2.224,2.224,0,0,0,2.225,2.225,2.225,2.225,0,0,0,2.225-2.225,2.225,2.225,0,0,0-2.225-2.226,2.225,2.225,0,0,0-2.225,2.226"
                        transform="translate(523.145 -283.501)" fill="#e59a2c" />
                    <path id="Path_159" data-name="Path 159"
                        d="M-349.466,315.489a2.225,2.225,0,0,0,2.225,2.225,2.225,2.225,0,0,0,2.225-2.225,2.225,2.225,0,0,0-2.225-2.226,2.225,2.225,0,0,0-2.225,2.226"
                        transform="translate(520.345 -283.501)" fill="#e59a2c" />
                    <path id="Path_160" data-name="Path 160"
                        d="M-338.291,315.489a2.225,2.225,0,0,0,2.226,2.225,2.225,2.225,0,0,0,2.225-2.225,2.226,2.226,0,0,0-2.225-2.226,2.225,2.225,0,0,0-2.226,2.226"
                        transform="translate(517.545 -283.501)" fill="#e59a2c" />
                    <path id="Path_161" data-name="Path 161"
                        d="M-360.642,325.131a2.224,2.224,0,0,0,2.225,2.225,2.225,2.225,0,0,0,2.225-2.225,2.225,2.225,0,0,0-2.225-2.225,2.224,2.224,0,0,0-2.225,2.225"
                        transform="translate(523.145 -285.917)" fill="#e59a2c" />
                    <path id="Path_162" data-name="Path 162"
                        d="M-349.466,325.131a2.225,2.225,0,0,0,2.225,2.225,2.225,2.225,0,0,0,2.225-2.225,2.225,2.225,0,0,0-2.225-2.225,2.225,2.225,0,0,0-2.225,2.225"
                        transform="translate(520.345 -285.917)" fill="#e59a2c" />
                    <path id="Path_163" data-name="Path 163"
                        d="M-360.642,335.915a2.224,2.224,0,0,0,2.225,2.224,2.225,2.225,0,0,0,2.225-2.224,2.224,2.224,0,0,0-2.225-2.225,2.224,2.224,0,0,0-2.225,2.225"
                        transform="translate(523.145 -288.619)" fill="#e59a2c" />
                    <path id="Path_164" data-name="Path 164"
                        d="M-360.642,275.777A2.224,2.224,0,0,0-358.417,278a2.225,2.225,0,0,0,2.225-2.225,2.225,2.225,0,0,0-2.225-2.225,2.224,2.224,0,0,0-2.225,2.225"
                        transform="translate(523.145 -273.552)" fill="#e59a2c" />
                    <g id="Group_56" data-name="Group 56" transform="translate(17.021 40.873)">
                        <path id="Path_165" data-name="Path 165"
                            d="M-549.633,335.2h-3.194l-.758,2.186h-1.172l3.061-8.487h.986l3,8.487h-1.172Zm-2.862-.962h2.535l-1.236-3.643h-.035Z"
                            transform="translate(554.757 -328.292)" fill="#302e2f" />
                        <path id="Path_166" data-name="Path 166"
                            d="M-544.256,334.145a4.206,4.206,0,0,1,.65-2.454,2.1,2.1,0,0,1,1.821-.932,2.264,2.264,0,0,1,.982.2,2.024,2.024,0,0,1,.732.594v-3.469h1.148v9.094h-.881l-.174-.8a2.057,2.057,0,0,1-.764.688,2.287,2.287,0,0,1-1.055.232,2.163,2.163,0,0,1-1.807-.836,3.475,3.475,0,0,1-.653-2.2Zm1.148.123a2.67,2.67,0,0,0,.391,1.53,1.365,1.365,0,0,0,1.213.575,1.517,1.517,0,0,0,.863-.233,1.737,1.737,0,0,0,.571-.654v-2.943a1.769,1.769,0,0,0-.574-.621,1.517,1.517,0,0,0-.848-.231,1.313,1.313,0,0,0-1.222.682,3.522,3.522,0,0,0-.394,1.772Z"
                            transform="translate(552.126 -328.089)" fill="#302e2f" />
                        <path id="Path_167" data-name="Path 167"
                            d="M-532.937,336.255l.1.443h.034l.111-.443,1.452-4.448h1.171l-2.32,6.307h-.869l-2.349-6.307h1.177Z"
                            transform="translate(549.959 -329.021)" fill="#302e2f" />
                        <path id="Path_168" data-name="Path 168"
                            d="M-525.569,329.261h-1.149v-1.172h1.149Zm0,7.922h-1.149v-6.307h1.149Z"
                            transform="translate(547.732 -328.089)" fill="#302e2f" />
                        <path id="Path_169" data-name="Path 169"
                            d="M-520.41,337.3a1.35,1.35,0,0,0,.926-.353,1.115,1.115,0,0,0,.4-.866h1.037l.018.035a1.813,1.813,0,0,1-.7,1.448,2.47,2.47,0,0,1-1.678.633,2.477,2.477,0,0,1-2.053-.9,3.493,3.493,0,0,1-.722-2.251V334.8a3.472,3.472,0,0,1,.725-2.241,2.475,2.475,0,0,1,2.05-.907,2.376,2.376,0,0,1,1.727.653,2.073,2.073,0,0,1,.651,1.6l-.012.035h-1.043a1.392,1.392,0,0,0-.376-.982,1.238,1.238,0,0,0-.947-.4,1.327,1.327,0,0,0-1.234.656,3.066,3.066,0,0,0-.387,1.588v.244a3.127,3.127,0,0,0,.381,1.6A1.327,1.327,0,0,0-520.41,337.3Z"
                            transform="translate(546.847 -328.982)" fill="#302e2f" />
                        <path id="Path_170" data-name="Path 170"
                            d="M-511.969,338.2a2.67,2.67,0,0,1-2.119-.868,3.343,3.343,0,0,1-.761-2.286v-.256a3.272,3.272,0,0,1,.784-2.247,2.457,2.457,0,0,1,1.915-.889,2.288,2.288,0,0,1,1.9.787,3.24,3.24,0,0,1,.633,2.093v.606h-4.034l-.017.029a2.608,2.608,0,0,0,.42,1.542,1.467,1.467,0,0,0,1.282.592,2.883,2.883,0,0,0,1.023-.166,2.276,2.276,0,0,0,.755-.457l.45.746a2.772,2.772,0,0,1-.893.551A3.545,3.545,0,0,1-511.969,338.2Zm-.181-5.642a1.249,1.249,0,0,0-.994.463,2.191,2.191,0,0,0-.481,1.186l.012.028h2.845v-.093a1.833,1.833,0,0,0-.339-1.136A1.219,1.219,0,0,0-512.149,332.556Z"
                            transform="translate(544.758 -328.982)" fill="#302e2f" />
                        <path id="Path_171" data-name="Path 171"
                            d="M-500.492,335.777l.2.793h.035l1.551-4.763h1.277l-2.652,7.275a3.278,3.278,0,0,1-.685,1.108,1.579,1.579,0,0,1-1.2.473,2.572,2.572,0,0,1-.355-.032c-.144-.022-.254-.042-.333-.061l.117-.9a1.591,1.591,0,0,1,.207.011c.162.012.263.018.306.018a.709.709,0,0,0,.6-.327,3.35,3.35,0,0,0,.39-.722l.274-.659-2.344-6.179h1.282Z"
                            transform="translate(541.816 -329.021)" fill="#302e2f" />
                        <path id="Path_172" data-name="Path 172"
                            d="M-494.651,334.864a3.469,3.469,0,0,1,.757-2.305,2.54,2.54,0,0,1,2.058-.907,2.567,2.567,0,0,1,2.072.9,3.46,3.46,0,0,1,.761,2.308v.128a3.456,3.456,0,0,1-.758,2.308,2.56,2.56,0,0,1-2.064.9,2.564,2.564,0,0,1-2.066-.9,3.45,3.45,0,0,1-.76-2.305Zm1.148.128a2.993,2.993,0,0,0,.422,1.656,1.4,1.4,0,0,0,1.256.653,1.4,1.4,0,0,0,1.248-.653,2.981,2.981,0,0,0,.425-1.656v-.128a2.966,2.966,0,0,0-.428-1.65,1.4,1.4,0,0,0-1.256-.659,1.386,1.386,0,0,0-1.245.659,3,3,0,0,0-.422,1.65Z"
                            transform="translate(539.698 -328.982)" fill="#302e2f" />
                        <path id="Path_173" data-name="Path 173"
                            d="M-481.243,337.182a2.143,2.143,0,0,1-.764.781,2.094,2.094,0,0,1-1.078.274,2.024,2.024,0,0,1-1.608-.659,3.054,3.054,0,0,1-.577-2.051v-3.72h1.148v3.732a2.35,2.35,0,0,0,.3,1.387,1.107,1.107,0,0,0,.928.379,1.879,1.879,0,0,0,1.01-.248,1.546,1.546,0,0,0,.6-.7v-4.547h1.148v6.307h-1.032Z"
                            transform="translate(537.348 -329.021)" fill="#302e2f" />
                        <path id="Path_174" data-name="Path 174"
                            d="M-469.81,337.3a1.35,1.35,0,0,0,.927-.353,1.115,1.115,0,0,0,.4-.866h1.037l.017.035a1.81,1.81,0,0,1-.7,1.448,2.467,2.467,0,0,1-1.678.633,2.475,2.475,0,0,1-2.052-.9,3.489,3.489,0,0,1-.722-2.251V334.8a3.468,3.468,0,0,1,.725-2.241,2.473,2.473,0,0,1,2.049-.907,2.378,2.378,0,0,1,1.728.653,2.075,2.075,0,0,1,.65,1.6l-.012.035h-1.042a1.392,1.392,0,0,0-.376-.982,1.239,1.239,0,0,0-.947-.4,1.325,1.325,0,0,0-1.233.656,3.066,3.066,0,0,0-.387,1.588v.244a3.127,3.127,0,0,0,.381,1.6A1.327,1.327,0,0,0-469.81,337.3Z"
                            transform="translate(534.17 -328.982)" fill="#302e2f" />
                        <path id="Path_175" data-name="Path 175"
                            d="M-460.086,338.076q-.058-.286-.09-.5a3.731,3.731,0,0,1-.038-.44,2.668,2.668,0,0,1-.836.761,2.139,2.139,0,0,1-1.11.306,2.058,2.058,0,0,1-1.5-.5,1.852,1.852,0,0,1-.516-1.389,1.63,1.63,0,0,1,.732-1.414,3.418,3.418,0,0,1,1.985-.51h1.247v-.624a1.153,1.153,0,0,0-.338-.876,1.322,1.322,0,0,0-.956-.324,1.384,1.384,0,0,0-.9.28.837.837,0,0,0-.344.67l-1.09.012-.012-.035a1.508,1.508,0,0,1,.644-1.258,2.67,2.67,0,0,1,1.77-.572,2.6,2.6,0,0,1,1.725.545,1.931,1.931,0,0,1,.653,1.571v3.038a5.917,5.917,0,0,0,.034.653,3.407,3.407,0,0,0,.128.618Zm-1.906-.839a2.045,2.045,0,0,0,1.125-.321,1.519,1.519,0,0,0,.653-.734v-1.026H-461.5a1.669,1.669,0,0,0-1.111.349,1.046,1.046,0,0,0-.417.822.867.867,0,0,0,.262.665A1.1,1.1,0,0,0-461.992,337.236Z"
                            transform="translate(532.064 -328.982)" fill="#302e2f" />
                        <path id="Path_176" data-name="Path 176"
                            d="M-454.106,331.768l.082.939a2.3,2.3,0,0,1,.789-.779,2.114,2.114,0,0,1,1.082-.277,2.057,2.057,0,0,1,1.579.6,2.623,2.623,0,0,1,.56,1.845v3.981h-1.148v-3.958a1.707,1.707,0,0,0-.329-1.183,1.321,1.321,0,0,0-1.006-.35,1.785,1.785,0,0,0-.9.213,1.548,1.548,0,0,0-.586.6v4.681h-1.148v-6.307Z"
                            transform="translate(529.797 -328.982)" fill="#302e2f" />
                        <path id="Path_177" data-name="Path 177"
                            d="M-436.734,334.268a3.463,3.463,0,0,1-.656,2.2,2.165,2.165,0,0,1-1.8.836,2.29,2.29,0,0,1-1.093-.247,2.032,2.032,0,0,1-.767-.731l-.186.857h-.881v-9.094h1.148v3.533a2,2,0,0,1,.737-.641,2.256,2.256,0,0,1,1.03-.222,2.1,2.1,0,0,1,1.818.932,4.2,4.2,0,0,1,.653,2.454Zm-1.148-.123a3.492,3.492,0,0,0-.4-1.769,1.317,1.317,0,0,0-1.216-.685,1.505,1.505,0,0,0-.9.26,1.83,1.83,0,0,0-.579.7v2.734a1.879,1.879,0,0,0,.579.728,1.5,1.5,0,0,0,.907.262,1.367,1.367,0,0,0,1.207-.577,2.654,2.654,0,0,0,.4-1.527Z"
                            transform="translate(526.537 -328.089)" fill="#302e2f" />
                        <path id="Path_178" data-name="Path 178"
                            d="M-429.263,338.076q-.057-.286-.09-.5a3.724,3.724,0,0,1-.037-.44,2.679,2.679,0,0,1-.837.761,2.139,2.139,0,0,1-1.11.306,2.058,2.058,0,0,1-1.5-.5,1.852,1.852,0,0,1-.516-1.389,1.63,1.63,0,0,1,.732-1.414,3.42,3.42,0,0,1,1.985-.51h1.248v-.624a1.154,1.154,0,0,0-.339-.876,1.321,1.321,0,0,0-.956-.324,1.385,1.385,0,0,0-.9.28.837.837,0,0,0-.344.67l-1.09.012-.012-.035a1.507,1.507,0,0,1,.645-1.258,2.666,2.666,0,0,1,1.769-.572,2.6,2.6,0,0,1,1.725.545,1.931,1.931,0,0,1,.653,1.571v3.038a5.733,5.733,0,0,0,.035.653,3.341,3.341,0,0,0,.128.618Zm-1.906-.839a2.049,2.049,0,0,0,1.126-.321,1.523,1.523,0,0,0,.653-.734v-1.026h-1.288a1.672,1.672,0,0,0-1.111.349,1.046,1.046,0,0,0-.417.822.867.867,0,0,0,.262.665A1.1,1.1,0,0,0-431.169,337.236Z"
                            transform="translate(524.341 -328.982)" fill="#302e2f" />
                        <path id="Path_179" data-name="Path 179"
                            d="M-423.283,331.768l.082.939a2.3,2.3,0,0,1,.79-.779,2.11,2.11,0,0,1,1.081-.277,2.057,2.057,0,0,1,1.579.6,2.623,2.623,0,0,1,.56,1.845v3.981h-1.148v-3.958a1.707,1.707,0,0,0-.329-1.183,1.32,1.32,0,0,0-1.006-.35,1.785,1.785,0,0,0-.9.213,1.546,1.546,0,0,0-.585.6v4.681h-1.149v-6.307Z"
                            transform="translate(522.075 -328.982)" fill="#302e2f" />
                        <path id="Path_180" data-name="Path 180"
                            d="M-413.51,334.274h-.588v2.909h-1.148v-9.094h1.148v5.27h.577l1.895-2.484h1.376l-2.292,2.909,2.49,3.4h-1.358Z"
                            transform="translate(519.805 -328.089)" fill="#302e2f" />
                        <path id="Path_181" data-name="Path 181"
                            d="M-403.486,334.864a3.473,3.473,0,0,1,.757-2.305,2.54,2.54,0,0,1,2.058-.907,2.567,2.567,0,0,1,2.072.9,3.46,3.46,0,0,1,.761,2.308v.128a3.456,3.456,0,0,1-.758,2.308,2.56,2.56,0,0,1-2.064.9,2.564,2.564,0,0,1-2.066-.9,3.454,3.454,0,0,1-.76-2.305Zm1.148.128a2.993,2.993,0,0,0,.422,1.656,1.4,1.4,0,0,0,1.256.653,1.4,1.4,0,0,0,1.248-.653,2.981,2.981,0,0,0,.426-1.656v-.128a2.966,2.966,0,0,0-.429-1.65,1.4,1.4,0,0,0-1.256-.659,1.386,1.386,0,0,0-1.245.659,3,3,0,0,0-.422,1.65Z"
                            transform="translate(516.858 -328.982)" fill="#302e2f" />
                        <path id="Path_182" data-name="Path 182"
                            d="M-393.05,331.768l.081.939a2.307,2.307,0,0,1,.79-.779,2.112,2.112,0,0,1,1.081-.277,2.057,2.057,0,0,1,1.58.6,2.623,2.623,0,0,1,.56,1.845v3.981h-1.148v-3.958a1.706,1.706,0,0,0-.33-1.183,1.319,1.319,0,0,0-1.005-.35,1.788,1.788,0,0,0-.9.213,1.552,1.552,0,0,0-.585.6v4.681h-1.148v-6.307Z"
                            transform="translate(514.5 -328.982)" fill="#302e2f" />
                    </g>
                </svg></a>
            <a class="btn btn-custom login-button d-md-none d-block" href="javascript:void(0)"
                (click)="azureAdlogin('')">Login</a>

            <div class=" navbar-collapse">
                <!--<span class="navbar-close d-block d-block d-md-none">

                        <img src="assets/images/cross.png" alt="" width="22px"/>

                    </span>-->
                <div class="ms-md-5 d-flex column-reverse">
                    <ul class="navbar-nav justify-content-end custom-nav">
                        <li class="nav-item">
                            <a name="btn_Register_RegisterStepTwo" class="nav-link" href="/register{{RestQueryString}}">Register</a>
                        </li>
                        <li class="nav-item">
                            <a name="btn_Login_RegisterStepTwo" class="nav-link" href="javascript:void(0)" (click)="azureAdlogin('')">Login</a>
                        </li>
                    </ul>
                    <!--<div class="button-sp justify-md-content-end flex-wrap align-items-center d-flex">
                            <span class="pe-3 d-none d-md-block">Need Help?</span>
                            <a class="teleph d-none d-md-block" href="tel:086 999 0606">086 999 0606</a>
                        </div>-->
                </div>
            </div>


        </div>
    </nav>
</header>



<section (window:resize)="onResize($event)" class=" register-hld register">
    <div class="registerholder b5containeradjust">
        <div class="container ">
            <div class="d-flex flex-wrap justify-content-center">
                <h1 class="text-orange topcaption pt-4">Register for your JustMoney profile</h1>
                <div class="col-lg-6 col-md-9 px-md-2 px-0 mobilespce pb-4">

                    <p class="page-text">Your credit profile provides access to a unique view of your financial
                        position. JustMoney believes in giving South Africans a second chance to build a brighter
                        financial future. We do this by helping clients take back control of their finances.</p>

                    <div class="frmhold" *ngIf="!signupSuccess">

                        <form [formGroup]="autoRegisterForm" (ngSubmit)="autoRegister()" id="auto-register-form" *ngIf="IsAutoRegister=='1'">
                            <div *ngIf="errorMsg!=''" class="alert alert-danger" role="alert" [innerHTML]="errorMsg">
                            </div> 
                            <div class="custom-row row mb-50 required dbc-verify-yes">
                                <div class="col-md-12 col-12 position-relative mobilefeldspace">
                                    <input id="Password" [type]="pwrdTextType ? 'text' : 'password'" class="form-control frm-fld"
                                        placeholder="Password" formControlName="Password" [(ngModel)]="password"
                                        [closeDelay]="1000" [ngbPopover]="popContent"
                                        popoverTitle="Password Requirements" triggers="click:blur focus">
                                        <span [ngClass]="classPwrd === true ? 'showPw' : '' " (click)="pwrdToggle($event)" class="eyeIcon" ></span>
                                    <label class="d-none" for="Password">Password</label>
                                    <app-password-strength-bar *ngIf="password" [passwordToCheck]="password"
                                        [barLabel]="'Password strength:'" class="psb"></app-password-strength-bar>
                                    <div *ngIf="autoRegisterFormControls.Password.touched && autoRegisterFormControls.Password.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="autoRegisterFormControls.Password.errors.required">Password is
                                            required</div>
                                    </div>
                                </div>
                                <div class="col-md-12 position-relative col-12 mobilefeldspace">
                                    <input id="ConfirmPassword" [type]="conpwrdTextType ? 'text' : 'password'" class="form-control frm-fld"
                                        placeholder="Confirm Password" formControlName="ConfirmPassword">
                                        <span [ngClass]="classConfirmPwrd === true ? 'showPw' : '' " (click)="confirmpwrdToggle($event)" class="eyeIcon" ></span>    
                                    <label class="d-none" for="ConfirmPassword">Confirm Password</label>
                                    <div *ngIf="autoRegisterFormControls.ConfirmPassword.touched && autoRegisterFormControls.ConfirmPassword.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="autoRegisterFormControls.ConfirmPassword.errors.required">Confirm
                                            password is
                                            required</div>
                                        <div *ngIf="autoRegisterFormControls.ConfirmPassword.errors.mustMatch">Password
                                            must match</div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-row row mb-28 required dbc-verify-yes position-relative">
                                <div class="col-md-12 d-flex align-items-center mobile-spcing">
                                    <!-- Rounded switch -->
                                    <label class="switch">
                                        <input type="checkbox" formControlName="AcceptTerms">
                                        <span class="slider round" data-off="No" data-on="Yes"></span>
                                    </label>
                                    <span class="ps-2 cond-text"> I have read and agree to <a name="btn_TermsCondition_RegisterStepTwo" class="text-underline"
                                            href="/terms" target="_blank">Terms and
                                            Conditions</a> and <a class="text-underline" href="/policy"
                                            target="_blank">Privacy Policy</a></span>
                                </div>
                                <div class="col-md-12 position-relative termerror">
                                    <div *ngIf="autoRegisterFormControls.AcceptTerms.touched && autoRegisterFormControls.AcceptTerms.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="autoRegisterFormControls.AcceptTerms.errors.pattern"> Terms and
                                            Conditions is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <!--Newsletter-->
                            <div class="custom-row row mb-28 required dbc-verify-yes position-relative d-none">
                                <div class="col-md-12 d-flex align-items-center mobile-spcing">
                                    <label class="switch">
                                        <input type="checkbox" formControlName="NewsLetter" checked>
                                        <span class="slider round" data-off="No" data-on="Yes"></span>
                                    </label>
                                    <span class="ps-2 cond-text"> Subscribe to JustMoney Newsletter</span>
                                </div>
                                <div class="col-md-12 d-flex align-items-center mobile-spcing">
                                    <div class="jointext">
                                        <p class="m-0 orange-text">Join over 200,000 South Africans who receive our free
                                            weekly Newsletter and stay informed!</p>
                                    </div>
                                </div>
                            </div>
                            <!--Newsletter-->
                            <div class="custom-row row pt-4">
                                <div
                                    class="col mobile-spcing d-flex flex-wrap align-items-center justify-content-between">
                                    <button type="submit"
                                        class="btn btn-custom text-uppercase mb-md-0 mb-0 subresponsive"
                                        id="btn-register" name="btnRegisterRegisterPage">Register</button>
                                    <!-- <button type="button" (click)="dbcVerify()" class="btn btn-custom text-uppercase mb-md-0" id="btn-partical-register" name="btnRegisterRegisterPage">Register</button> -->

                                    <div class="btn-responsive mt-md-0 mt-0">
                                        <span class="text-md d-md-inline-block pe-1">Have an account ?</span>
                                        <a name="btn_Login_RegisterStepTwo" href="javascript:void(0)" (click)="azureAdlogin('')"
                                            class="btn btn-custom login-button">Login </a>
                                    </div>

                                </div>
                            </div>
                            <ng-template #popContent>
                                Be at least 8 characters <br>
                                At least one number<br>
                                At least one lowercase letter <br>
                                At least one uppercase letter<br>
                                At least one special character
                            </ng-template>
 
                        </form>

                        <form [formGroup]="registerForm" (ngSubmit)="register()" id="registerForm" *ngIf="IsAutoRegister=='0'">
                            <div *ngIf="errorMsg!=''" class="alert alert-danger" role="alert" [innerHTML]="errorMsg">
                            </div> 
                            <div class="custom-row row mb-28 required dbc-verify-no">
                                <div class="col-md-6 col-12 position-relative mobilefeldspace">
                                    <input id="FirstName" type="text" class="form-control frm-fld"
                                        placeholder="First Name" formControlName="FirstName" name-field-hyphen-space>
                                    <label class="d-none" for="FirstName">First Name</label>
                                    <div *ngIf="registerFormControls.FirstName.touched && registerFormControls.FirstName.errors"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="registerFormControls.FirstName.errors.required || registerFormControls.FirstName.errors.isWhiteSpace">
                                            First name is
                                            required</div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 position-relative rightspace">
                                    <input id="Surname" type="text" class="form-control frm-fld"
                                        formControlName="Surname" placeholder="Surname" name-field-with-space>
                                    <label class="d-none" for="Surname">Surname</label>
                                    <div *ngIf="registerFormControls.Surname.touched && registerFormControls.Surname.errors"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="registerFormControls.Surname.errors.required || registerFormControls.Surname.errors.isWhiteSpace">
                                            Surname is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-row row mb-28 required dbc-verify-no">
                                <div class="col-md-6 col-12 position-relative mobilefeldspace">
                                    <input id="PhoneNumber" type="tel" class="form-control frm-fld"
                                        placeholder="Cell Phone Number" formControlName="PhoneNumber" numeric-field
                                        maxlength="10" (keyup)="cellNumberErrorMsg=''">
                                    <label class="d-none" for="PhoneNumber">Cell Phone Number</label>
                                    <div *ngIf="(registerFormControls.PhoneNumber.touched && registerFormControls.PhoneNumber.errors) || cellNumberErrorMsg"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.PhoneNumber.errors?.required">Cell phone number
                                            is required
                                        </div>
                                        <div *ngIf="registerFormControls.PhoneNumber.errors?.minlength">Cell phone
                                            number must be 10
                                            digits long</div>
                                        <div *ngIf="registerFormControls.PhoneNumber.errors?.isValidMobile">Please enter
                                            your valid
                                            10 digits cell
                                            phone number</div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 position-relative rightspace">
                                    <input id="Email" type="text" class="form-control frm-fld"
                                        placeholder="Email Address" formControlName="Email">
                                    <label class="d-none" for="Email">Email Address</label>
                                    <div *ngIf="registerFormControls.Email.touched && registerFormControls.Email.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.Email.errors.required">Email address is
                                            required</div>
                                        <div *ngIf="registerFormControls.Email.errors?.isValidEmail">Please enter a
                                            valid email
                                            address</div>
                                    </div>
                                </div>
                            </div>

                            <div class="custom-row row mb-50 required dbc-verify-yes">
                                <div class="col-md-6 col-12 position-relative mobilefeldspace position-relative">
                                    <input id="Password" [type]="registerpwrdTextType ? 'text' : 'password'" class="form-control frm-fld"
                                        placeholder="Password" formControlName="Password" [(ngModel)]="password"
                                        [closeDelay]="1000" [ngbPopover]="popContent"
                                        popoverTitle="Password Requirements" triggers="click:blur focus">
                                        <span [ngClass]="registerclassPwrd === true ? 'showPw' : '' " (click)="registerpwrdToggle($event)" class="eyeIcon" ></span>
                                    <label class="d-none" for="Password">Password</label>
                                    <app-password-strength-bar *ngIf="password" [passwordToCheck]="password"
                                        [barLabel]="'Password strength:'" class="psb"></app-password-strength-bar>
                                    <div *ngIf="registerFormControls.Password.touched && registerFormControls.Password.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.Password.errors.required">Password is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 position-relative rightspace position-relative">
                                    <input id="ConfirmPassword" [type]="registerconpwrdTextType ? 'text' : 'password'"  class="form-control frm-fld"
                                        placeholder="Confirm Password" formControlName="ConfirmPassword">
                                        <span [ngClass]="registerclassConfirmPwrd === true ? 'showPw' : '' " (click)="registerconfirmpwrdToggle($event)" class="eyeIcon" ></span>
                                    <label class="d-none" for="ConfirmPassword">Confirm Password</label>
                                    <div *ngIf="registerFormControls.ConfirmPassword.touched && registerFormControls.ConfirmPassword.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.ConfirmPassword.errors.required">Confirm
                                            password is
                                            required</div>
                                        <div *ngIf="registerFormControls.ConfirmPassword.errors.mustMatch">Password must
                                            match</div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-row row mb-28 required dbc-verify-yes position-relative">
                                <div class="col-md-12 d-flex align-items-center mobile-spcing">
                                    <!-- Rounded switch -->
                                    <label class="switch">
                                        <input type="checkbox" formControlName="AcceptTerms">
                                        <span class="slider round" data-off="No" data-on="Yes"></span>
                                    </label>
                                    <span class="ps-2 cond-text"> I have read and agree to <a class="text-underline" name="btn_TermsCondition_RegisterStepTwo"
                                            href="/terms" target="_blank">Terms and
                                            Conditions</a> and <a class="text-underline" href="/policy" name="btn_PrivacyPolicy_RegisterStepTwo"
                                            target="_blank">Privacy Policy</a></span>
                                </div>
                                <div class="col-md-12 position-relative termerror">
                                    <div *ngIf="registerFormControls.AcceptTerms.touched && registerFormControls.AcceptTerms.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.AcceptTerms.errors.pattern"> Terms and
                                            Conditions is
                                            required</div>
                                    </div>
                                </div>
                                <input type="hidden" formControlName="IdNumber">
                            </div>
                            <!--Newsletter-->
                            <div class="custom-row row mb-28 required dbc-verify-yes position-relative d-none">
                                <div class="col-md-12 d-flex align-items-center mobile-spcing">
                                    <label class="switch">
                                        <input type="checkbox" formControlName="NewsLetter" checked>
                                        <span class="slider round" data-off="No" data-on="Yes"></span>
                                    </label>

                                    <span class="ps-2 cond-text"> Subscribe to JustMoney Newsletter</span>

                                </div>
                                <div class="col-md-12 d-flex align-items-center mobile-spcing">
                                    <div class="jointext">
                                        <p class="m-0 orange-text">Join over 200,000 South Africans who receive our free
                                            weekly Newsletter and stay informed!</p>
                                    </div>
                                </div>

                            </div>
                            <!--Newsletter-->
                            <div class="custom-row row pt-4">
                                <div
                                    class="col mobile-spcing d-flex flex-wrap align-items-center justify-content-between">

                                        <!-- old button class,btn btn-custom text-uppercase mb-md-0 mb-0 subresponsive  -->
                                        <!-- <button class="btn btn-custom text-uppercase mb-md-0 mb-0 subresponsive">Register</button> -->
                                        <button type="submit"
                                        class="btn btn-custom text-uppercase mb-md-0 mb-0 subresponsive"
                                        id="btn-register" name="btnRegisterRegisterStepTwoPage">Register</button>

                                    <div class="btn-responsive mt-md-0 mt-0">
                                        <span class="text-md d-md-inline-block pe-1">Have an account ?</span>
                                            <!-- old class name, "btn btn-custom login-button -->
                                            <a name="btn_Login_RegisterStepTwo" href="javascript:void(0)" (click)="azureAdlogin('')"
                                            class="btn btn-custom login-button">Login</a>
                                            <!-- <a class="btn btn-custom login-button">Login</a> -->
                                          
                                    </div>

                                </div>
                            </div>
                            <ng-template #popContent>
                                Be at least 8 characters <br>
                                At least one number<br>
                                At least one lowercase letter <br>
                                At least one uppercase letter<br>
                                At least one special character
                            </ng-template>


                        </form>
                    </div>


                    
                </div>
                <div class="col-lg-9 px-md-0 col-12 px-0 mx-auto mb-0">
                    <div class="loginbgholder">
                        
                        <div class="col-md-5 col-10 px-md-3 px-0">
                            <div class="loginbottompart">
                                <h3 class="text-white">You can:</h3>
                                <ul class="plist pt-2">
                                    <li>Get a personal loan and credit facility</li>
                                    <li>Consolidate your debt</li>
                                    <li>Save and invest</li>
                                    <li>Protect your car</li>
                                </ul>
                                <div class="gauge-cont mx-auto" data-percentage="83">
                                    <div class="score">
                                      <div class="score_inner">
                                        <p class="mb-0">YOUR SCORE</p>
                  
                                        <h2  class="text-yellow font-weight-bold mb-0">
                                          <span class="value">700</span>
                                        </h2>
                                      </div>
                                    </div>
                                    <div class="gauge">
                                      <div class="inner"></div>
                                      <div class="spinner" style="transform: rotate(224.1deg)"></div>
                                    </div>
                                    <!-- <div class="pointer" style="transform: rotate(calc(0deg - 126deg + 254deg))"></div> -->
                                    <div class="pointer" style="transform: rotate(-2.054deg);"></div>
                                    <div class="pointer-knob"></div>
                                    <div class="arc arc_start"></div>
                  
                                    <p class="average text-white md">Average</p>
                                    <div class="average_line"></div>
                                    <p class="bad text-white md">Bad</p>
                                    <div class="bad_line"></div>
                                    <p class="good text-white md">Good</p>
                                    <div class="good_line"></div>
                  
                                    <div class="risk_block text-center">
                                      <p class="average text-white sm">Last Updated 23.07.2021</p>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <!-- Reg HTML Start -->



        <div class="login-hld" *ngIf="signupSuccess" style="height: 200px;">
            <h2>
                Registration Successful, <a name="btn_Login_RegisterStepTwo" href="javascript:void(0)" (click)="azureAdlogin('')">click here to login</a>
            </h2>
        </div>
        <!-- REG HTML Start -->

        <ng-template #otpModel let-modal>
            <div class="modal-header">
                <h4 class="modal-title ftsz" id="modal-basic-title">
                    {{ otpPopupHeaderText }}
                </h4>
                <button name="btnCloseOTPModelRegisterStepTwoPage" type="button" class="close" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">

                    <i aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs"
                            version="1.1" width="15" height="15" x="0" y="0" viewBox="0 0 22.88 22.88"
                            style="enable-background:new 0 0 512 512" xml:space="preserve">
                            <g>
                                <path xmlns="http://www.w3.org/2000/svg" style=""
                                    d="M0.324,1.909c-0.429-0.429-0.429-1.143,0-1.587c0.444-0.429,1.143-0.429,1.587,0l9.523,9.539  l9.539-9.539c0.429-0.429,1.143-0.429,1.571,0c0.444,0.444,0.444,1.159,0,1.587l-9.523,9.524l9.523,9.539  c0.444,0.429,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-9.539-9.539l-9.523,9.539c-0.444,0.429-1.143,0.429-1.587,0  c-0.429-0.444-0.429-1.159,0-1.587l9.523-9.539L0.324,1.909z"
                                    fill="#1e201d" data-original="#1e201d" />
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                            </g>
                        </svg></i>
                </button>
            </div>
            <form class="otp-form" [formGroup]="otpForm" (ngSubmit)="validateOtp()">
                <div class="modal-body">
                    <div *ngIf="otpErrorMsg!=''" class="alert alert-danger" role="alert">
                        {{otpErrorMsg}}
                    </div>
                    <div *ngIf="successMsg!=''" class="alert alert-success" role="alert">
                        {{successMsg}}
                    </div>
                    <div *ngIf="successMsgWithPipe == true" class="alert alert-success" role="alert">
                        Your session will expire in {{counter | formatTime}}.

                    </div>

                    <div class="form-group fullwidth">
                        <div class="form-field non-editable cellnumber">   
                            <label for="otp">Your Cell Number</label>
                            <input type="text" class="form-control" value="{{registerFormControls.PhoneNumber.value}}"
                                readonly="readonly">

                        </div>
                    </div>

                    <div *ngIf="isOtpSent || optSessionStillValid == true" class="form-group fullwidth">
                        <div class="form-field position-relative position-relative">
                            <label for="otp">Please Enter OTP</label>
                            <input type="text" class="form-control" formControlName="Otp" numeric-field maxlength="6">

                            <div *ngIf="otpFormControls.Otp.touched && otpFormControls.Otp.errors"
                                class="invalid-feedback">
                                <div *ngIf="otpFormControls.Otp.errors.required">OTP is required</div>
                                <div *ngIf="otpFormControls.Otp.errors.minlength">OTP must be 6 digits</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer otpbtn">
                    <div class="arrangebtn">
                        <button [name]="'btn'+sendOtpText+'OTPRegisterPage'" type="button"  *ngIf="sendOtpText != 'Resend SMS OTP'"
                        [ngClass]="(sendOtpText=='Resend SMS OTP')?'btn-border':'btnresend'" 
                        (click)="sendOtp(1)">{{sendOtpText}}</button>
                    </div>

                   <button [name]="'btn'+sendOtpText+'OTPRegisterPage'" type="button" *ngIf="sendOtpText =='Resend SMS OTP' && hideResendOTPButton == false"
                    [ngClass]="(sendOtpText=='Resend SMS OTP')?'btn-border':'btnresend'"
                    (click)="sendOtp(1)" 
                    [ngStyle]="disableOTPSendButton == true ? {'border-color': '#767272','background': '#ffffff','color': '#767272'} :  {'border-color': '#e59a2c','color': '#e59a2c', 'background': '#ffffff'}"
                    [disabled]="disableOTPSendButton == true">Resend OTP <span *ngIf="secondsLeft > 0">in {{secondsLeft}}s</span></button>
                 


                    <div class="submitbt">
                        <button name="btnSubmitOTPRegisterStepTwoPage" id="btnSubmitOTPRegisterStepTwoPage" [disabled]="btnOTPIsDisabled" type="submit"
                            class="btns" *ngIf="isOtpSent">Submit</button>
                    </div>
                 
                </div>
            </form>
        </ng-template>


    </div>
</section>
<!-- Banner HTML End -->


<!-- Footer HTML Start -->
<app-footer></app-footer>
<!-- Footer HTML End -->