import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';   
import { RegisterStepOneBaseComponent } from '../register-step-one-base.component';

@Component({
  selector: 'app-cars-registration',
  templateUrl: './cars-registration.component.html',
  styleUrls: ['./cars-registration.component.css'],
  host: {'class': 'red'}
})

export class CarsRegistrationComponent extends  RegisterStepOneBaseComponent {
    
}
